import React, { useState } from "react";
import { BaseForm, FieldWithError, Helper } from "_common";
import { ICoupon, IDataField, IKeyValue } from "_interfaces";
import { adminCouponApiService as apiService } from "_services";
import { Validator, validationMap } from "_components/_schemas";
import { DropDown } from "_common/DropDown";
import { CouponDiscountTypeName } from "_constants";
import ReactDatePicker from "react-datepicker";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: ICoupon) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField[];
}

const CouponForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }: IAppProps) => {
  const [expiryDate, setExpiryDate] = useState<Date>(initialValues['expireDateUtc'] ? new Date(initialValues['expireDateUtc']) : new Date());
  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      editableFields={editableFields}
      validationSchema={validationMap.get(Validator.ADMIN_COUPON_FORM)}
    >
      {({ errors, touched, setFieldValue }) => {
        return (
          <>
          
            <DropDown  errors={errors} touched={touched} label="Discount Type" defaultItem={{key: '', value: 'Select Discount Type'} as IKeyValue<string>} enumMap={CouponDiscountTypeName} name="discountTypeId"/>

            <div>
                <span className="me-2">Expiration Date</span>
                <div style={{display: "none"}}>
                <FieldWithError errors={errors} touched={touched} fieldName="expireDateUtc" />
                </div>
                <ReactDatePicker
                  onChange={(date: Date) => {
                    setExpiryDate(date);
                    setFieldValue("expireDateUtc", Helper.getFormattedDate(date));
                  }}
                  selected={expiryDate}
                />
              </div>


            <FieldWithError errors={errors} touched={touched} fieldName="name" placeholder="Name" label="Coupon Name" />

            <FieldWithError errors={errors} touched={touched} fieldName="code" placeholder="Code" label="Coupon Code" />

            <FieldWithError errors={errors} touched={touched} fieldName="discount" placeholder="Discount" label="Discount" />

            <FieldWithError errors={errors} touched={touched} fieldName="quantity" placeholder="Quantity" label="Quantity" />
          </>
        );
      }}
    </BaseForm>
  );
};

export { CouponForm };
