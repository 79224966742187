import React, { useEffect } from "react";
import { Url } from "_constants";
import { IOrderSummary } from "_interfaces";

const WebOrderConfirmation: React.FunctionComponent<{ orderSummary: IOrderSummary }> = ({ orderSummary }) => {

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  return (
    <div className="container" id="thank-you">
      <div className="row my-2">
        <div className="col">
          <div className="medium-text-1 text-primary">Order Received</div>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-sm-8">
          <div>
            <p>
            Thank you for your order!
            </p>

            <p className="fw-bold">
              Please check your email inbox or spam folder for your order confirmation and make sure to whitelist the @convenahealth.com domain.
            </p>

            <p>You should receive your Home Colon Cancer Test Kit in a few days. You can check the
            status of your order by logging into your account, and clicking on the "Orders and Payments" section of your{" "}
            <a href={`${Url.CUSTOMER_DASHBOARD}`}>Account</a>.
            </p>



            <p>
              If you have additional questions, please email customer support at <a href="mailto:contact@convenahealth.com">contact@convenahealth.com</a>.
            </p>
          </div>

          <div className="my-3">
            <div className="small-text-3"><span className="fw-bold">Order ID:</span> {orderSummary.uid}</div>
            <table className="table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Product Name: {orderSummary.productName}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Quantity</td>
                  <td className="text-end">{orderSummary.quantity}</td>
                </tr>
                {orderSummary?.couponName && (
                  <tr>
                    <td>Coupon</td>
                    <td className="text-end">{orderSummary.couponName}</td>
                  </tr>
                )}
                {orderSummary?.couponDiscount && (
                  <tr>
                    <td>Discount Applied</td>
                    <td className="text-end">${orderSummary.couponDiscount.toLocaleString('en-us', {minimumFractionDigits: 2})}</td>
                  </tr>
                )}
                <tr>
                  <td>Shipping</td>
                  <td className="text-end">FREE</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className="fw-bold">Total Amount Charged</td>
                  <td className="text-end fw-bold">${orderSummary.amount?.toLocaleString('en-us', {minimumFractionDigits: 2})}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="envelope ">
            <img src="/images/Envelop.png" alt="Convena Health Test Kit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { WebOrderConfirmation };
