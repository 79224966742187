import axios from "axios";
import { config } from '_config';
import { customerAuthenticationService as authService } from "_services";

class CustomerQuestionnaireService {

  constructor (
    protected apiBaseUrl: string
  ) {}

  async saveAnswers(answers: any): Promise<any> {
    const url: string = `${this.apiBaseUrl}`;
    return this.post<any>(url, answers)
  }

  async post<T>(url:string, item: object): Promise<T> {
    try {
      const response: any = await axios.post<T>(url, item, await authService.getAuthHeader());
      if (response?.error) {
        throw Error(response.error.message);
      }
      return response.data as T;
    } catch (error: any) {
      throw Error(error);
    }    
  }
}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user/questionnaire`;
const customerQuestionnaireService = new CustomerQuestionnaireService(apiBaseUrl);

export { customerQuestionnaireService }