import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalAgreement: React.FunctionComponent<{ show: any, handleClose: any, children: any, title: string}> = ({show, handleClose, children, title}) => {
  return(
    <Modal scrollable={true} show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="text-primary">{title}</Modal.Title>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
}

export { ModalAgreement }