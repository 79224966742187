import { StorageKey } from "_constants";
import { ICart } from "_interfaces";

class CartService {
  
  getCart():ICart {
    const cart = localStorage.getItem(StorageKey.CART);
    if (cart) {
      return JSON.parse(cart) as ICart;
    }
    return {} as ICart
  }
  
  setCart(cart: ICart) {
    localStorage.setItem(StorageKey.CART, JSON.stringify(cart));
  }

  emptyCart() {
    //localStorage.removeItem(StorageKey.ACTIVATION_CODE);
    localStorage.removeItem(StorageKey.CART);
  }

  isCodeExistInCart(code: string) {
    const cart = this.getCart();
    return cart.activationCodes && cart.activationCodes.includes(code);
  }

  getCodeCount(): number {
    const cart = this.getCart();
    if (cart.activationCodes) {
      return cart.activationCodes.length;
    }
    return 0;
  }

}

const cartService = new CartService();
export { cartService }