import * as Yup from 'yup';

enum Validator {
  ADMIN_PRODUCT_FORM = 'admin_product_form',
  ADMIN_ACTIVATION_CODE_FORM = 'admin_activation_code_form',
  ADMIN_COUPON_FORM = 'admin_coupon_form',

  COMMON_ADDRESS = 'common_address',
  BILLING_ADDRESS = 'billing_address',
  SHIPPING_ADDRESS = 'shipping_address',
  ACTIVATION_FORM = 'activation_form',
  REGISTRATION_FORM = 'registration_form',
  PROFILE_FORM = 'profile_form',
  CHECKOUT_FORM = 'checkout_form', // anonymous, shipping validator only, billing is same
  CHECKOUT_FORM_LOGGED_IN = 'checkout_form_logged_in', // logged in, shipping validator only, billing is same, logged in
  CHECKOUT_FORM_WITH_BILL = 'checkout_form_with_bill', // anonymous, diff billing so additional validator for billing
  CHECKOUT_FORM_WITH_BILL_LOGGED_IN = 'checkout_form_with_bill_logged_in', // logged in, diff billing so additional validator for billing
  CHECKOUT_FORM_BILL_ONLY = 'checkout_form_bill_only',
  CHECKOUT_FORM_BILL_ONLY_LOGGED_IN = 'checkout_form_bill_only_logged_in',
  FORGOT_PASSWORD = 'forgot_password_form',
  RESET_PASSWORD_FORM = 'reset_password_form',
  SIMPLE_REGISTRATION = 'simple_registration',
  CONFIRM_PROFILE_FORM = 'confirm_profile_form',
}
const validationMap = new Map<string, object>([]);

const password = Yup
  .string()
  .required("Please enter your password")
  .matches(
    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    "Password must contain at least 8 characters, one uppercase, one number and one special case character"
  )
const confirmPassword = Yup
  .string()
  .required("Please confirm your password")
  .when("password", {
    is: (password: string) => (password && password.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref("password")], "Password doesn't match")
  })

const phoneNumber = Yup
  .string()
  .required("Phone Number is required")
  .matches(
    /[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
    "Please use this format: 123-456-7890"
  )

const usState = Yup
.string()
.required("Shipping State is required")
.oneOf(["DE", "IN", "IA", "KS", "MN", "MS", "MO", "NM", "NC", "OH", "OK", "UT", "WI", "TX", "VA"], "The FIT Test Kit is not yet available in your State. Check back in a couple of weeks.");

validationMap.set(Validator.COMMON_ADDRESS, Yup.object().shape({
  email: Yup.string().email("Must be a valid email address").required("Email is Required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: usState,
  zipcode: Yup.string().required("Zip Code is required"),
  phoneNumber,
}));

validationMap.set(Validator.ADMIN_PRODUCT_FORM, Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  description: Yup.string().required("Description is required"),
}));


const shippingValidators = {
  shippingFirstName: Yup.string().required("First Name is required"),
  shippingLastName: Yup.string().required("Last Name is Required"),
  shippingAddress1: Yup.string().required("Shipping Address is required"),
  shippingCity: Yup.string().required("Shipping City is required"),
  shippingState: usState,
  shippingZip: Yup.string().required("Shipping Zip is required"),
  shippingPhoneNumber: phoneNumber,
};

const billingValidators = {
  billingFirstName: Yup.string().required("First Name is required"),
  billingLastName: Yup.string().required("Last Name is Required"),
  billingAddress1: Yup.string().required("Billing Address is required"),
  billingCity: Yup.string().required("Billing City is required"),
  billingState: Yup.string().required("Billing State is required"),
  billingZip: Yup.string().required("Billing Zip is required"),
  billingPhoneNumber: phoneNumber,
}

const simpleRegistrationValidators = {
  email: Yup.string().email("Must be valid email address").required("Email is Required"),
  password,
  confirmPassword,
  agreeTerms: Yup.boolean().oneOf([true], "You must accept the Terms and Conditions"),

}

validationMap.set(Validator.SHIPPING_ADDRESS, Yup.object().shape(shippingValidators));
validationMap.set(Validator.BILLING_ADDRESS, Yup.object().shape(billingValidators));

// anonymous, ship and bill the same
validationMap.set(Validator.CHECKOUT_FORM, Yup.object().shape({
  ...shippingValidators,
  ...simpleRegistrationValidators,
}));

// logged in, ship and bill the same
validationMap.set(Validator.CHECKOUT_FORM_LOGGED_IN, Yup.object().shape({
  ...shippingValidators,
}));

// anonymous, bill different
validationMap.set(Validator.CHECKOUT_FORM_WITH_BILL, Yup.object().shape({
  ...billingValidators,
  ...shippingValidators,
  ...simpleRegistrationValidators,
}));

// logged in, bill different
validationMap.set(Validator.CHECKOUT_FORM_WITH_BILL_LOGGED_IN, Yup.object().shape({
  ...billingValidators,
  ...shippingValidators,
}));


// anonymous, billing only (premailed checkout)
validationMap.set(Validator.CHECKOUT_FORM_BILL_ONLY, Yup.object().shape({
  ...billingValidators,
  ...simpleRegistrationValidators,
}));

// logged in, billing only (premailed checkout)
validationMap.set(Validator.CHECKOUT_FORM_BILL_ONLY_LOGGED_IN, Yup.object().shape({
  ...billingValidators,
}));


validationMap.set(Validator.ACTIVATION_FORM, Yup.object().shape({
  activationCode: Yup.string().required("Activation Code Required"),
  codeA: Yup.string().required('Required'),
  codeB: Yup.string().required('Required'),
}));

validationMap.set(Validator.ADMIN_ACTIVATION_CODE_FORM, Yup.object().shape({
  statusId: Yup.string().required("Status is Required"),
}));

validationMap.set(Validator.REGISTRATION_FORM, Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Must be valid email address").required("Email is Required"),
  password,
  confirmPassword,
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: usState,
  zipcode: Yup.string().required("Zip Code is required"),
  phoneNumber,
  agreeTerms: Yup.boolean().oneOf([true], "You must accept the Terms and Conditions"),
}));

validationMap.set(Validator.PROFILE_FORM, Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Must be valid email address").required("Email is Required"),
  confirmPassword,
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: usState,
  zipcode: Yup.string().required("Zip Code is required"),
  phoneNumber,
}));

validationMap.set(Validator.ADMIN_COUPON_FORM, Yup.object().shape({
  name: Yup.string().required("Please enter the name of the coupon").min(5, 'Must be at least 5 characters long'),
  code: Yup.string().required("Please enter the code of the coupon").min(5, 'Must be at least 5 characters long'),
  discount: Yup.number().required("Please enter the discount"),
  expireDateUtc: Yup.string().required("Please enter the expiration date (UTC)"),
  quantity: Yup.number().required("Please enter the number of coupons"),
  discountTypeId: Yup.string().required("Please select the Discount Type"),
}));

validationMap.set(Validator.FORGOT_PASSWORD, Yup.object().shape(
  {
    emailAddress: Yup.string().email("Must be valid email address").required("Email is Required"),
  }
))

validationMap.set(Validator.RESET_PASSWORD_FORM, Yup.object().shape(
  {
    password,
    confirmPassword,
  }
))

validationMap.set(Validator.SIMPLE_REGISTRATION, Yup.object().shape(simpleRegistrationValidators));

validationMap.set(Validator.CONFIRM_PROFILE_FORM, Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: usState,
  zipcode: Yup.string().required("Zip Code is required"),
  phoneNumber: Yup
    .string().required("In case we need to contact you about your results, your phone number is required")
    .matches(
      /[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
      "Please use this format: 123-456-7890"
    ),
  agreeHipaa: Yup.boolean().oneOf([true], "You must accept the HIPAA Authorization"),
  agreeProductConsent: Yup.boolean().oneOf([true], "You must accept the Product Consent"),
  dateOfBirth: Yup.string().required("You must provide your date of birth"),
}));

export { validationMap, Validator }
