import React, { useContext, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { BillingForm, CompanionCodePurchase, CouponForm, CreditCardForm, PreMailOrderSummary } from "_components";
import { useStripe, useElements, CardNumberElement } from "@stripe/react-stripe-js";
import { useHistory } from "react-router";
import { ProductCodeStatus, StorageKey, Url } from "_constants";
import { cartService, customerAccountService, paymentService } from "_services";
import { IAccount, ICart, ICodeStatus, IUserContext } from "_interfaces";
import { validationMap, Validator } from "_components/_schemas";
import { customerCodeService } from "_services/CustomerCodeService";
import { UserContext } from "_common";
import { config } from "_config";
import { AccountSetup } from "_components/_account/_subforms";

const ActivationCodeCheckout = () => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const userContext: IUserContext | null = useContext(UserContext);

  const [, setOrderTotal] = useState<number>(0);
  const [codes, setCodes] = useState<string[]>([]);
  const [hasCompanionPrompt, setHasCompanionPrompt] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [isSameBillProfile, setIsSameBillProfile] = useState<boolean>(true);
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponApplied, setCouponApplied] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = useState<string>("");

  const urlSearchParams = new URLSearchParams(window.location.search);
  const redir: string | undefined = urlSearchParams.get("redir") || undefined;
  const isLoggedIn = userContext?.account;

  const defaultSchema = isLoggedIn
    ? validationMap.get(Validator.CHECKOUT_FORM_BILL_ONLY_LOGGED_IN)
    : validationMap.get(Validator.CHECKOUT_FORM_BILL_ONLY);

  const [validationSchema, setValidationSchema] = useState<any>(defaultSchema);

  useEffect(() => {
    const _init = async () => {
      if (isLoggedIn) {
        setValidationSchema(validationMap.get(Validator.CHECKOUT_FORM_BILL_ONLY_LOGGED_IN));
      } else {
        setValidationSchema(validationMap.get(Validator.CHECKOUT_FORM_BILL_ONLY));
      }

      const cart = cartService.getCart();
      let mainCode: string;

      if (cart.activationCodes) {
        setCodes(cart.activationCodes);
        const orderTotal = await paymentService.getPayDetails(1, cart.activationCodes.length); // @TODO: should not be hard-coded
        mainCode = cart.activationCodes[0];
        setOrderTotal(orderTotal);
      } else {
        const code: string = localStorage.getItem(StorageKey.ACTIVATION_CODE) || "";
        mainCode = code;
        setCodes([code]);
      }
      try {
        const codeStatus: ICodeStatus = await customerCodeService.getStatus(mainCode);

        const companionStatusId: number | null =
          codeStatus.companionStatusIds && codeStatus.companionStatusIds?.length > 0 ? codeStatus.companionStatusIds[0] : null;

        const hasCompanionPrompt =
          companionStatusId === (ProductCodeStatus.CREATED || ProductCodeStatus.MAILED) && cartService.getCodeCount() < 2;
        setHasCompanionPrompt(hasCompanionPrompt);
        const orderTotal = await paymentService.getPayDetails(1, cartService.getCodeCount()); // @TODO: should not be hard-coded
        setOrderTotal(orderTotal);
      } catch (error: any) {
        console.error("error in ActivationCodeCheckout", error);
        setStatus(error.message);
      }
    };
    _init();
  }, [userContext, isLoggedIn]);

  const onAgree = async (code: string) => {
    const newCodes = [...codes, code];
    const orderTotal = await paymentService.getPayDetails(1, newCodes.length);
    setHasCompanionPrompt(false);
    setCodes(newCodes);
    cartService.setCart({
      productId: config.productId,
      activationCodes: newCodes,
    });
    setOrderTotal(orderTotal);
    setStep(1);
  };

  const onDisagree = async () => {
    cartService.setCart({
      productId: config.productId,
      activationCodes: codes,
    });
    const orderTotal = await paymentService.getPayDetails(1, codes.length);
    setOrderTotal(orderTotal);
    setStep(1);
  };

  const initialFormState: any = {
    billingFirstName: isSameBillProfile ? userContext?.account?.firstName : "",
    billingLastName: isSameBillProfile ? userContext?.account?.lastName : "",
    billingAddress1: isSameBillProfile ? userContext?.account?.address1 : "",
    billingAddress2: isSameBillProfile ? userContext?.account?.address2 : "",
    billingCity: isSameBillProfile ? userContext?.account?.city : "",
    billingState: isSameBillProfile ? userContext?.account?.state : "",
    billingZip: isSameBillProfile ? userContext?.account?.zipcode : "",
    creditCardCode: "",
    couponCode: "",
    email: "",
    password: "",
    confirmPassword: "",
    agreeTerms: false,
  };

  const onClickSameBillProfile = (values: any, e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSameBillProfile(!isSameBillProfile);
    if (isSameBillProfile === false) {
      values["billingFirstName"] = userContext?.account?.firstName;
      values["billingLastName"] = userContext?.account?.lastName;
      values["billingAddress1"] = userContext?.account?.address1;
      values["billingAddress2"] = userContext?.account?.address2;
      values["billingCity"] = userContext?.account?.city;
      values["billingState"] = userContext?.account?.state;
      values["billingZip"] = userContext?.account?.zipcode;
      values["billingPhoneNumber"] = userContext?.account?.phoneNumber;
    } else {
      values["billingFirstName"] = "";
      values["billingLastName"] = "";
      values["billingAddress1"] = "";
      values["billingAddress2"] = "";
      values["billingCity"] = "";
      values["billingState"] = "";
      values["billingZip"] = "";
      values["billingPhoneNumber"] = "";
    }
    e.stopPropagation(); // without this, checkbox will not toggle!
  };

  const submitOrder = (data: any, actions: any) => {
    const billingDetails: any = {
      name: `${data.billingFirstName} ${data.billingLastName}`,
      address: {
        city: data.billingCity,
        line1: data.billingAddress1,
        state: data.billingState,
        postal_code: data.billingZip,
      },
    };

    const { setStatus, setSubmitting } = actions;
    setStatus("");

    paymentService.getPaymentIntent().then((paymentIntent) => {
      const cardNumberElement = elements ? elements.getElement(CardNumberElement) : null;
      const stripeClientSecret = paymentIntent.data.stripeClientSecret;
      if (cardNumberElement) {
        stripe
          ?.confirmCardSetup(stripeClientSecret, {
            payment_method: {
              card: cardNumberElement,
              billing_details: billingDetails,
            },
          })
          .then((result) => {
            if (result.error) {
              setStatus(`${result.error.message}`);
              setSubmitting(false);
            } else {
              const cart: ICart = {
                activationCodes: codes,
                couponCode: data.couponCode,
              };
              stripe.retrieveSetupIntent(stripeClientSecret).then((setupIntentResult) => {
                paymentService
                  .pay({ setupIntentResult, cart })
                  .then((result) => {
                    // success. go to Order Complete page
                    cartService.emptyCart();
                    if (redir) {
                      history.push(redir);
                    } else {
                      history.push(`${Url.CUSTOMER_KIT_ACTIVATION}?cart=1`);
                    }
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    console.error("error calling pay endpoint", err);
                    setStatus(`${err}`);
                  });
              });
            }
          });
      }
    });
  };

  const onSubmit = (data: any, actions: any) => {
    const { setStatus, setSubmitting } = actions;
    if (isLoggedIn) {
      submitOrder(data, actions);
    } else {
      customerAccountService
        .register({
          email: data.email,
          password: data.password,
          firstName: data.billingFirstName,
          lastName: data.billingLastName,
          address1: data.billingAddress1,
          address2: data.billingAddress2,
          city: data.billingCity,
          state: data.billingState,
          zipcode: data.billingZip,
          phoneNumber: data.billingPhoneNumber,
        })
        .then((account: IAccount) => {
          userContext?.setAccount(account);
          submitOrder(data, actions);
        })
        .catch((err: any) => {
          setSubmitting(false);
          setStatus(err.message);
        });
    }
  };

  return (
    <>
      <h3 className="mb-3">Purchase Activation Code</h3>

      {step === 0 && <>{hasCompanionPrompt && <CompanionCodePurchase onAgree={onAgree} onDisagree={onDisagree} />}</>}

      {((step === 0 && !hasCompanionPrompt) || step === 1) && (
        <Formik initialValues={initialFormState} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ values, isSubmitting, errors, touched, setStatus, status, setSubmitting, setFieldValue }) => {
            return (
              <Form>
                <div className="container my-5">
                  <div className="row">
                    <div className="col-md-4">
                      <PreMailOrderSummary
                        activationCodes={codes}
                        couponCode={couponCode}
                        setCouponApplied={setCouponApplied}
                        setStatus={setStatus}
                      />

                      {!couponApplied && (
                        <CouponForm
                          errors={errors}
                          touched={touched}
                          values={values}
                          setCouponCode={setCouponCode}
                          setFieldValue={setFieldValue}
                          name="coupon"
                        />
                      )}
                    </div>
                    <div className="col">
                      {!isLoggedIn && (
                        <div className="container my-3">
                          <AccountSetup
                            errors={errors}
                            touched={touched}
                            formatAsCard={true}
                            loginUrl={`${Url.CUSTOMER_LOGIN}?redir=${Url.CUSTOMER_PURCHASE_ACTIVATION}`}
                          />
                        </div>
                      )}

                      <BillingForm errors={errors} touched={touched} />

                      {isLoggedIn && (
                        <div className="my-2">
                          <input
                            type="checkbox"
                            id="sameBill"
                            checked={isSameBillProfile}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onClickSameBillProfile(values, e)}
                            value={1}
                            name="sameBill"
                            className="mx-3"
                          />
                          <label htmlFor="sameBill">Billing Address is same as Account Address</label>
                        </div>
                      )}

                      <div className="container my-3">
                        <CreditCardForm />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col text-center">
                      <div className="container">
                        {status && <div className={"alert alert-danger text-center"}>{status}</div>}
                      </div>
                      {isSubmitting && <div className="spinner-border"></div>}
                    </div>
                  </div>

                  <div className="row justify-content-center my-5">
                    <div className="col-auto">
                      <button disabled={isSubmitting} className="btn btn-primary btn-lg btn-block" type="submit">
                        {isSubmitting ? "Processing..." : "Place Order"}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export { ActivationCodeCheckout };
