import React, { useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useHistory } from "react-router";
import { UserContext } from "_common";
import { IUserContext } from "_interfaces";
import { Url } from "_constants";
import { adminAuthenticationService } from "_services";

const Navigation = () => {
  const history = useHistory();

  const userContext: IUserContext | null = useContext(UserContext);
 
  const onClickMyAccount = () => {
    history.push(Url.CUSTOMER_ACCOUNT_PROFILE);
  };

  const onClickLogin = () => {
    history.push(Url.CUSTOMER_LOGIN);

  }
  const logout = () => {
    userContext?.setAccount(null);
    adminAuthenticationService.logout();
    history.push(Url.DEFAULT_HOME);

  };

  return (
    <Navbar expand="lg">
      <Navbar.Brand href={Url.ADMIN_HOME_PAGE}>Convena Health Admin</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="justify-content-end w-100">
          <Nav.Link href={Url.ADMIN_HOME_PAGE}>Home</Nav.Link>
          <Nav.Item>
            <div className="btn-toolbar ml-auto">
              {!userContext?.account ? (
                <button type="button" className="btn btn-primary mx-2" onClick={onClickLogin}>Login</button>
              ) : (
                <>
                  <Nav.Link href={Url.ADMIN_ACTIVATION_CODES}>Activation Codes</Nav.Link>
                  {/*<Nav.Link href={Url.ADMIN_HOME_PAGE}>Labels and Mailings</Nav.Link>*/}
                  <Nav.Link href={Url.ADMIN_ORDER}>Orders</Nav.Link>
                  <Nav.Link href={Url.ADMIN_COUPON}>Coupons</Nav.Link>
                  <Nav.Link href={Url.ADMIN_USER}>Registered Users</Nav.Link>
                  <Nav.Link href={Url.ADMIN_PROSPECT}>Prospects</Nav.Link>
                  <Nav.Link href={Url.ADMIN_PROFILE} onClick={onClickMyAccount}>My Account</Nav.Link>
                  <Nav.Link href={Url.ADMIN_HOME_PAGE} onClick={logout}>Sign Out</Nav.Link>
                </>
              )}
            </div>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export { Navigation as AdminNavigation};
