import React, { useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { UserContext } from "_common";
import { IUserContext } from "_interfaces";
import { Url } from "_constants";
import { HashLink } from "react-router-hash-link";

const Navigation: React.FunctionComponent = () => {

  const userContext: IUserContext | null = useContext(UserContext);
 
  const onClickActivate = () => {
    window.location.href = 'https://www.convenacare.com/make-appointment';
    //history.push(Url.CUSTOMER_ACTIVATION);
  }

  return (
    <>
    <Navbar expand="lg" className="navbar fixed-top bg-white main-container pb-0">
      <div className="container-fluid">
      <Navbar.Brand href={Url.DEFAULT_HOME}><div className="header-logo"><img src="/images/logo.png" alt="Convena Health"/></div></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="justify-content-center w-100">
          <Nav.Link href={Url.DEFAULT_HOME} className="menu-item my-lg-2">Home</Nav.Link>
            <HashLink
              className="menu-item my-lg-2 nav-link"
              scroll={(el: any) => el.scrollIntoView({ behavior: 'smooth' })}
              to={'/#faq'}>
                FAQ
            </HashLink>
        </Nav>

        {/* collapsed */}
        <div className="d-sm-inline-block d-xs-inline-block d-md-block d-lg-none d-xl-none">
          <Nav className="justify-content-end w-100 ">
            <Nav.Link href="https://www.convenacare.com/make-appointment" className="menu-item my-lg-2">Activate your kit</Nav.Link>
            {/*<Nav.Link href={Url.CUSTOMER_CHECKOUT} className="menu-item my-lg-2" >Cart</Nav.Link>*/}
            {userContext ? 
              <Nav.Link href={Url.CUSTOMER_DASHBOARD} className="menu-item my-lg-2">My Account</Nav.Link> :
              <Nav.Link href={Url.CUSTOMER_LOGIN} className="menu-item my-lg-2">Login</Nav.Link>}
          </Nav>
        </div>


      </Navbar.Collapse>

      {/* menu using icons (expanded) */}
      <div className="d-none d-lg-inline-block">
      <Nav className="justify-content-end w-100">
        <Nav.Link href="https://www.convenacare.com/make-appointment" className="mx-3"><div className="btn-cta btn-activate">Activate your kit</div></Nav.Link>
        {/*<Nav.Link href={Url.CUSTOMER_CHECKOUT} className="icon icon-cart my-2 mx-1" ><div className="icon-cart"></div></Nav.Link>*/}
        {userContext ? 
          <Nav.Link href={Url.CUSTOMER_DASHBOARD} className="icon icon-account my-2 mx-1"></Nav.Link> :
          <Nav.Link href={Url.CUSTOMER_LOGIN} className="icon icon-account my-2 mx-1"></Nav.Link>}
      </Nav>
      </div>
      </div>
      <div className="mobile-activate-menu-item d-sm-inline-block d-xs-inline-block d-md-none d-lg-none d-xl-none" onClick={onClickActivate}>ACTIVATE KIT NOW</div>
    </Navbar>
    </>
  );
};

export { Navigation };
