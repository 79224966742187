import { SetupIntentResult } from '@stripe/stripe-js';
import axios from 'axios';
import { config } from '_config';
import { ICart, ICustomerPayment, IGateway } from '_interfaces';
import { customerAuthenticationService } from '_services';

class PaymentService {

  constructor(protected apiBaseUrl: string) {}

  /**
   * @returns {stripeIntentId, stripeClientSecretKey}
   */
  async getPaymentIntent(): Promise<any> {

    const url = `${this.apiBaseUrl}/pay/pre-init`;

    try {
      const data: any = await axios.post(url, {},  await customerAuthenticationService.getAuthHeader());
      return data;
    }
    catch (err: any) {
      throw Error(err.message);
    }
    
  }

  /**
   * 
   * @returns any
   */
  async getGatewayInfo(): Promise<IGateway> {
    const url = `${this.apiBaseUrl}/pay/gateway-info`;

    try {
      const response: any = await axios.post(url, {});
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as IGateway;
    }
    catch (err: any) {
      console.error('errored getGatewayInfo', err);
      throw Error(err.message);
    }
  }

  async pay(data: {setupIntentResult: SetupIntentResult, cart: ICart }) {
    const url = `${this.apiBaseUrl}/pay`;
    const payload = {
      stripeIntentId: data.setupIntentResult.setupIntent?.id,
      productId: data.cart?.productId,
      quantity: data.cart?.quantity,
      activationCodes: data.cart?.activationCodes,
      shipTo: data.cart?.shipTo,
      coupon: {code: data.cart?.couponCode},
    }
    try {
      const result: any = await axios.post(url, payload, await customerAuthenticationService.getAuthHeader());
      if (result.data.error) {
        throw Error(result.data.error.message);
      }
      return result;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async getPayDetails(productId: number, quantity: number, couponCode?: string): Promise<any> {
    if (quantity === 0) return 0;
    
    const url = `${this.apiBaseUrl}/pay/details`;
    const payload = {
      productId,
      quantity,
      coupon: {code: couponCode}
    };
    try {
      const result: any = await axios.post(url, payload);
      if (result.data.error) {
        throw Error(result.data.error.message);
      } else {
        return result.data;
      }
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async getPayments(): Promise<ICustomerPayment[]> {
    const url = `${this.apiBaseUrl}/payments`;
    try {
      const response: any = await axios.get<ICustomerPayment[]>(url, await customerAuthenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as ICustomerPayment[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const paymentService = new PaymentService(apiBaseUrl);
export { paymentService }