enum CouponStatusEnum {
  ACTIVE      = 1,
  USED        = 2,
}

const CouponStatusName: Map<number, string> = new Map([
  [CouponStatusEnum.ACTIVE, 'Active'],
  [CouponStatusEnum.USED, 'Used'],
]);

export {CouponStatusEnum, CouponStatusName }