enum CouponDiscountTypesEnum {
  PCT            = 1, // discount pct
  AMOUNT         = 2, // discount amount
}

const CouponDiscountTypeName: Map<number, string> = new Map([
  [CouponDiscountTypesEnum.PCT, 'Percent'],
  [CouponDiscountTypesEnum.AMOUNT, 'Amount'],
]);

export { CouponDiscountTypesEnum, CouponDiscountTypeName}
