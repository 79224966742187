import { IRoute } from "_interfaces";
import {
  Profile,
  ColonCancerInfo,
  Faq,
  PrivacyPolicy,
  TermsAndConditions,
  KitActivation,
  TestQuestionnaire,
  TestResultsList,
  UserRegistration,
  OrderPage,
  ThankYouQuestionnaire,
  Dashboard,
  LogoutPage,
  StripeActivationCodeCheckout,
  ActivationPage,
  Checkout,
  ColonPage,
  Hipaa,
  ForgotPassword,
  ResetPassword,
  ProductConsent,
  VideoPage,
  ConfirmEmail,
} from "_components";

import {
  AdminDashboard,
  AdminHomePage,
  AdminLogin,
  ProspectList,
  ProductList,
  UserList as AdminUserList,
  OrderList as AdminOrderList,
  CodeList as AdminActivationCodeList,
  AdminDownload,
  CodeResult as AdminCodeResult,
  CouponList as AdminCouponList,
  EnvelopeQR,
} from "_components/_admin";

import { Role, Url } from "_constants";

const adminRoutes: IRoute[] = [
  //Admin, Anonymous Routes
  { path: Url.ADMIN_HOME_PAGE, component: AdminHomePage },
  { path: Url.ADMIN_LOGIN, component: AdminLogin },

  // Admin, Authenticated Routes
  { path: Url.ADMIN_PROFILE, component: Profile, roles: [Role.ADMIN] },
  { path: Url.ADMIN_DASHBOARD, component: AdminDashboard, roles: [Role.ADMIN] },
  { path: Url.ADMIN_PRODUCT, component: ProductList, roles: [Role.ADMIN] },
  { path: Url.ADMIN_PROSPECT, component: ProspectList, roles: [Role.ADMIN] },
  { path: Url.ADMIN_ORDER, component: AdminOrderList, roles: [Role.ADMIN] },
  { path: Url.ADMIN_USER, component: AdminUserList, roles: [Role.ADMIN] },
  { path: Url.ADMIN_ACTIVATION_CODES, component: AdminActivationCodeList, roles: [Role.ADMIN] },
  { path: Url.ADMIN_DOWNLOAD, component: AdminDownload, roles: [Role.ADMIN]},
  { path: Url.ADMIN_CODE_RESULT, component: AdminCodeResult, roles: [Role.ADMIN] },
  { path: Url.ADMIN_COUPON, component: AdminCouponList, roles: [Role.ADMIN]},
  //{ path: Url.ADMIN_RESULT_IMAGE, component: AdminResultImage, roles: [Role.ADMIN] },  // not including it because it has its own handler and different layout
  { path: Url.ADMIN_ENVELOPE_CODE, component: EnvelopeQR },
  { path: Url.ADMIN_LOGIN, component: AdminLogin },

];

const customerRoutes: IRoute[] = [
  // Customer, Logged In Routes
  { path: Url.CUSTOMER_ACCOUNT_PROFILE, component: Profile, roles: [Role.CUSTOMER] },
  { path: Url.CUSTOMER_KIT_ACTIVATION, component: KitActivation, roles: [Role.CUSTOMER] },
  { path: Url.CUSTOMER_TEST_QUESTIONNAIRE, component: TestQuestionnaire, roles: [Role.CUSTOMER] },
  { path: Url.CUSTOMER_TEST_RESULTS_LIST, component: TestResultsList, roles: [Role.CUSTOMER] },
  { path: Url.CUSTOMER_CHECKOUT, component: Checkout, roles: [Role.CUSTOMER] },
  { path: Url.CUSTOMER_THANK_YOU_QUESTIONNAIRE, component: ThankYouQuestionnaire, roles: [Role.CUSTOMER] },
  { path: Url.CUSTOMER_DASHBOARD, component: Dashboard, roles: [Role.CUSTOMER] },

  // Customer, Anonymous Routes
  { path: Url.CUSTOMER_ORDER, component: OrderPage }, // allow to order anonymously
  { path: Url.CUSTOMER_PURCHASE_ACTIVATION, component: StripeActivationCodeCheckout },
  { path: Url.CUSTOMER_ACTIVATION, component: ActivationPage},
  { path: Url.CUSTOMER_LOGOUT, component: LogoutPage },
  { path: Url.CUSTOMER_COLON_CANCER_INFO, component: ColonCancerInfo },
  { path: Url.CUSTOMER_FAQ, component: Faq },
  { path: Url.CUSTOMER_PRIVACY_POLICY, component: PrivacyPolicy },
  { path: Url.CUSTOMER_TERMS, component: TermsAndConditions },
  { path: Url.CUSTOMER_REGISTRATION, component: UserRegistration },
  { path: Url.CUSTOMER_COLON_HOME, component: ColonPage },
  { path: Url.CUSTOMER_HIPAA, component: Hipaa },
  { path: Url.CUSTOMER_FORGOT_PASSWORD, component: ForgotPassword },
  { path: Url.CUSTOMER_RESET_PASSWORD, component: ResetPassword },
  { path: Url.CUSTOMER_CONFIRM_EMAIL, component: ConfirmEmail },
  { path: Url.CUSTOMER_PRODUCT_CONSENT, component: ProductConsent },
  { path: Url.CUSTOMER_VIDEO_PAGE, component: VideoPage },
  //{ path: Url.CUSTOMER_LOGIN, component: Login}, 
  //{ path: Url.DEFAULT_HOME, component: HomePage },
];

export { customerRoutes, adminRoutes }