import React, { useState } from "react";
import { FormButtons  } from "_constants";

interface ITableProps<T> {
  data: T[];
  onAddClick?: () => void;
  onEditClick?: (item: T) => void;
  onDeleteClick?: (item: T) => void;
  onSelectClick: (ids: string[]) => void;
  hasMultiSelect?: boolean;
  multiSelectLabel?: string;
  readOnly?: boolean;
  buttons?: number[];
  fieldList?: Map<string, [string, Function | undefined]>;
}

const BaseTable = <T extends { id?: number }>({
    data,
    onAddClick,
    onEditClick,
    onDeleteClick,
    onSelectClick,
    hasMultiSelect,
    multiSelectLabel,
    readOnly,
    buttons,
    fieldList
  }: ITableProps<T>) => {
  const [selected, setSelected] = useState<string[]>([]);

  if (data.length === 0) {
    return (
      <>
        No Data
        {buttons && buttons.indexOf(FormButtons.ADD) >= 0 && (
          <button type="button" className="btn btn-primary" onClick={onAddClick}>
            Add New
          </button>
        )}
      </>
    );
  }

  const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      selected.push(e.target.value);
      setSelected(selected);
    } else {
      setSelected(selected.filter((i: string) => i !== e.target.value));
    }
  };

  return (
    <table className="table table-hover table-striped">
      <thead>
        <tr>
          <th
            align="right"
            colSpan={
              Object.keys(data[0]).length +
              (buttons && buttons.indexOf(FormButtons.EDIT) >= 0 ? 1 : 0) +
              (buttons && buttons.indexOf(FormButtons.DELETE) >= 0 ? 1 : 0)
            }
          >
            {buttons && buttons.indexOf(FormButtons.ADD) >= 0 && onAddClick && (
              <button type="button" className="btn btn-primary" onClick={onAddClick}>
                Add New
              </button>
            )}
          </th>
        </tr>

        {/* column headers */}
        <tr>
          {hasMultiSelect && <th>Select</th>}

          {fieldList ? (
            <>
              {Array.from(fieldList.entries()).map((item: [string, [string, Function | undefined]], i: number) => {
                const [fieldName, [fieldTitle,  ]] = item;
                return <th key={`${fieldName}-${i}`}>{fieldTitle}</th>;
              })}
            </>
          ) : (
            <>
              {Object.keys(data[0]).map((key) => {
                return <th key={key}>{key}</th>;
              })}
            </>
          )}

          {buttons && buttons.indexOf(FormButtons.EDIT) >= 0 && <th>Edit</th>}
          {buttons && buttons.indexOf(FormButtons.DELETE) >= 0 && <th>Delete</th>}
        </tr>
      </thead>

      <tbody>
        {data.map((item: T, index: number) => (
          <tr key={index}>
            {hasMultiSelect && (
              <td>
                <input type="checkbox" name="selected" value={item.id?.toString()} onChange={onSelect} />
              </td>
            )}

            {fieldList ? (
              <>
                {Array.from(fieldList.entries()).map((field: [string, [string, Function | undefined]], i: number) => {
                  const [fieldName, [, fn]] = field;
                  if (typeof fn === 'function') {
                    return <td key={`${fieldName}-${i}`}>{fn(item)}</td>;
                  }
                  return <td key={`${fieldName}-${i}`}>{Object(item)[fieldName]}</td>;
                })}
              </>
            ) : (
              <>
                {Object.keys(data[0]).map((key) => {
                  return <td key={key.toString()}>{Object(item)[key]}</td>;
                })}
              </>
            )}

            {buttons && buttons.indexOf(FormButtons.EDIT) >= 0 && onEditClick && (
              <td>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    onEditClick(data[index]);
                  }}
                >
                  Edit
                </button>
              </td>
            )}
            {buttons && buttons.indexOf(FormButtons.DELETE) >= 0 && onDeleteClick && (
              <td>
                <button
                  onClick={() => {
                    if (window.confirm("Are You Sure You Want to Delete?")) {
                      onDeleteClick(data[index]);
                    }
                  }}
                  className="btn btn-secondary"
                >
                  Delete
                </button>
              </td>
            )}
          </tr>
        ))}
        <tr></tr>

        {hasMultiSelect && (
          <tr>
            <td>
              <button
                className="btn btn-primary"
                onClick={() => {
                  onSelectClick(selected);
                }}
              >
                {multiSelectLabel}
              </button>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export { BaseTable };
