import React from "react";
import { IUser } from "_interfaces";
import { adminUserApiService } from "_services";
import { ListContainer } from "_common";
import { UserForm } from ".";
import { FormButtons } from "_constants";

const UserList: React.FunctionComponent = () => {
  
  const emptyValues: IUser = {
    email: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    phoneNumber: '',
    city: '',
    country: "USA", //@Todo: Add country field in form
    zipcode: '',
    state: '',
  };

  const onSelectedRows = (ids: string[]) => {
    //console.log('selected ', ids);
  }

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['ID', undefined]],
    ['email', ['E-Mail Address',undefined]],
    ['firstName', ['First Name',undefined]],
    ['lastName', ['Last Name',undefined]],
    ['address1', ['Address1',undefined]],
    ['city', ['City',undefined]],
    ['state', ['State',undefined]],
    ['zipcode', ['Zip Code', undefined]],
  ]);

  return (
    <ListContainer 
      apiService={adminUserApiService} 
      emptyValues={emptyValues} 
      onSelectedRows={onSelectedRows}
      buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
      hasMultiSelect={false}
      title="Manage Users"
      fieldList={fieldList}
    >
      {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
        return (
          <UserForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick}/>
        );
      }}
    </ListContainer>
  );
};

export { UserList };
