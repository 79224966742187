import React from 'react';
import { AddressFields } from '_common';
import { IAddressFieldMap } from '_interfaces';


const BillingForm:React.FunctionComponent<{errors: any, touched: any }>= ({errors, touched}) => {
  const fieldMap: IAddressFieldMap = {
    firstName: 'billingFirstName',
    lastName: 'billingLastName',
    address1: 'billingAddress1',
    address2: 'billingAddress2',
    city: 'billingCity',
    state: 'billingState',
    zipcode: 'billingZip',
    phoneNumber: 'billingPhoneNumber'
  };

  return (
    <AddressFields errors={errors} touched={touched} fieldMap={fieldMap} label='Billing Info'/>
  );
}

export { BillingForm }

