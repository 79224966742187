import React from "react";
import { BaseForm, CodeResultField, CodeStatusField } from "_common";
import { IActivationCode, IDataField } from "_interfaces";
import { adminActivationCodeApiService as apiService } from "_services";
import { Validator, validationMap } from "_components/_schemas";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IActivationCode) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField[];
}

const CodeForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }: IAppProps) => {
  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.ADMIN_ACTIVATION_CODE_FORM)}
      editableFields={editableFields}
    >
      {({ errors, touched }) => {
        return (
          <>
          <div className="form-group">
          Select Code Status
            <CodeStatusField errors={errors} touched={touched} name="statusId" />
          </div>

          <div className="form-group">
            Select Code Result
            <CodeResultField errors={errors} touched={touched} name="resultId" />
          </div>

          </>
        );
      }}
    </BaseForm>
  );
};

export { CodeForm };
