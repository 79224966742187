
class Helper {

  /**
   * Clones an object with only the given fields as its properties
   * @param fields 
   * @param object 
   * @returns 
   */
  public static cloneObject<T>(fields: string[], object: Object) {
    const clone: any = Object.assign({}, object);
    // eslint-disable-next-line array-callback-return
    Object.keys(clone).map( k => {
      if (fields.indexOf(k) < 0) {
        delete clone[k];
      }
     })
     return clone as T;
  }


  public static getUtcDate (date: Date) : string {
    if (!date) date = new Date();

    let year = date.getUTCFullYear();
    let month = (1 + date.getUTCMonth()).toString().padStart(2, '0');
    let day = date.getUTCDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day;
  }

  public static getFormattedDate (date: Date): string {
    if (!date) date = new Date();

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day;
  }

  public static getFormattedDateMDY ( date: Date) : string {
    if (!date) {
    	date = new Date();
    }

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

		return month + '/' + day + '/' + year;
  }

  public static restoreDash(code: string): string {
    return code.slice(0,4) + '-' + code.slice(4);
  }


}

export { Helper }