import axios from 'axios';
import { BaseApiService } from '.';
import { adminAuthenticationService } from '.';
import { config } from '_config';
import { ICustomerPayment, IProduct, IProspect, IActivationCode, IUser, ICoupon } from '_interfaces';

class AdminApiService<T> extends BaseApiService<T> {}


class AdminPaymentApiService extends BaseApiService<ICustomerPayment> {

  // overrides getAll with POST instead of GET
  async getAll(): Promise<ICustomerPayment[]> {
    try {
      const response: any = await axios.post<ICustomerPayment[]>(this.allUrl, {}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as ICustomerPayment[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/admin`;
const adminProductApiService: AdminApiService<IProduct> = new AdminApiService<IProduct>(apiBaseUrl, '/product', '/products', adminAuthenticationService);
const adminProspectApiService: AdminApiService<IProspect> = new AdminApiService<IProspect>(apiBaseUrl, '/prospect', '/prospects', adminAuthenticationService);
const adminPaymentApiService: AdminPaymentApiService = new AdminPaymentApiService(apiBaseUrl, '/payment', '/payments', adminAuthenticationService);
const adminActivationCodeApiService: AdminApiService<IActivationCode> = new AdminApiService<IActivationCode>(apiBaseUrl, '/product-code', '/product-codes', adminAuthenticationService);
const adminUserApiService: AdminApiService<IUser> = new AdminApiService<IUser>(apiBaseUrl, '/user', '/users', adminAuthenticationService);
const adminCouponApiService: AdminApiService<ICoupon> = new AdminApiService<ICoupon>(apiBaseUrl, '/coupon', '/coupons', adminAuthenticationService);

export { AdminApiService, adminProductApiService, adminProspectApiService, adminPaymentApiService, adminActivationCodeApiService, adminUserApiService, adminCouponApiService }