import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { SimpleCheckout } from "_components";
import { config } from "_config";


const OrderPage: React.FunctionComponent = () => {

  useEffect(()=> {
    window.scrollTo(0,0);
  }, [])

  return (
    <Elements stripe={loadStripe(config.stripePublicKey)}>
      <SimpleCheckout />
    </Elements>
  );
};

export { OrderPage };
