import React from "react";
import { AddressFields, BaseForm, FieldWithError } from "_common";
import { IUser } from "_interfaces";
import { adminUserApiService as apiService } from "_services";
import { Validator, validationMap } from '_components/_schemas';

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IUser) => void;
  initialValues: any;
  onCancelClick: () => void;
}

const UserForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick }: IAppProps) => {

  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.COMMON_ADDRESS)}
    >
      {({errors, touched}) => {
        return(
          <>
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="email"
            placeholder="E-Mail Address"
            label="Email Address"
          />
          <AddressFields errors={errors} touched={touched} label="Name and Address" />          
          </>
        )
      }}
    </BaseForm>
  );
};


export {UserForm}