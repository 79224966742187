import React from "react";
import { ErrorMessage, Field } from "formik";
import { IKeyValue } from "_interfaces";
const YesNoField: React.FunctionComponent<{ groupName: string; label: string; name: string, errors: any, touched: any }> = ({ groupName, label, name, errors, touched }) => {
  const options: IKeyValue<string>[] = [
    { key: "0", value: "No" },
    { key: "1", value: "Yes" },
  ];

  return (
    <>
      <div id={name} className="small-text-3">{label}</div>
      <div role="group" aria-labelledby={name} className={"form-control" + (errors && errors[name] ? " is-invalid" : "")}>
        {options.map((i: IKeyValue<string>) => (
          <div className="form-check" key={`key-${name}-${i.key}`} >
            <Field id={`${name}-${i.key}`} className="form-check-input" type="radio" name={name} value={i.key} />
            <label className="form-check-label" htmlFor={`${name}-${i.key}`}>
              {i.value}
            </label>
          </div>
        ))}
      </div>
      {errors && <ErrorMessage name={name} component="div" className="invalid-feedback" />}

    </>
  );
};

export { YesNoField };
