import React from "react";
import { ErrorMessage, Field } from "formik";
import { IKeyValue } from "_interfaces";

const DropDown: React.FunctionComponent<{
  errors: any;
  touched: any;
  enumMap: Map<number, string>;
  defaultItem?: IKeyValue<string | number>;
  label: string;
  name: string;
}> = ({ errors, touched, enumMap, label, name, defaultItem }) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <Field
        name={name}
        as="select"
        className={"form-control" + (errors && errors[name] && touched && touched[name] ? " is-invalid" : "")}
      >
        {defaultItem && <option value={defaultItem.key}>{defaultItem.value}</option>}

        {Array.from(enumMap.keys()).map((key: number, i: number) => (
          <option key={`code_${i}`} value={key}>
            {enumMap.get(key)}
          </option>
        ))}
      </Field>
      {errors && <ErrorMessage name={name} component="div" className="invalid-feedback" />}
    </div>
  );
};

export { DropDown };
