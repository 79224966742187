import { ErrorMessage, Field } from "formik";
import React from "react";

const ConfirmCheck: React.FunctionComponent<{ errors: any; touched: any; name: string; label?: any; readOnly?: boolean, children?: any }> = ({
  errors,
  touched,
  name,
  label,
  readOnly = false,
  children
}) => {
  return (
    <div>{label}
      <div role="group" className={"form-control" + (errors && errors[name] && touched && touched[name] ? " is-invalid" : "")}>
        <Field disabled={readOnly} key={`confirm-${name}`} id={`confirm-${name}`} className="form-check-input mx-2" name={name} type="checkbox" />
        <label className="form-class-label" htmlFor={`confirm-${name}`}>
          {children}
        </label>
      </div>
      {errors && <ErrorMessage className="mt-2 invalid-feedback" name={name} component="div" />}
    </div>
  );
};

export { ConfirmCheck };
