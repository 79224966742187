import React from "react";
import { AddressFields, BaseForm, FieldWithError } from "_common";
import { IProspect } from "_interfaces";
import { adminProspectApiService as apiService } from "_services";
import { Validator, validationMap } from '_components/_schemas';

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IProspect) => void;
  initialValues: any;
  onCancelClick: () => void;
}

const ProspectForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick }: IAppProps) => {

  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.COMMON_ADDRESS)}
    >
      {({errors, touched}) => {
        return(
          <>
          <div className="container-md my-2">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="email"
            placeholder="E-Mail Address"
            label="Email Address"
          />

          </div>
          <AddressFields errors={errors} touched={touched} label="Prospect Name and Address" />          
          </>
        )
      }}
    </BaseForm>
  );
};


export {ProspectForm}