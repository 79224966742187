import React from "react";

const ProductConsent = () => {
  return (
    <div className="product-consent">
      <h1>Consent for Services</h1>
      <p>Last Modified: December 11, 2021</p>

      <h2>Introduction</h2>

      <p>
        This consent form (“Consent”) reviews the benefits, risks and limitations of you, as the person who has created an Account
        (referred to as “you” or “your”), utilizing the Services provided by Convena Health, Inc. (referred to as “Convena
        Health”, “we”, “us” or “our”). It also explains how your information and sample(s) will be used after performance of the
        Services. Your sample(s) will not be processed unless you confirm that you have read and understood the contents of this
        form.
      </p>

      <p>
        By clicking on the box, you indicate that this Consent is a binding agreement and that you have read and understood the
        following terms. Capitalized terms used but not defined in this Consent have the meaning given to them in our other
        policies.
      </p>

      <p>
        By clicking your acceptance, you have chosen to use our Services and have given your informed consent to have your
        biological sample(s) tested for biomarkers and/or genes. Biological samples shall consist solely of human fluids and/or
        tissue samples, depending on the test purchased, that are intended to be analyzed for the individual’s sole use, and not
        for any other purpose, including but not limited to research, publications, commercial uses, or any other purpose other
        than to inform the purchaser’s health and/or wellness.
      </p>

      <h2>Voluntary Participation</h2>

      <p>
        Our Services are offered and available to users who are residents of the United States. Your use of Convena Health’s
        Services is voluntary. It is your choice whether to utilize our Services or not. Prior to signing this Consent, you may
        wish to speak with a genetic counselor or your healthcare provider for further guidance about our Services.
      </p>

      <h2>Procedures and Protocol for Convena Health Tests</h2>

      <p>
        When you create your Account, we will collect some information from you. We cannot perform the Services without collecting
        information from you but we will only collect information that will assist us in providing the Services that you have
        requested. In order for the Services to be provided as intended, you must provide accurate and correct information.
        Failure to provide required information or to execute required documents may result in your sample(s) not being tested.
      </p>
      <p>
        All requests for tests will be reviewed by a licensed physician (“Health Consultant”) affiliated with a company that we
        have partnered with to provide review and authorization of testing, review of lab results, prescriptions and counseling
        where applicable. Services will not be provided to you if a Health Consultant does not authorize your request and order
        the applicable test(s). We will refund the applicable fees for such canceled tests.
      </p>
      <p>
        You will collect the biological sample(s) (i.e., blood, saliva, or urine) that will be provided to and tested by either
        our lab or one of our designated testing laboratory partners (“Lab”). You will send the sample(s) directly to the Lab
        where your sample(s) will be tested.
      </p>
      <p>
        The results of your test(s) will be made available to you through our Site. In the event we determine that a biological
        sample is not suitable for testing due to the content of the sample or because we believe the sample to have been
        submitted in violation of this Consent or the User Agreement, we reserve the right to withhold the results and to not
        provide a refund. We will determine and establish normal ranges for all tests in conjunction with the Labs based on their
        validation and proficiency testing procedures. If your results are clinically significant or have a critical value that
        falls outside of our established normal ranges by at least a certain amount, a Health Consultant will make reasonable
        efforts to contact you.
      </p>

      <h2>Benefits</h2>

      <p>
        Utilizing our Services can help you gain a better perspective on your health and how your body functions. You may use this
        information to make more informed health care decisions and choices.
      </p>

      <div className="container my-2">
        <div className="row">
          <div className="col border">Fecal Immunochemical Test (FIT)</div>
          <div className="col border">
            The presence of blood in your stool to help detect colon cancer early so it may be treated.
          </div>
        </div>
      </div>

      <p>
        *Diagnostic test panels. If your results for these test panels identify a marker as detected or positive, a consult from a
        Health Consultant licensed in your state of residence will be offered to you at no additional cost. When appropriate and
        permitted by law, the Health Consultant may prescribe treatment for positive results.
      </p>
      <p>More information about the specific benefits of our tests and Services are available on our Site.</p>

      <h2>Risks</h2>

      <p>In order to utilize our Services, you must collect the appropriate biological sample(s) for the test(s). </p>
      <p>
        Disclosing certain information may make you uncomfortable. Our Services include stool tests that may reveal sensitive
        information about your health. This information may be distressing.
      </p>
      <p>
        Health information that you choose to share with your physician or other healthcare providers may become part of your
        medical record and through that route may be accessible to other healthcare providers and/or insurance companies in the
        future. Information that you share with family, friends or employers may also be used against your interests. Even if you
        share health information that has no or limited meaning today, that information could have greater meaning in the future
        as new discoveries are made.
      </p>
      <p>
        Convena Health, or the Health Consultant who reviews your results, may advise you to have a follow-up visit with your
        doctor after reviewing the results of your test. As a result, you may learn about health conditions and problems or
        potential health risks that you were not aware of before you utilized our Services. You may experience stress, anxiety, or
        emotional or physical discomfort when you learn about health problems or potential health problems. There may also be
        additional risks of utilizing our Services that are currently unforeseeable.
      </p>

      <h2>Limitations</h2>
      <p>
        OUR SERVICES DO NOT PROVIDE MEDICAL ADVICE. The information and content provided, including but not limited to text,
        graphics, images, videos, and other material contained on our Site and in the Services, are for informational purposes
        only and are not intended as a substitute for professional medical advice, help, diagnosis or treatment. Always seek the
        advice of your physician or other qualified health care provider with any questions you have regarding your medical care,
        and never disregard professional medical advice or delay seeking it because of something you have read on or via our Site.
        Nothing contained in the Services is intended to constitute a medical diagnosis or treatment. Reliance on any information
        appearing in the Services, including but not limited to information provided by Convena Health or by other users of the
        Services, is solely at your own risk. By clicking your acceptance, you understand and agree that your results are not a
        substitute for professional medical advice. You understand that Convena Health does not provide medical services,
        diagnosis, treatment, or advice. You acknowledge that the Health Consultants are not employees of Convena Health and are
        not providing services on behalf of Convena Health, but instead are independent professionals solely responsible for the
        services each provides to you.
      </p>
      <p>
        Convena Health does not warrant the accuracy, completeness, timeliness or usefulness of the opinions, content, services or
        other information provided through the Services or on the Internet generally. Convena Health and its affiliates,
        licensors, and suppliers have no control over and accept no responsibility for your compliance with the laws applicable to
        your state of residence.
      </p>
      <p>
        Convena Health implements several safeguards to avoid technical errors, but as with all medical tests, there is a chance
        of a false positive or a false negative result. A false positive result means a biomarker was detected, which is not in
        fact present. A false negative result means the test failed to identify biomarker that is in fact present. Other sources
        of error include sample mix-up, poor sample quality or contamination, inherent DNA sequence properties, and technical
        errors in the laboratory.{" "}
      </p>
      <p>
        Results interpretation is based on currently available information in the medical literature and scientific databases. The
        field of medicine is constantly changing and our understanding of test results is evolving. New information may replace or
        add to the information that we used to interpret your results. We are not obligated to notify you if there is a new
        understanding of testing that might result in a change to the interpretation of your results. We reserve the right to
        contact you, at our option, in the future.
      </p>

      <h2>Retention and Use of Your Information</h2>
      <p>
        We are subject to multiple laws on the retention of data. Accordingly, we retain any information collected about you for
        as long as we are required to maintain it for regulatory and compliance purposes or for a legal or business necessity.
      </p>
      <p>
        You will send your biological sample(s) directly to the Lab that will conduct the test. Convena Health has no obligations
        or liability regarding the retention of your sample(s). The Lab testing your sample(s) is subject to multiple laws
        regarding the retention of data and samples and will maintain your data and sample(s) for as long as required for
        regulatory and compliance purposes or for legal or business necessity.
      </p>
      <p>
        You understand that by providing any sample, having your sample processed, accessing your results, or providing
        information to us, you acquire no rights in any research or commercial products that may be developed by Convena Health or
        its collaborating partners. You specifically understand that you will not receive compensation for any research or
        commercial products that include or result from your sample or information.
      </p>
      <p>
        Your information and test results may be stored in a repository and used for validation, educational, and/or research
        purposes. By clicking your acceptance of this Consent, you acknowledge and agree that Convena Health may de-identify the
        information that is obtained from our Services and that we may aggregate this information with de-identified information
        from other users. De-identification means that the personally identifiable information associated with your information
        will be removed. You understand that we will not disclose, sell and otherwise commercialize personally identifiable
        information but that we may disclose, sell and otherwise commercialize de-identified information and User Content without
        restriction.
      </p>
      <p>
        We may incorporate different or additional technologies to test or analyze data in the future. We may, at our sole
        discretion, choose to test or analyze your data using such technologies. We are not obligated to notify you if we chose to
        conduct different or additional testing or analysis on your data. Your purchase of our Services does not automatically
        include any such different or additional technologies. You may have to pay additional fees in order to receive Services
        using any future or additional technologies or features.
      </p>

      <h2>Confidentiality</h2>
      <p>
        By clicking your acceptance of this Consent, you acknowledge that you have read and understand our Privacy Policy and
        HIPAA Privacy Authorization. You agree the Convena Health is not liable for the unauthorized release of your results or
        information unless such unauthorized release was the result of gross negligence or willful misconduct on the part of
        Convena Health.
      </p>

      <h2>Withdrawal of Consent</h2>
      <p>
        Your use of Convena Health’s Services is voluntary. You may choose to withdraw your Consent or to stop using Convena
        Health’s Services at any time. Such requests to should be sent to us by email at contact@Convenahealth.com or in writing
        at Convena Health, Inc. 92 E. Main St. Suite 405 Somerville, NJ. 08876.
      </p>
      <p>
        Please note that while anysuch requests you make will be reflected in our databases within a reasonable period of time, we
        may retain your information in the ordinary course of business, for the satisfaction of our legal obligations, or where we
        otherwise reasonably believe that we have a legitimate reason to do so. Information that has already been de-identified,
        anonymized, or aggregated may not be retrievable or traced back for destruction, deletion, or amendment. Legal Agreement
      </p>
      <p>
        You give permission for Convena Health, its representatives, affiliates, staff, agents, and designees to perform the
        requested Services on the sample(s) you provide and to disclose your information and results in accordance with our
        Privacy Policy and your HIPAA Privacy Authorization. You are not an insurance company or an employer attempting to obtain
        information about an insured person or an employee.
      </p>

      <h2>Dispute Resolution</h2>
      <p>
        In the event of any dispute, claim, question, or disagreement arising from or relating to this Consent or the purchase,
        registration, or use of any Convena Health product or Services, we and you (collectively, the “Parties”) shall use their
        best efforts to settle the dispute, claim, question, or disagreement. To this effect, the Parties shall consult and
        negotiate with each other in good faith and, recognizing their mutual interests, attempt to reach a just and equitable
        solution satisfactory to both Parties. If the Parties do not reach such solution within a period of thirty (30) days, then
        all disputes shall be resolved by binding arbitration in Somerville, NJ, in accordance with the Commercial Arbitration
        Rules of the American Arbitration Association (the “AAA”), subject to the limitations of this section. This agreement to
        arbitrate will be specifically enforceable in any court having jurisdiction. Notice of a demand for arbitration shall be
        filed in writing with the other party hereto and with the AAA. The demand for arbitration shall be made within a
        reasonable time after the dispute has arisen, and in no event shall any such demand be made after the date when
        institution of legal or equitable proceedings based on such dispute would be barred by the applicable statute of
        limitations. The Parties agree that one (1) arbitrator shall arbitrate the dispute. The arbitrator shall be selected by
        the joint agreement of the Parties, but if they do not so agree within twenty (20) days after the date of the notice of a
        demand for arbitration referred to above, the selection shall be made pursuant to the Commercial Arbitration Rules of the
        AAA from the panels of business arbitrators maintained by the AAA. The Parties agree that the arbitrator shall have sole
        authority to decide whether claims brought by either party (excluding claims brought under immediately following paragraph
        regarding “Preliminary Equitable Relief and Intellectual Property Claims)) are subject to this dispute resolution
        agreement. The decision of the arbitrator shall be made in writing, shall be final, judgment may be entered upon it in any
        court having jurisdiction thereof, and the decision shall not be subject to vacation, modification or appeal, except to
        the extent permitted by sections 10 and 11 of the Federal Arbitration Act, the terms of which sections the Parties agree
        shall apply. The fees charged by the AAA and arbitrator shall be shared equally by the parties.
      </p>

      <h2>Preliminary Equitable Relief and Intellectual Property Claims</h2>
      <p>
        Either party may bring a claim related to intellectual property rights, or seek temporary or preliminary specific
        performance or temporary or preliminary injunctive relief, in any court of competent jurisdiction, without the posting of
        bond or other security.
      </p>

      <h2>Governing Law</h2>
      <p>
        All disputes arising out of or relating to this Consent or the purchase, registration, or use of any Convena Health
        product or Services shall be governed by New Jersey law regardless of where you access the Site or the Services, and
        notwithstanding of any conflicts of law principles.
      </p>

      <h2>Miscellaneous</h2>
      <p>Class Action Waiver</p>
      <p>
        Any proceedings to resolve or litigate any dispute in any forum will be conducted solely on an individual basis. Neither
        you nor we will seek to have any dispute heard as a collective or class action or in any other proceeding in which either
        party acts or proposes to act in a representative capacity, and each party hereby waives any right to assert consolidated
        claims with respect to any disputes subject to arbitration under the User Agreement. No arbitration or proceeding will be
        combined with another without the prior written consent of all parties to all affected arbitrations or proceedings.
      </p>
      <p>Limitation of Time to File Claims</p>
      <p>
        Any action, claim or dispute you have against us must be filed within one year, unless prohibited by applicable law. To
        the extent permitted by law, any claim or dispute under this agreement must be filed within one year in an arbitration
        proceeding. The one-year period begins when the claim or notice of dispute first could be filed. If a claim or dispute is
        not filed within one year, it is permanently barred.
      </p>
      <p>Notices</p>
      <p>
        You agree that we may provide you with notices by email to the address you provided at the time of registration or as
        changed in your Member profile on the Site.
      </p>
      <p>No Affiliation with Convena Health </p>
      <p>
        You agree not to hold yourself out as in any way sponsored by, affiliated with, endorsed by, in partnership or venture
        with, nor as an employee or employer of Convena Health, any of our affiliates or service providers.
      </p>
      <p>Entire Agreement</p>
      <p>
        This Agreement, including the other documents referenced and referred to herein, constitutes the entire agreement between
        you and us with respect to the Site and the Services and supersede all prior or contemporaneous understandings and
        agreements, whether written or oral, with respect thereto. No failure to exercise, and no delay in exercising, on the part
        of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial
        exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a
        conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern. If
        any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the provision will be
        amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will
        continue in full force and effect. The headings of sections and paragraphs in this Agreement are for convenience only and
        shall not affect its interpretation.
      </p>

      <h2>Contacting Convena Health</h2>
      <p>
        If you have questions or comments about our Services or this Consent, please contact us by email at
        contact@convenahealth.com, or by letter to:
      </p>
      <p>
        Convena Health, Inc.
        <br />
        92 E. Main St. Suite 405
        <br />
        Somerville, NJ. 08876
        <br />
      </p>
    </div>
  );
};

export { ProductConsent };
