import React from "react";
import { Formik, Form } from "formik";
import { IApiService, IDataField } from "_interfaces";
import { Helper } from "_common";

interface IChildrenProps {
  errors: any;
  touched: any;
  setFieldValue?: any;
}

interface IAppProps<T> {
  isEditing: boolean;
  onSuccess: (item: T) => void;
  initialValues: T;
  onCancelClick: () => void;
  validationSchema: any;
  apiService: IApiService<T>;
  children: (props: IChildrenProps) => React.ReactNode;
  editableFields?: IDataField [];
}

const BaseForm = <T extends {id?: number}>({ isEditing, onSuccess, initialValues, onCancelClick, validationSchema, apiService, children, editableFields }: IAppProps<T>) => {

  const onSubmit = async (data: any, actions: any) => {
    const { setStatus, setSubmitting } = actions;
    const itemData: T = Object.assign(data);

    setSubmitting(true);
    if (isEditing) {

      const fields: string[] = editableFields ? editableFields.filter((f: IDataField) => f.editable === true ).map((f: IDataField) => f.name) : [];
      const updateData: T = editableFields ? Helper.cloneObject<T>(fields, itemData) : itemData;
      
      try {
        const id: number = itemData.id || 0;
        delete itemData.id;
        const item: T = await apiService.update(updateData, id);
        item.id = id;
        onSuccess(item);
      } catch (error: any) {
        console.error("could not edit", error);
        setStatus(`Edit Failed: ${error.message}`);
      }

    } else {
      try {
        const item: T = await apiService.create(itemData);
        onSuccess(item);
      } catch (error) {
        console.error("Add Failed", error);
        setStatus(`Could not create record: ${error}`);
      }
    }
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, isSubmitting, errors, touched, status, setFieldValue }) => {
        return (
          <Form>
            {children({errors, touched, setFieldValue})}

            {status && <div className="alert my-3">{status}</div>}

            <div className="container-md mt-5">
              <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-md">Submit</button>
              <button disabled={isSubmitting} type="button" onClick={onCancelClick} className="btn btn-secondary btn-md mx-3">Cancel</button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export { BaseForm };
