import React from 'react';
import { FieldWithError } from "_common";

const PersonalInfo: React.FunctionComponent<{ errors: any; touched: any, readOnly?: boolean }> = ({ errors, touched, readOnly }) => {
  return (
    <div className="container">
      <div className="title medium-text-1">Personal Information</div>
      <div className="row">
        <div className="col">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="firstName"
            placeholder="Enter First Name"
            label="First Name"
            readOnly={readOnly}
          />
        </div>

        <div className="col">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="lastName"
            placeholder="Enter Last Name"
            label="Last Name"
            readOnly={readOnly}
          />
        </div>
      </div>
    </div>
  );
};

export { PersonalInfo }