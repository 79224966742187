import React from "react";
import { Field, Form, Formik } from "formik";
import { FieldWithError } from "_common";
import { validationMap, Validator } from "_components/_schemas";
import { ICodeStatus } from "_interfaces";
import { customerCodeService } from "_services/CustomerCodeService";
import { StorageKey } from "_constants";

const CompanionCodePurchase: React.FunctionComponent<{ onAgree: any; onDisagree: any }> = ({ onAgree, onDisagree }) => {
  const initialFormState = {
    validationCode: "",
    codeA: "",
    codeB: "",
  };

  const onDisagreeClick = async () => {
    onDisagree();
  };
  const onSubmit = async (data: any, actions: any) => {
    const { setStatus } = actions;
    try {
      const activationCode = data.activationCode.replace("-", "");
      if (activationCode === localStorage.getItem(StorageKey.ACTIVATION_CODE)) {
        throw Error("Code cannot be the same as its companion");
      }
      const codeStatus: ICodeStatus = await customerCodeService.getStatus(activationCode);
      if (codeStatus.error) {
        throw Error(codeStatus.error.message);
      }
      setStatus(codeStatus.statusId);
      onAgree(activationCode);
    } catch (error: any) {
      setStatus(error.message);
    }
  };
  const validationSchema = validationMap.get(Validator.ACTIVATION_FORM);

  //@TODO: this is repeated code from ActivationForm.tsx. Refactor to use single component.
  const updateCode = (setFieldValue: any, values: any) => {
    setFieldValue(
      "activationCode",
      "" + (values.codeA ? values.codeA.toUpperCase() : "") + (values.codeB ? values.codeB.toUpperCase() : "")
    );
  };

  return (
    <div className="container-sm">
      <div className="row d-flex justify-content-center">
        <div className="col-md-6">
          <div className="small-text-2">
            <p>We Have A Savings Opportunity For You!</p>

            <p>
              The package your received in the mail contains another testing kit. Would you also like to pay for its processing at
              discounted price of $29.95? If so, please enter the second activation code to pay for processing.
            </p>
            <Formik initialValues={initialFormState} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ values, isSubmitting, errors, touched, setStatus, status, setFieldValue }) => {
                return (
                  <Form>
                    <div className="small-text-2 mb-2 text-center">Please enter your second Activation Code</div>
                    <div className="d-flex justify-content-center">
                      <div>
                        <Field name="codeA" maxLength={4} size={4} onBlur={() => updateCode(setFieldValue, values)} />
                        {errors && errors.codeA && <div className="text-red">{errors.codeA}</div>}
                      </div>
                      <div className="mx-2">-</div>
                      <div>
                        <Field name="codeB" size={4} maxLength={4} onBlur={() => updateCode(setFieldValue, values)} />
                        {errors && errors.codeB && <div className="text-red">{errors.codeB}</div>}
                      </div>

                    </div>

                    <div style={{ display: "none" }}>
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="activationCode"
                        placeholder="Activation Code"
                      />
                    </div>

                    <div className="text-center">
                    <button className="btn btn-primary fs-5 mt-3" type="submit" disabled={isSubmitting}>
                      Add Companion Code To Order
                    </button>
                    <button
                      className="btn btn-primary fs-5 mt-3 mx-3"
                      type="button"
                      disabled={isSubmitting}
                      onClick={onDisagreeClick}
                    >
                      No, Thank You
                    </button>

                    </div>
                    {status && <div className="alert ">Message: {status}</div>}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CompanionCodePurchase };
