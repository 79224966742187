import React from "react";

const VideoPage = () => {
  return (
    <div className="container">
      <h4>Colon Cancer FIT Test</h4>

      <div className="video-container my-5">
        <video controls autoPlay={true} width="100%" poster="/images/play-background.png">
          <source src="/videos/colon-cancer-animation.mp4" type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    </div>
  );
};

export { VideoPage };
