import React from "react";
import { adminCouponApiService } from "_services";
import { ListContainer } from "_common";
import { CouponDiscountTypeName, CouponStatusName, FormButtons } from "_constants";
import { CouponForm } from ".";
import { IDataField } from "_interfaces";

const CouponList: React.FunctionComponent = () => {
  
  const emptyValues: any = {
    statusId: undefined,
    name: '',
    code: '',
    discountTypeId: '',
    discount: 0,
    expireDateUtc: new Date(),
    quantity: 0,
  };

  
  const getStatusName = (row: any): string | undefined => CouponStatusName.get(Number(row['statusId']));
  const getDiscountTypeName = (row: any): string | undefined => CouponDiscountTypeName.get(Number(row['discountTypeId']));

  const editableFields: IDataField[] = [
    {name: 'statusId', title: 'Status ID', editable: false},
    {name: 'name', title: 'Coupon Name', editable: true},
    {name: 'code', title: 'Code Name', editable: true},
    {name: 'discountTypeId', title: 'Discount Type', editable: true},
    {name: 'discount', title: 'Discount', editable: true},
    {name: 'expireDateUtc', title: 'Expire Date UTC', editable: true},
    {name: 'quantity', title: 'Quantity', editable: true},
    {name: 'quantityUsed', title: 'Quantity Used', editable: false},
];

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['ID', undefined]],
    ['statusId', ['Status ID',undefined]],
    ['statusName', ['Status Name', getStatusName]],
    ['name', ['Name',undefined]],
    ['code', ['Code',undefined]],
    ['discountTypeId', ['Discount Type ID',undefined]],
    ['discountTypeName', ['Discount Type Name', getDiscountTypeName]],
    ['expireDateUtc', ['Expiration Date',undefined]],
    ['quantity', ['Quantity',undefined]],
    ['quantityUsed', ['Quantity Used',undefined]],
  ]);

  return (
    <>
    <ListContainer 
      apiService={adminCouponApiService} 
      emptyValues={emptyValues} 
      hasMultiSelect={false}
      buttons={[FormButtons.EDIT, FormButtons.DELETE, FormButtons.ADD]}
      title="Coupon Management"
      fieldList={fieldList}
      editableFields={editableFields}
    >
      {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
        return (
          <CouponForm isEditing={isEditing} editableFields={editableFields} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick}/>
        );
      }}
    </ListContainer>
    </>
  );
};

export { CouponList };
