import React from "react";
import { Field } from "formik";
import { ProductCodeStatusName } from "_constants";

const CodeStatusField = ({ ...rest }) => {
  return (
    <Field {...rest} as="select">
      <option value="">Status</option>

      {
        Array.from(ProductCodeStatusName.keys()).map( (key: number, i: number) =>
          <option key={`code_${i}`} value={key}>{ProductCodeStatusName.get(key)}</option>
        )
      }

    </Field>
  );
};

export { CodeStatusField };
