import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { QuestionEnum, StorageKey, Url } from "_constants";
import { customerCodeService } from "_services";
import { QuestionnaireForm, ReviewAnswers } from "_components";
import ReactTooltip  from "react-tooltip";

enum Stage {
  STARTED = 1,
  CANCELED = 2,
  COMPLETED = 3,
  REVIEW = 4,
}

const TestQuestionnaire: React.FunctionComponent = () => {
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [status, setStatus] = useState<string>("");
  const [stage, setStage] = useState<number>(Stage.STARTED);
  const [allAnswers, setAllAnswers] = useState<any[]>([]);

  const previousAnswers = localStorage.getItem(StorageKey.PREVIOUS_ANSWERS);
  const initialValues:any = previousAnswers ? JSON.parse(previousAnswers) : undefined;

  const initialFormState = {
    gender: initialValues ? initialValues[`q${QuestionEnum.GENDER}`] || "" : "",
    historyCheck: initialValues ? initialValues[`q${QuestionEnum.DISEASE_HISTORY}`]?.map((i: number) => i.toString()) || [] : [],
    familyWithColonCancer: initialValues
      ? initialValues[`q${QuestionEnum.FAMILY_WITH_COLON_RECTAL_CANCER}`]?.map((i: number) => i.toString()) || []
      : [],
    hasBloodInPriorBM: initialValues
      ? initialValues[`q${QuestionEnum.RECENT_BRIGHT_RED_BLOOD}`]?.toString() || undefined
      : undefined,
    hasMenstrualPeriod: initialValues
      ? initialValues[`q${QuestionEnum.FEMALE_MENSTRUAL_PERIOD_3D_STOOL_SAMPLE}`]?.toString() || 2
      : 2, // Defaulting this to N/A
    lastColonoscopyYear: initialValues ? initialValues[`q${QuestionEnum.RECENT_COLONOSCOPY_YEAR}`] : 0,
    colonoscopyResult: initialValues
      ? initialValues[`q${QuestionEnum.RECENT_COLONOSCOPY_RESULT}`]?.toString() || undefined
      : undefined,
    medicalConditionOptions: initialValues
      ? initialValues[`q${QuestionEnum.MEDICAL_CONDITIONS}`]?.map((i: number) => i.toString()) || []
      : [],
    hasPCP: initialValues ? initialValues[`q${QuestionEnum.PRIMARY_CARE_PHYSICIAN}`] || undefined : undefined,
    ethnicity: initialValues ? initialValues[`q${QuestionEnum.ETHNICITY}`]?.toString() || undefined : undefined,
    race: initialValues ? initialValues[`q${QuestionEnum.RACE}`]?.map((i: number) => i.toString()) || [] : [],
  };

  initialFormState.lastColonoscopyYear =
    initialFormState.lastColonoscopyYear === false ? "0" : initialFormState.lastColonoscopyYear;

  const steps = [
    "gender",
    "race",
    "ethnicity",
    "historyCheck",
    "familyWithColonCancer",
    "hasBloodInPriorBM",
    "hasMenstrualPeriod",
    "lastColonoscopyYear",
    "colonoscopyResult",
    "medicalConditionOptions",
    "hasPCP",
  ];

  const validationSchemaMap: Map<string, Object> = new Map();
  validationSchemaMap.set("gender", Yup.object().shape({ gender: Yup.string().required("Please select your gender") }));
  validationSchemaMap.set("historyCheck", Yup.object().shape({ historyCheck: Yup.array().min(1, "Please select an answer") }));
  validationSchemaMap.set(
    "familyWithColonCancer",
    Yup.object().shape({ familyWithColonCancer: Yup.array().min(1, "Please select an answer") })
  );
  validationSchemaMap.set(
    "hasBloodInPriorBM",
    Yup.object().shape({ hasBloodInPriorBM: Yup.string().required("Please select an answer") })
  );
  validationSchemaMap.set(
    "hasMenstrualPeriod",
    Yup.object().shape({ hasMenstrualPeriod: Yup.string().required("Please select an answer") })
  );
  validationSchemaMap.set(
    "lastColonoscopyYear",
    Yup.object().shape({ lastColonoscopyYear: Yup.string().required("Please select an answer") })
  );
  validationSchemaMap.set(
    "colonoscopyResult",
    Yup.object().shape({ colonoscopyResult: Yup.string().required("Please select an answer") })
  );
  validationSchemaMap.set(
    "medicalConditionOptions",
    Yup.object().shape({ medicalConditionOptions: Yup.array().min(1, "Please select an answer") })
  );
  validationSchemaMap.set("hasPCP", Yup.object().shape({ hasPCP: Yup.string().required("Please specify if you have a PCP") }));
  validationSchemaMap.set("race", Yup.object().shape({ race: Yup.array().min(1, "Please select at least one option") }));
  validationSchemaMap.set(
    "ethnicity",
    Yup.object().shape({ ethnicity: Yup.string().required("Please select at least one option") })
  );

  const cancelActivation = async (e: any) => {
    if (window.confirm("Are you sure you want to cancel and reset the activation process?")) {
      const activationCode = localStorage.getItem(StorageKey.ACTIVATION_CODE);
      if (activationCode) {
        try {
          await customerCodeService.cancelActivation(activationCode);
          setStepIndex(steps.length);
          setStatus("");
          setStage(Stage.CANCELED);
          localStorage.removeItem(StorageKey.ACTIVATION_CODE);
          localStorage.removeItem(StorageKey.PREVIOUS_ANSWERS);
        } catch (error) {
          console.error("Error canceling activation", error);
          setStatus("Could not cancel. Try again later");
        }
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const setCurrentStage = () => {
    if (stepIndex === steps.length - 1) {
      setStage(Stage.REVIEW);
    } else if (stepIndex >= steps.length - 1) {
      setStage(Stage.COMPLETED);
    } else {
      setStage(Stage.STARTED);
    }
  };

  const onSubmit = async (data: any, actions: any) => {
    // skip if menstraul question and gender is Male
    if (data.gender === "M" && stepIndex === 5) {
      //@TODO: refactor this. Questionnaire will break if questions are re-ordered.
      setStepIndex(stepIndex + 2);
    } else {
      setStepIndex(stepIndex + 1);
    }

    setCurrentStage();

    // just exit if completed stage
    if (stage === Stage.COMPLETED) {
      return;
    }

    const answers: any = {};
    const keys = [
      `q${QuestionEnum.GENDER}`,
      `q${QuestionEnum.RACE}`,
      `q${QuestionEnum.ETHNICITY}`,
      `q${QuestionEnum.DISEASE_HISTORY}`,
      `q${QuestionEnum.FAMILY_WITH_COLON_RECTAL_CANCER}`,
      `q${QuestionEnum.RECENT_BRIGHT_RED_BLOOD}`,
      `q${QuestionEnum.FEMALE_MENSTRUAL_PERIOD_3D_STOOL_SAMPLE}`,
      `q${QuestionEnum.RECENT_COLONOSCOPY_YEAR}`,
      `q${QuestionEnum.RECENT_COLONOSCOPY_RESULT}`,
      `q${QuestionEnum.MEDICAL_CONDITIONS}`,
      `q${QuestionEnum.PRIMARY_CARE_PHYSICIAN}`,
    ];

    const values: any[] = [
      data.gender,
      data.race.map((i: string) => Number(i)),
      data.ethnicity,
      data.historyCheck.map((i: string) => Number(i)),
      data.familyWithColonCancer.map((i: string) => Number(i)),
      data.hasBloodInPriorBM,
      data.hasMenstrualPeriod,
      data.lastColonoscopyYear,
      data.colonoscopyResult,
      data.medicalConditionOptions.map((i: string) => Number(i)),
      data.hasPCP,
    ];

    answers[keys[stepIndex]] = values[stepIndex];

    //const isDraft = stepIndex < steps.length - 1;
    const allAnswers: any = {};
    for (let i = 0; i < steps.length; i++) {
      allAnswers[keys[i]] = values[i];
    }

    setAllAnswers(allAnswers);

    setStatus("");
    try {
      const activationCode = localStorage.getItem(StorageKey.ACTIVATION_CODE);
      if (activationCode) {
        //const response = await customerCodeService.activate(activationCode, isDraft ? answers : allAnswers, isDraft);
        const response = await customerCodeService.activate(
          activationCode,
          stage === Stage.REVIEW ? allAnswers : answers,
          stage !== Stage.REVIEW
        );
        if (response.error) {
          throw Error(response.error.message);
        }
        localStorage.removeItem(StorageKey.PREVIOUS_ANSWERS);
        if (stage === Stage.REVIEW) {
          setStage(Stage.COMPLETED);
        }
      }
    } catch (error: any) {
      setStatus(error.message);
    }
  };

  const CompletedQuestionnaire = () => {
    return (
      <div className="container-sm p-5">
        <div className="large-text-1 mb-3">Congratulations! Your kit is now activated!</div>
        <div className="small-text-2">
          <p>
            Please check the instructions in the kit for how to collect the sample. You can also watch this{" "}
            <a href={`${Url.CUSTOMER_VIDEO_PAGE}`}>video</a>.
          </p>
          <p>After you mail it back to us, you can keep track of the status of the test by logging into your account.</p>

          <p>We will also send you an email when the results are available.</p>
          <p>Thank you for using Convena Health!</p>
        </div>
      </div>
    );
  };

  const CanceledQuestionnaire = () => {
    return (
      <div className="container my-5 p-5">
        <div className="row">
          <div className="col small-text-1 text-center">
            Activation has been canceled. Click <a href={Url.CUSTOMER_ACTIVATION}>here</a> to activate another code.
          </div>
        </div>
      </div>
    );
  };

  const ProgressTab: React.FunctionComponent<{ stage: Stage }> = ({ stage }) => {
    // Map usage <key: currentTab>
    const stageMap: Map<number, Map<number, string>> = new Map([
      [
        Stage.STARTED,
        new Map([
          [Stage.STARTED, "checked right-rounded"],
          [Stage.REVIEW, ""],
          [Stage.COMPLETED, ""],
        ]),
      ],
      [
        Stage.REVIEW,
        new Map([
          [Stage.STARTED, "checked"],
          [Stage.REVIEW, "checked right-rounded"],
          [Stage.COMPLETED, ""],
        ]),
      ],
      [
        Stage.COMPLETED,
        new Map([
          [Stage.STARTED, "checked"],
          [Stage.REVIEW, "checked"],
          [Stage.COMPLETED, "checked right-rounded"],
        ]),
      ],
    ]);

    return (
      <>
        <div className="container">
          <div className="row stage-bar-container">
            <div className={"col-4 stage-bar left-rounded text-center " + stageMap.get(stage)?.get(Stage.STARTED)}>
              Health Profile
            </div>
            <div className={"col-4 stage-bar text-center " + stageMap.get(stage)?.get(Stage.REVIEW)}>Review</div>
            <div className={"col-4 stage-bar text-center " + stageMap.get(stage)?.get(Stage.COMPLETED)}>Activated</div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {stage !== Stage.STARTED ? (
        <div className="container-lg" id="questionnaire">
          <div className="mt-5 pt-5">
            <ProgressTab stage={stage} />
          </div>
          {!status && stage === Stage.CANCELED && <CanceledQuestionnaire />}

          {!status && stage === Stage.REVIEW && (
            <ReviewAnswers validationSchemaMap={validationSchemaMap} initialValues={allAnswers} onSubmit={onSubmit} />
          )}

          {!status && stage === Stage.COMPLETED && <CompletedQuestionnaire />}

          {status && <div className="alert alert-warning">{status}</div>}
        </div>
      ) : (
        <div className="container-md" id="questionnaire">
          <div className="mt-5 pt-5">
            <ProgressTab stage={stage} />
          </div>

          <Formik
            enableReinitialize
            initialValues={initialFormState}
            validationSchema={validationSchemaMap.get(steps[stepIndex])}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values, errors, touched, setFieldValue }) => {
              return (
                <div className="container-md">
                  <div className="row">
                    <div className="col">
                      <div className="alert alert-warning my-5 p-3 small-text-2 text-center">
                        <span className="fw-bold">PRIVACY NOTICE:</span> These questions are HIPAA-compliant. Your privacy is
                        paramount. We will never sell your information.
                      </div>
                      <Form>
                        <div className="container-md">
                          <div className="row justify-content-center">
                            <div className="col col-md-8 questionnaire-steps">
                              {stepIndex + 1} OF {steps.length}
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col col-md-8">
                              <QuestionnaireForm
                                values={values}
                                errors={errors}
                                touched={touched}
                                stepIndex={stepIndex}
                                showAll={false}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          </div>

                          <div className="row my-5 justify-content-center">
                            <div className="col-4 text-end">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => setStepIndex(stepIndex - 1)}
                                disabled={stepIndex < 1 || isSubmitting}
                              >
                                Previous
                              </button>
                            </div>
                            <div className="col-4 text-center">
                              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                Next
                              </button>
                            </div>
                            <div className="col-4 text-start">
                              <button data-tip data-for="cancelActivationTip" type="button" className="btn btn-secondary" onClick={(e) => cancelActivation(e)}>
                                Reset Answers
                              </button>
                              <ReactTooltip id="cancelActivationTip" place="top" effect="solid">
                                This resets the questionnaire and allows you to give the activation code to someone else.
                              </ReactTooltip>
                            </div>
                          </div>
                        </div>

                        {status && <div className="alert alert-warning">{status}</div>}
                      </Form>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

export { TestQuestionnaire };
