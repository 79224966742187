import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { IAccount, IUserContext } from "_interfaces";
import { UserContext } from "_common";
import { customerAccountService, customerAuthenticationService } from "_services";
import { useHistory } from "react-router";
import { Url } from "_constants";
import { validationMap, Validator } from "_components/_schemas";
import { AccountSetup, AddressInfo, ContactInfo, PersonalInfo } from "./_subforms";
import { DateOfBirth } from "./_subforms/DateOfBirth";

const Profile: React.FunctionComponent = () => {
  const userContext: IUserContext | null = useContext(UserContext);

  const history = useHistory();

  const initialFormState: any = {
    firstName: userContext?.account?.firstName,
    lastName: userContext?.account?.lastName,
    email: userContext?.account?.email,
    password: "",
    confirmPassword: "",
    address1: userContext?.account?.address1,
    address2: userContext?.account?.address2,
    city: userContext?.account?.city,
    state: userContext?.account?.state,
    zipcode: userContext?.account?.zipcode,
    phoneNumber: userContext?.account?.phoneNumber,
    dateOfBirth: userContext?.account?.dateOfBirth,
  };

  const validationSchema = validationMap.get(Validator.PROFILE_FORM);

  const onSubmit = async (data: any, actions: any) => {
    const { setSubmitting, setStatus } = actions;

    if (data.password === "") {
      delete data.password;
      delete data.confirmPassword;
    }

    const account: IAccount = {
      firstName: data.firstName,
      lastName: data.lastName,
      // email: data.email,  // cannot update email
      password: data.password,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      zipcode: data.zip,
      phoneNumber: data.phoneNumber,
      dateOfBirth: data.dateOfBirth,
    };

    try {
      setSubmitting(true);
      await customerAccountService.updateAccount(account);
      setStatus("Saved.");
    } catch (err: any) {
      setStatus(`Error: ${err}`);
    }
  };

  return (
    <>
      <div className="container-lg py-1" id="questionnaire">
        <div className="d-flex justify-content-center my-5">
          <div className="medium-text-1 mb-3 fw-bold text-primary">My Account</div>
          <button
            className="btn btn-primary ms-auto"
            type="button"
            onClick={() => {
              userContext?.setAccount(null);
              customerAuthenticationService.logout();
              history.push(Url.DEFAULT_HOME);
            }}
          >
            Logout
          </button>
        </div>

        <Formik initialValues={initialFormState} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ status, isSubmitting, errors, touched, setFieldValue, values }) => {
            return (
              <Form autoComplete="off">
                <div className="container-md my-3">
                  <div className="row justify-content-center mt-5">
                    <div className="col-md-6">
                      {/* Account Set up */}
                      <AccountSetup errors={errors} touched={touched} showPasswordToggle={true} emailDisabled={true}/>
                   </div>
                  </div>
                  <div className="row justify-content-center mt-5">
                    <div className="col-md-6">
                      {/* Personal Information*/}
                      <PersonalInfo errors={errors} touched={touched} />

                      {/* DoB */}
                      <div className="container my-2">
                      <DateOfBirth errors={errors} touched={touched} values={values} fieldName="dateOfBirth" setFieldValue={setFieldValue} />
                      </div>

                    </div>

                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6">
                        {/* Address */}
                        <AddressInfo errors={errors} touched={touched} />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6">
                        {/* Contact Information*/}
                        <ContactInfo errors={errors} touched={touched} />
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center mt-5">
                    <div className="col-md-6">{status && <div className="alert my-3">{status}</div>}</div>
                  </div>

                  <div className="row justify-content-center mt-5">
                    <div className="col-md-6 text-center">
                      <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-md">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export { Profile };
