import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions container">
      <h1 className="text-center">Agreement Between User and ConvenaHealth.com</h1>

      <p>
        Welcome to ConvenaHealth.com. The ConvenaHealth.com website (the "Site") is comprised of various web pages operated by
        Convena Care, LLC ("Convena"). ConvenaHealth.com is offered to you conditioned on your acceptance without modification
        of the terms, conditions, and notices contained herein (the "Terms"). Your use of ConvenaHealth.com constitutes your
        agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.
      </p>

      <p>ConvenaHealth.com is a Health E-Commerce Site.</p>

      <p>ConvenaHealth offers online products to help patients enjoy better health.</p>

      <p className="fw-bold">Privacy</p>
      <p>
        Your use of ConvenaHealth.com is subject to Convena's Privacy Policy. Please review our Privacy Policy, which also governs
        the Site and informs users of our data collection practices.
      </p>

      <p className="fw-bold">Electronic Communications</p>
      <p>
        Visiting ConvenaHealth.com or sending emails to Convena constitutes electronic communications. You consent to receive
        electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide
        to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.
      </p>

      <p className="fw-bold">Your Account</p>
      <p>
        If you use this site, you are responsible for maintaining the confidentiality of your account and password and for
        restricting access to your computer, and you agree to accept responsibility for all activities that occur under your
        account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge
        that Convena is not responsible for third party access to your account that results from theft or misappropriation of your
        account. Convena and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit
        content in our sole discretion.{" "}
      </p>
      <p>
        When you access the Site, you confirm: (i) that you are 18 years of age or older, (ii) that you are using the Site in your
        own name and not on behalf of anyone else; (iii) that you will not allow any other person to use the Site under your name,
        nor will you use the Site and pretend you are someone else, or otherwise seek to disguise your identity; and (iv) that you
        are only using the Site, the Products and the Services for your own benefit and not for the purposes of providing Services
        to others. If you provide any information that is untrue, inaccurate, non-current or incomplete, or we have reasonable
        grounds to suspect that such information is untrue, inaccurate, non-current or incomplete, in addition to any other rights
        we may have, we may terminate or refuse any and all current or future access or use of the Site (or any portion thereof).
      </p>
      <p>
        If you set up an Account with us, you agree to provide contact details that can be used to contact you in relation to your
        use of the Site, your Order, the Product of the Services. It is important that the details you provide us with are
        correct, accurate and complete and that you promptly update your Account. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
        CAUSED TO YOU AS A RESULT OF A FAILURE OR OMISSION BY YOU TO PROVIDE ACCURATE AND SUITABLE CONTACT DETAILS.
      </p>
      <p>
        In order to use the Services, you must set up an Account with us by creating a username and Password. We reserve the right
        to take any action that we deem necessary to ensure the security of the Site and your Account, including without
        limitation changing your Password, terminating your Account, or requesting additional information to authorize
        transactions on your Account. You are solely responsible for keeping your Password and any security questions and
        responses associated with your Account confidential. Anyone with knowledge of or access to your Password or the security
        questions and responses associated with your Account can use that information to gain access to your Account. You are
        solely liable for any claims, damages, losses, costs or other liabilities resulting from or caused by any failure to keep
        your Password and the security questions and responses confidential, whether such failure occurs with or without your
        knowledge or consent. You will immediately notify us of any suspected or actual unauthorized access to or use of your
        Password or any other breach of your Account security.
      </p>
      <p>
        You are also responsible for: (i) all transactions or activities carried out using your Account and Password; and (ii)
        ensuring that when you access the Account that the system you use to access the Account is secure, is not left unattended
        unless you have fully exited the Account and that your Password is not retrievable from the system by others.
      </p>
      <p>
        You are responsible for notifying us if you know or suspect that your Account or Password might be known to a third party.
        We will not be liable for any loss or damage that you may suffer as a result of your failure or omission to do so. We will
        have no liability to you or to any third party if any communication by us to you, using contact information provided by
        you or via your Account, is seen, received or accessible by any third party.
      </p>
      <p>
        By using the Site, you acknowledge that the transmission of data over the internet can never be completely secure and you
        accept the risk that others may be able to read or intercept any information, text, graphics you submit or send to or
        through the Site. This includes transmissions that are identified as secure or encrypted, and may include the Test
        Information.
      </p>

      <p className="fw-bold">Children Under Thirteen</p>
      <p>
        Convena does not knowingly collect, either online or offline, personal information from persons under the age of thirteen.
        If you are under 18, you may use ConvenaHealth.com only with permission of a parent or guardian.
      </p>

      <p className="fw-bold">Cancellation/Refund Policy</p>
      <p>
        You may cancel an order at any time prior to the test being mailed to you. Given the medical nature of the products, tests
        are not returnable. Please contact us at Contact@convenahealth.com with any questions.
      </p>

      <p className="fw-bold">Links to Third Party Sites/Third Party Services</p>
      <p>
        ConvenaHealth.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of
        Convena and Convena is not responsible for the contents of any Linked Site, including without limitation any link
        contained in a Linked Site, or any changes or updates to a Linked Site. Convena is providing these links to you only as a
        convenience, and the inclusion of any link does not imply endorsement by Convena of the site or any association with its
        operators.
      </p>

      <p>
        Certain services made available via ConvenaHealth.com are delivered by third party sites and organizations. By using any
        product, service or functionality originating from the ConvenaHealth.com domain, you hereby acknowledge and consent that
        Convena may share such information and data with any third party with whom Convena has a contractual relationship to
        provide the requested product, service or functionality on behalf of ConvenaHealth.com users and customers.
      </p>

      <p className="fw-bold">No Unlawful or Prohibited Use/Intellectual Property </p>
      <p>
        You are granted a non-exclusive, non-transferable, revocable license to access and use ConvenaHealth.com strictly in
        accordance with these terms of use. As a condition of your use of the Site, you warrant to Convena that you will not use
        the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could
        damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may
        not obtain or attempt to obtain any materials or information through any means not intentionally made available or
        provided for through the Site.
      </p>

      <p>
        All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof,
        and any software used on the Site, is the property of Convena or its suppliers and protected by copyright and other laws
        that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other
        proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.
      </p>

      <p>
        You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or
        in any way exploit any of the content, in whole or in part, found on the Site. Convena content is not for resale. Your use
        of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not
        delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for
        your personal use, and will make no other use of the content without the express written permission of Convena and the
        copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any
        licenses, express or implied, to the intellectual property of Convena or our licensors except as expressly authorized by
        these Terms.
      </p>

      <p className="fw-bold">International Users</p>
      <p>
        The Service is controlled, operated and administered by Convena from our offices within the USA. If you access the Service
        from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use
        the Convena Content accessed through ConvenaHealth.com in any country or in any manner prohibited by any applicable laws,
        restrictions or regulations.
      </p>

      <p className="fw-bold">Indemnification</p>
      <p>
        You agree to indemnify, defend and hold harmless Convena, its officers, directors, employees, health consultants, agents
        and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or
        arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any
        terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules
        or regulations. Convena reserves the right, at its own cost, to assume the exclusive defense and control of any matter
        otherwise subject to indemnification by you, in which event you will fully cooperate with Convena in asserting any
        available defenses.
      </p>

      <p className="fw-bold">Arbitration</p>
      <p>
        In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and
        Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other
        relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act,
        conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar
        arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award
        shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or
        equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party
        shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and
        claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether
        directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the
        Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the
        scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision
        shall survive the termination of these Terms and Conditions.
      </p>

      <p className="fw-bold">Class Action Waiver</p>
      <p>
        Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and
        class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE
        OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
        REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless
        both you and Convena agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not
        otherwise preside over any form of a representative or class proceeding.
      </p>

      <p className="fw-bold">Liability Disclaimer</p>
      <p>
        THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
        TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. CONVENA CARE, LLC. AND/OR ITS SUPPLIERS
        MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
      </p>

      <p>
        CONVENA CARE, LLC. AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
        TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR
        ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
        RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. CONVENA CARE, LLC. AND/OR ITS SUPPLIERS
        HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
        GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
        NON-INFRINGEMENT.
      </p>

      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL CONVENA CARE, LLC. AND/OR ITS SUPPLIERS OR HEALTH
        CONSULTANTS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
        WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED
        WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF
        OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH
        THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY
        OR OTHERWISE, EVEN IF CONVENA CARE, LLC. OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE
        SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
        THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE
        TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
      </p>

      <p className="fw-bold">Reliance on Information Posted</p>
      <p>
        The information presented on or through the Site is made available solely for general information purposes. We do not
        warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is
        strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such
        information by you or any other visitor to the Site, or by anyone who may be informed of any of its contents. The Site may
        include content provided by third parties, including materials provided by other users, bloggers and third-party
        licensors, syndicators, aggregators and/or reporting services. All statements and/or opinions expressed in these
        materials, and all articles and responses to questions and other content, other than the content we provide, are solely
        the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily
        reflect our opinion. We are not responsible, or liable to you or any third party, for the content or accuracy of any
        materials provided by any third parties.
      </p>

      <p className="fw-bold">We Do Not Provide Medical Advice</p>
      <p>
        WE DO NOT PROVIDE MEDICAL ADVICE. THE INFORMATION CONTAINED ON THE SITE, THE TEST INFORMATION, OUR CONTENT, THE SERVICES,
        AND THE PRODUCTS AVAILABLE THROUGH THE SITE ARE FOR GENERAL GUIDANCE ONLY AND ARE NOT A SUBSTITUTE FOR PROFESSIONAL
        MEDICAL ADVICE. NEITHER THE SITE NOR OUR SERVICES OR PRODUCTS, OR ANY TEST INFORMATION, CONSTITUTE MEDICAL ADVICE AND YOU
        SHOULD ALWAYS SEEK THE ADVICE OF YOUR PHYSICIAN OR OTHER LICENSED HEALTHCARE PROFESSIONAL WITH ANY QUESTIONS YOU MAY HAVE
        REGARDING ANY TEST INFORMATION OR STATUS OF YOUR HEALTH.
      </p>
      <p>This Section (We Do Not Provide Medical Advice) will survive any termination of these Terms.</p>

      <p className="fw-bold">Termination/Access Restriction </p>
      <p>
        Convena reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any
        portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the
        laws of the State of New Jersey and you hereby consent to the exclusive jurisdiction and venue of courts in New Jersey in
        all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that
        does not give effect to all provisions of these Terms, including, without limitation, this section.
      </p>

      <p>
        You agree that no joint venture, partnership, employment, or agency relationship exists between you and Convena as a
        result of this agreement or use of the Site. Convena's performance of this agreement is subject to existing laws and legal
        process, and nothing contained in this agreement is in derogation of Convena's right to comply with governmental, court
        and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by
        Convena with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to
        applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the
        invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches
        the intent of the original provision and the remainder of the agreement shall continue in effect.
      </p>

      <p>
        Unless otherwise specified herein, this agreement these Terms of Use, our Privacy Policy, and any User Agreement, Consent
        for Services, or HIPAA Privacy Authorization you have consented to constitute the entire agreement between the user and
        Convena with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether
        electronic, oral or written, between the user and Convena with respect to the Site. A printed version of this agreement
        and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or
        relating to this agreement to the same extent and subject to the same conditions as other business documents and records
        originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all
        related documents be written in English.
      </p>

      <p className="fw-bold">Changes to Terms</p>
      <p>
        Convena reserves the right, in its sole discretion, to change the Terms under which ConvenaHealth.com is offered. The most
        current version of the Terms will supersede all previous versions. Convena encourages you to periodically review the Terms
        to stay informed of our updates.
      </p>

      <p className="fw-bold">Contact Us</p>
      <p>Convena welcomes your questions or comments regarding the Terms:</p>

      <p>
        Convena Care, LLC
        <br />
        92 E. Main St. Suite 405
        <br />
        Somerville, New Jersey 08876
        <br />
      </p>

      <p>
        Email Address:
        <br />
        contact@convenahealth.com
        <br />
        Effective as of September 21, 2022
      </p>
    </div>
  );
};

export { TermsAndConditions };
