import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { FieldWithError } from "_common";
import { useHistory } from "react-router-dom";
import { ProductCodeStatus, StorageKey, Url } from "_constants";
import { customerCodeService } from "_services/CustomerCodeService";
import { ICodeHash, ICodeStatus, IMultiPartCode } from "_interfaces";
import { validationMap, Validator } from "_components/_schemas";
import { cartService, customerAccountService } from "_services";
import { config } from "_config";

const ActivationForm: React.FunctionComponent = () => {
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const hashedId: string | undefined = urlSearchParams.get("hashedId") || undefined;

  const [initialFormState, setInitialFormState] = useState<IMultiPartCode>({} as IMultiPartCode);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activationCode, setActivationCode] = useState<string>("");

  useEffect(() => {
    const _init = async () => {
      if (hashedId) {
        const response: ICodeHash = await customerCodeService.getCodeFromHash(hashedId);
        if (response.code) {
          setActivationCode(response.code);
          setInitialFormState({
            activationCode,
            codeA: activationCode.substring(1, 4),
            codeB: activationCode.substring(5),
          });
        }
      }
    };
    _init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationCode]);

  const validationSchema = validationMap.get(Validator.ACTIVATION_FORM);

  const onSubmit = async (data: any, actions: any) => {
    const { setSubmitting, setStatus } = actions;

    setSubmitting(true);
    setStatus("");
    try {
      const activationCode = data.activationCode.replace("-", "");
      const codeStatus: ICodeStatus = await customerCodeService.getStatus(activationCode, !customerAccountService.isLoggedIn());
      if (codeStatus.error) {
        throw Error(codeStatus.error.message);
      }

      switch (codeStatus.statusId) {
        case (ProductCodeStatus.PURCHASED):
          localStorage.setItem(StorageKey.ACTIVATION_CODE, activationCode);
          // it's already been paid; proceed to questionnaire; 
          history.push(Url.CUSTOMER_KIT_ACTIVATION);
          break;
        case (ProductCodeStatus.CREATED):
          // not yet paid. ask for payment.
          localStorage.setItem(StorageKey.ACTIVATION_CODE, activationCode)
          // set cart and proceed to purchase page
          cartService.setCart({ productId: config.productId, activationCodes: [activationCode] });
          history.push(Url.CUSTOMER_PURCHASE_ACTIVATION)
          break;
        case (ProductCodeStatus.ACTIVATING):
          if (codeStatus.answers) {
            setStatus("");
            localStorage.setItem(StorageKey.PREVIOUS_ANSWERS, JSON.stringify(codeStatus.answers));
            history.push(Url.CUSTOMER_KIT_ACTIVATION);
          } else {
            throw Error('The code you entered is in the process of being activated. You may try again after the other user cancels their activation attempt.');
          }
          break;
        
        default: 
          console.error(`${JSON.stringify(codeStatus, null, 2)}`)
          cartService.emptyCart();
          throw Error('This Activation Code has already been activated. Please double-check your test-card.');
      }

    } catch (error: any) {
      setStatus(error.message.replace('Error:', ''));
    }
  };

  const updateCode = (setFieldValue: any, values: any) => {
    setFieldValue("activationCode", "" + (values.codeA ? values.codeA.toUpperCase() : "") + (values.codeB ? values.codeB.toUpperCase() : ""));
  };

  return (
    <Formik enableReinitialize initialValues={initialFormState} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, isSubmitting, errors, touched, setStatus, status, setFieldValue }) => {
        return (
          <Form>
            <div className="small-text-3 mb-2 text-center">Please enter your Activation Code</div>

            <div className="d-flex justify-content-center">
              <div>
                <Field name="codeA" maxLength={4} size={4} onBlur={() => updateCode(setFieldValue, values)} />
                {errors && errors.codeA && <div className="text-red">{errors.codeA}</div>}
              </div>
              <div className="mx-2">-</div>
              <div>
                <Field name="codeB" size={4} maxLength={4} onBlur={() => updateCode(setFieldValue, values)} />
                {errors && errors.codeB && <div className="text-red">{errors.codeB}</div>}
              </div>
            </div>

            <div style={{ display: "none" }}>
              <FieldWithError errors={errors} touched={touched} fieldName="activationCode" placeholder="Activation Code" />
            </div>

            <div className="text-center">
              <button className="btn btn-primary fs-5 mt-3" type="submit">
                Submit Code
              </button>
              {status && <div className="alert alert-danger my-3">{status}</div>}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export { ActivationForm };
