import React from "react";
import { Formik, Form } from "formik";
import { FieldWithError } from "_common";
import { PersonCircle, KeyFill } from "react-bootstrap-icons";

const LoginForm:React.FunctionComponent<{initialFormState: any, validationSchema: any, onSubmit: any, onClickRegister: any}> = ({initialFormState, validationSchema, onSubmit, onClickRegister}) => {
  return(
    <Formik initialValues={initialFormState} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({ errors, status, touched, isSubmitting }) => {
      return (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col">
              <Form className="form">
                <div className="card">
                  <h4 className="card-header">Login Page</h4>
                  <div className="card-body">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col col-md-6">
                          <FieldWithError
                            errors={errors}
                            touched={touched}
                            fieldName="email"
                            placeholder="Enter E-Mail Address"
                            icon={<PersonCircle />}
                          />
                        </div>
                      </div>

                      <div className="row justify-content-center">
                        <div className="col col-md-6">
                          <FieldWithError
                            errors={errors}
                            touched={touched}
                            fieldName="password"
                            icon={<KeyFill />}
                            placeholder="Enter Password"
                            type="password"
                          />
                        </div>
                      </div>

                      <div className="row justify-content-center">
                        <div className="col col-md-4 text-center">
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="btn btn-primary btn-md justify-text-center"
                          >
                            Login
                          </button>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col lg-auto text-center">
                          Forgot password? <a href="/forgot-password">Reset it!</a>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col lg-auto text-center">
                          Don't have an account?{" "}
                          <button type="button" className="btn btn-primary mt-1" onClick={onClickRegister}>
                            Register Here
                          </button>
                        </div>
                      </div>

                      {status && <div className={"alert alert-danger"}>{status}</div>}
                      {isSubmitting && <div className="spinner-border"></div>}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      );
    }}
  </Formik>

  );
}

export { LoginForm }