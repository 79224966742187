import React, { useEffect } from 'react';
import { customerAuthenticationService } from '_services';


const LogoutPage = () => {
  useEffect( ()=> {
    customerAuthenticationService.logout();
  }, []);

  return(
    <>Logged out</>
  );
}

export { LogoutPage }