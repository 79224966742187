import React, { useState } from "react";
import { useHistory } from "react-router";
import { Url } from "_constants";
import { ConfirmProfile } from ".";


enum Answer {
  NO_ANSWER = 0,
  NO = 1,
  YES = 2,
}

const NotThePatient = (props: any) => {
  return (
    <div className="container small-text-2 d-flex justify-content-center justify-content-middle my-5">
      <div className="row">
        <div className="col">
          <p>You must be the person taking the FIT test in order to activate the kit. Please have the person who is being tested go to ConvenaHealth.com to activate their kit.</p>
        </div>
      </div>
    </div>
  );
};


const KitActivation: React.FunctionComponent = () => {
 
  const [answer, setAnswer] = useState<number>(Answer.NO_ANSWER);

  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const fromCart = queryParams.get("cart");

  const onNotPatientClick = async () => {
    setAnswer(Answer.NO);
  };

  const onConfirm = () => {
    history.push(Url.CUSTOMER_TEST_QUESTIONNAIRE);
  };

  const TestTaker = () => {
    return (
        <div className="container justify-content-center my-5 medium-text-2 text-center">
          <div className="row">
            <div className="col">
              {fromCart && fromCart === "1" ? <p>Thank you for your purchase.</p> : ""}
  
              Are you the test taker?
            </div>
          </div>

          <div className="row mt-4">
            <div className="col">
              <button type="button" className="btn btn-primary mx-2 fs-5" onClick={()=>setAnswer(Answer.YES)}>
                Yes
              </button>
              <button type="button" className="btn btn-primary mx-2 fs-5" onClick={onNotPatientClick}>
                No
              </button>
            </div>
          </div>
        </div>
    );
  };


  
  return (

    <div className="test-taker-question">
      <h3 className="blue fw-bold text-primary text-center">Kit Activation</h3>
      {answer === Answer.NO_ANSWER && <TestTaker />}

      {answer === Answer.NO && <NotThePatient />}

      {answer === Answer.YES && 
        <div className="container my-5">
        <div className="row justify-content-center my-2">
          <div className="col text-center medium-text-1">
            <p>Before proceeding with the activation, please confirm your information below</p>
          </div>

          <ConfirmProfile onConfirm={onConfirm} />
        </div>
      </div>
      }

    </div>
    
    
  );
};

export { KitActivation };
