import React from 'react';
import { FieldWithError } from "_common";

const ContactInfo: React.FunctionComponent<{ errors: any; touched: any, readOnly?: boolean }> = ({ errors, touched, readOnly = false }) => {
  return (
    <div className="container">
      <div className="title medium-text-1">Contact Info</div>
      <div className="row">
        <div className="col">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="phoneNumber"
            placeholder="Phone Number"
            label="Phone Number"
            readOnly={readOnly}
          />
        </div>
      </div>
    </div>
  );
};

export { ContactInfo }