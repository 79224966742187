import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { ModalResult, Profile } from "_components";
import { ProductCodeStatus, ProductCodeStatusName } from "_constants";
import { ICustomerPayment, IActivationCode } from "_interfaces";
import { customerCodeService, paymentService } from "_services";



const Dashboard = () => {
  const [payments, setPayments] = useState<ICustomerPayment[]>([]);
  const [codes, setCodes] = useState<IActivationCode[]>([]);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [activationCode, setActivationCode] = useState<IActivationCode>({} as IActivationCode);

  useEffect(() => {
    const _init = async () => {
      const payments: ICustomerPayment[] = await paymentService.getPayments();
      const codes: IActivationCode[] = await customerCodeService.getCodes();
      setPayments(payments);
      setCodes(codes);
    };
    _init();
  }, []);

  const OrdersAndPayments = () => {
    return (
      <>
        <h4>Orders and Payments</h4>
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th scope="col">Order ID</th>
              <th scope="col">Order Date</th>
              <th scope="col">Description</th>
              <th className="text-end" scope="col">
                Quantity
              </th>
              <th className="text-end">Amount</th>
              <th>Activation Codes</th>
              <th scope="col">Tracking #</th>
            </tr>
          </thead>
          <tbody>
            {payments &&
              payments.map((p: ICustomerPayment, i: number) => (
                <tr key={`key-${i}`}>
                  <td>{p.id}</td>
                  <td>{p.dateUtc}</td>
                  <td>{p.productDescription}</td>
                  <td className="text-end">{p.quantity}</td>
                  <td className="text-end">${p.amount?.toFixed(2)}</td>
                  <td>{p.codes?.join()}</td>
                  <td>{p.trackingNumber}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </>
    );
  };

  const onClickViewResult = (code: IActivationCode) => {
    setShowResult(true);
    setActivationCode(code);
  }

  const renderStatus = (code: IActivationCode) => {
    return (
      <>
      {
        (ProductCodeStatus.COMPLETED === code.statusId && code.resultId) ?
          (
              <button type="button" className="btn btn-primary" onClick={() => onClickViewResult(code)}>View Result</button>
          ) : (
              <>{code.statusId ? ProductCodeStatusName.get(code.statusId) : ''}</>
          )
      }
      </>
    );
  }

  const TestKits = () => {
    return (
      <>
        <h4>Tests</h4>
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th scope="col">Activation Code</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {codes &&
              codes.map((code: IActivationCode, i: number) => (
                <tr key={`row-${i}`}>
                  <td>{code.code}</td>
                  <td>{renderStatus(code)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </>
    );
  };

  return (
   
    <div>
      <ModalResult show={showResult} handleClose={()=>setShowResult(false)} activationCode={activationCode}/>
      <Tabs defaultActiveKey="tests" id="orders-and-tests" className="mb-3">
        <Tab eventKey="tests" title="Tests">
          <TestKits />
        </Tab>
        <Tab eventKey="orders" title="Orders and Payments">
          <OrdersAndPayments />
        </Tab>
        <Tab eventKey="profile" title="My Account Profile">
          <Profile />
        </Tab>
      </Tabs>
    </div>
  );
};

export { Dashboard };
