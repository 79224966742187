import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { paymentService } from "_services";
import { ActivationCodeCheckout } from "_components";

const StripeActivationCodeCheckout: React.FunctionComponent = () => {
  const [stripePromise, setStripePromise] = useState<any>(undefined);

  useEffect(() => {
    const _setup = async () => {
      try {
        const gateway = await paymentService.getGatewayInfo();
        setStripePromise(loadStripe(gateway.stripePubKey));
      } catch (error: any) {
        console.error(error);
        throw Error(error);
      }
    };

    _setup();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {stripePromise ? (
        <Elements stripe={stripePromise}>
          <ActivationCodeCheckout />
        </Elements>
      ) : (
        <div>Waiting for API...</div>
      )}
    </>
  );
};

export { StripeActivationCodeCheckout };
