import React from "react";
import { ListContainer } from "_common";
import { IProduct } from "_interfaces";
import { adminProductApiService } from "_services";
import { ProductForm } from ".";

const ProductList: React.FunctionComponent = () => {
  const emptyValues: IProduct = {
    id: 0,
    name: '',
    description: '',
    price: 0.00,
    price2: 0.00,
    priceN: 0.00,
  }

  return(
    <ListContainer
    apiService={adminProductApiService}
    emptyValues={emptyValues}>
      {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
        return (
          <ProductForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick}/>
        );
      }}
    </ListContainer>    
  );
};

export { ProductList };
