import React from "react";
import { Navigation, Footer } from "_components";

const CustomerLayout: React.FunctionComponent = ({ children }) => {
  return (
    <div className="main-container">
      <div className="container-fluid">
        <div className="">
          <div className="" style={{ minHeight: "100vh", position: "relative" }}>
            <div style={{paddingBottom: "25rem"}}>
              <Navigation />
              <div className="mt-5 pt-5"></div>
              {children}
            </div>
            <div style={{ position: "absolute", bottom: "0", right: "auto", height: "25rem"}}>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CustomerLayout };
