import React from 'react';
import { AddressFields } from '_common'
import { IAddressFieldMap } from '_interfaces';


const ShippingForm:React.FunctionComponent<{errors: any, touched: any }>= ({errors, touched}) => {
  const fieldMap: IAddressFieldMap = {
    firstName: 'shippingFirstName',
    lastName: 'shippingLastName',
    address1: 'shippingAddress1',
    address2: 'shippingAddress2',
    city: 'shippingCity',
    state: 'shippingState',
    zipcode: 'shippingZip',
    phoneNumber: 'shippingPhoneNumber',
  };

  return (
    <AddressFields errors={errors} touched={touched} fieldMap={fieldMap} label='Shipping Info'/>
  );
}

export { ShippingForm }

