import axios from "axios";
import { config } from '_config';
import { adminAuthenticationService as authService } from "_services";




class AdminStorageService {

  constructor (protected apiBaseUrl: string) {

  }

  async downloadFile(relativePath: string): Promise<string> {
    const url: string = `${this.apiBaseUrl}/storage/download-file`;
    const authHeader: any = await authService.getAuthHeader();
    authHeader.responseType = 'blob';
    const item = {filePath: relativePath};
    const response: any = await axios.post<any>(url, item, authHeader);
    const data = new Blob([response.data]);
    return window.URL.createObjectURL(data);
  }

  async uploadCollectionImage (file: any):Promise<any> { //@TODO: find the correct data type for file upload
    const url = `${this.apiBaseUrl}/storage/upload-file-tmp`;
    return this.post<any>(url, {file});
  }

  async post<T>(url:string, item: object): Promise<T> {
    try {
      const response: any = await axios.post<any>(url, item, await authService.getAuthHeader());
      if (response?.error) {
        throw Error(response.error.message);
      }
      return response.data;
    } catch (error: any) {
      throw Error(error);
    }    
  }
}


const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/admin`;
const adminStorageService = new AdminStorageService(apiBaseUrl);

export { adminStorageService }