import React from "react";
import { ErrorMessage } from "formik";
import { FieldWithError, StateField } from "_common";
import { IAddressFieldMap } from "_interfaces";

const AddressFields: React.FunctionComponent<{ errors: any; touched: any; label: string; fieldMap?: IAddressFieldMap }> = ({
  errors,
  touched,
  label,
  fieldMap,
}) => {
  if (!fieldMap) {
    fieldMap = {
      firstName: "firstName",
      lastName: "lastName",
      address1: "address1",
      address2: "address2",
      city: "city",
      state: "state",
      zipcode: "zipcode",
      phoneNumber: "phoneNumber",
    };
  }

  return (
    <div className="container">
      <div className="card">
        <h4 className="card-header">{label}</h4>
        <div className="card-body">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-12 col-md-6">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={fieldMap.firstName}
                  placeholder="Enter First Name"
                  label="First Name"
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={fieldMap.lastName}
                  placeholder="Enter Last Name"
                  label="Last Name"
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={fieldMap.address1}
                  placeholder="Enter Address Line 1"
                  label="Address Line 1"
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={fieldMap.address2}
                  placeholder="Enter Address Line 2 (Optional)"
                  label="Address Line 2 (Optional)"
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-5">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={fieldMap.city}
                  placeholder="Enter City"
                  label="City"
                />
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label>State</label>
                  <StateField
                    name={fieldMap.state}
                    className={
                      "form-control" +
                      (errors && errors[fieldMap.state] && touched && touched[fieldMap.state] ? " is-invalid" : "")
                    }
                  />
                {errors && <ErrorMessage name={fieldMap.state} component="div" className="invalid-feedback" />}
                </div>
              </div>

              <div className="col-md-3">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={fieldMap.zipcode}
                  placeholder="Zip Code"
                  label="Zip Code"
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-auto">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={fieldMap.phoneNumber}
                  placeholder="999-999-9999"
                  label="Phone Number"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AddressFields };
