import React from "react";
import { FieldWithError } from "_common";

const CouponForm: React.FunctionComponent<{
  errors: any;
  touched: any;
  name: string;
  setFieldValue: any;
  values: any;
  setCouponCode: any;
}> = ({ errors, touched, name, setFieldValue, values, setCouponCode }) => {
  const onClickApply = () => {
    setFieldValue("couponCode", values["coupon"]);
    setCouponCode(values["coupon"]);
  };
  return (
    <div className="d-flex flex-row align-items-end">
      <div className="fw-bold">
        <FieldWithError label="Coupon Code" errors={errors} touched={touched} fieldName={name} />
      </div>
      <div className="mx-2">
        <button type="button" className="btn btn-primary" onClick={onClickApply}>
          Apply
        </button>
      </div>
    </div>
  );
};

export { CouponForm };
