import axios from "axios";
import { config } from '_config';
import { IActivationCode, ICodeHash } from "_interfaces";
import { customerAuthenticationService as authService } from "_services";

class CustomerCodeService {

  constructor (
    protected apiBaseUrl: string
  ) {}

  getStatus(code: string, isAnonymous: boolean = true): Promise<any> {
    const url: string = `${this.apiBaseUrl}/code`;
    return isAnonymous ? this.anonPost<any>(url, {code}) : this.post<any>(url, {code});
  }

  getCodeFromHash(codeHash: string): Promise<ICodeHash> {
    const url: string = `${this.apiBaseUrl}/code-hash`;
    return this.anonPost<ICodeHash>(url, {codeHash});
  }

  activate(code: string, answers: object, isDraft: boolean = false): Promise<any> {
    const url: string = `${this.apiBaseUrl}/code-activate`;
    const payload = {
      code,
      answers,
      isDraft
    }
    return this.post<any>(url, payload);
  }

  getCodes(): Promise<IActivationCode[]> {
    const url: string = `${this.apiBaseUrl}/codes`;
    return this.getter<IActivationCode[]>(url);
  }

  cancelActivation(code: string): Promise<any> {
    const url: string = `${this.apiBaseUrl}/code-cancel-activation`;
    return this.post<any>(url, {code});

  }

  // utility: @TODO: move to a helper class
  async getter<T>(url:string): Promise<T> {
    try {
      const response: any = await axios.get<T>(url, await authService.getAuthHeader());
      if (response?.error) {
        throw Error(response.error.message);
      }
      return response.data.data;
    } catch (error: any) {
      console.error({error});
      throw Error(error);
    }    
  }

  async post<T>(url:string, item: object): Promise<T> {
    try {
      const response: any = await axios.post<T>(url, item, await authService.getAuthHeader());
      if (response.data.error) {
        console.error('error in post');
        throw Error(response.data.error.message);
      }
      return response.data as T;
    } catch (error: any) {
      throw Error(error);
    }    
  }

  async anonPost<T>(url:string, item: object): Promise<T> {
    try {
      const response: any = await axios.post<T>(url, item);
      if (response.data.error) {
        console.error('error in post');
        throw Error(response.data.error.message);
      }
      return response.data as T;
    } catch (error: any) {
      throw Error(error);
    }    
  }  
}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user/product`;
const customerCodeService = new CustomerCodeService(apiBaseUrl);

export { customerCodeService }