enum QuestionEnum {
  NAME_FIRST_LAST                         = 1,
  GENDER                                  = 2,
  DATE_OF_BIRTH                           = 3,
  ADDRESS                                 = 4,
  DISEASE_HISTORY                         = 5,
  FAMILY_WITH_COLON_RECTAL_CANCER         = 6,
  RECENT_BRIGHT_RED_BLOOD                 = 7,
  FEMALE_MENSTRUAL_PERIOD_3D_STOOL_SAMPLE = 8,
  RECENT_COLONOSCOPY_YEAR                 = 9,
  RECENT_COLONOSCOPY_RESULT               = 10,
  MEDICAL_CONDITIONS                      = 11,
  PRIMARY_CARE_PHYSICIAN                  = 12,
  RACE                                    = 13,
  ETHNICITY                               = 14,
}

export { QuestionEnum }