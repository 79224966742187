export enum Url {
  // ADMIN paths
  ADMIN_HOME_PAGE = '/admin',
  ADMIN_PROFILE = '/admin/profile',
  ADMIN_DASHBOARD = '/admin/dashboard',
  ADMIN_LOGIN = '/admin/login',
  ADMIN_PRODUCT = '/admin/product',
  ADMIN_PROSPECT = '/admin/prospect',
  ADMIN_ORDER = '/admin/order',
  ADMIN_ACTIVATION_CODES = '/admin/codes',
  ADMIN_USER = '/admin/user',
  ADMIN_DOWNLOAD = '/admin/download',
  ADMIN_CODE_RESULT = '/admin/code',
  ADMIN_COUPON = '/admin/coupon',
  ADMIN_RESULT_IMAGE = '/admin/result-image',
  ADMIN_ENVELOPE_CODE = '/admin/envelope/code/:envelopeID',

  // CUSTOMER paths
  CUSTOMER_DASHBOARD = '/dashboard',
  CUSTOMER_ACCOUNT_PROFILE = '/account-profile',
  CUSTOMER_KIT_ACTIVATION = '/test-activation',
  CUSTOMER_TEST_QUESTIONNAIRE = '/test-questionnaire',
  CUSTOMER_TEST_RESULTS_LIST = '/test-results-list',
  CUSTOMER_CHECKOUT = '/checkout',
  CUSTOMER_ORDER = '/order',
  CUSTOMER_THANK_YOU = '/thank-you',
  CUSTOMER_THANK_YOU_QUESTIONNAIRE = '/thank-you-questionnaire',
  CUSTOMER_COLON_CANCER_INFO = '/colon-cancer-info',
  CUSTOMER_FAQ = '/faq',
  CUSTOMER_PRIVACY_POLICY = '/privacy-policy',
  CUSTOMER_TERMS = '/terms-and-conditions',
  CUSTOMER_REGISTRATION = '/user-registration',
  CUSTOMER_LOGIN = '/login',
  CUSTOMER_LOGOUT = '/logout',
  CUSTOMER_ACTIVATION = '/activation',
  CUSTOMER_PURCHASE_ACTIVATION = '/purchase-activation',
  CUSTOMER_FORGOT_PASSWORD = '/forgot-password',
  CUSTOMER_RESET_PASSWORD = '/reset-password/:authID',
  CUSTOMER_VIDEO_PAGE = '/colon-cancer-fit-animation',
  CUSTOMER_CONFIRM_EMAIL = '/confirm-email/:authID',

  CUSTOMER_COLON_HOME = '/colon',
  CUSTOMER_HIPAA = '/hipaa',
  CUSTOMER_PRODUCT_CONSENT = '/product-consent',

  // common paths
  LOGOUT = '/logout',
  DEFAULT_HOME = '/',
}