import React, { useContext } from "react";
import { Redirect } from "react-router";
import { UserContext } from "_common";
import { Role, Url } from "_constants";
import { IUserContext } from "_interfaces";

const ActivationRedirector = (props: any) => {
  const hashedId: string = props.match.params.hashedId;
  const userContext: IUserContext | null = useContext(UserContext);
  const redirUrl = userContext?.account?.roleId === Role.ADMIN ? Url.ADMIN_CODE_RESULT : Url.CUSTOMER_ACTIVATION;
  return (
    <Redirect
      to={{
        pathname: redirUrl,
        search: `?hashedId=${hashedId}`,
      }}
    />
  );
};

export { ActivationRedirector };
