import React from "react";
import { Route, Redirect } from "react-router-dom";
import { IAccount } from "_interfaces";
import { Role } from "_constants";

export const PrivateRoute: React.FunctionComponent<{
  component: React.FC;
  path: string;
  roles: Role[];
  exact?: boolean;
  redirectUrl: string;
  account: IAccount | null;}> = ({ component, path, roles, exact, redirectUrl, account }) => {

    const isAllowed: boolean =
        !roles ||
        roles.length === 0 ||
        (account === null && roles.length === 0) ||
        (account !== null && account.roleId && roles.indexOf(account.roleId) >= 0) || false;


  return isAllowed ? <Route path={path} exact={exact} component={component} /> : <Redirect to={{
      pathname: redirectUrl,
      search: `?redir=${path}`
  }} />;
};
