import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { UserContext, Helper } from "_common";
import { ProductCodeResultEnum } from "_constants";
import { IActivationCode, IUserContext } from "_interfaces";

const Result: React.FunctionComponent<{ activationCode: IActivationCode }> = ({ activationCode }) => {
  const userContext: IUserContext | null = useContext(UserContext);

  const ResultText: Map<ProductCodeResultEnum, React.ReactNode> = new Map([
    [
      ProductCodeResultEnum.NEGATIVE,
      <>
        <p>
          This test shows that there was <span className="fw-bold">no microscopic blood</span> detected in your stool. Please inform your physician of these
          results. A negative test makes colon cancer less likely, but does not completely rule out disease. You should repeat
          this test every year. See your doctor if you notice any change in your bowel movements, visible blood in stools, unusual
          abdominal pain, or unusual weakness and fatigue.
        </p>
        <p className="tiny-text-1">Laboratory Director: Ashraf Nashed, MD</p>
      </>,
    ],
    [
      ProductCodeResultEnum.POSITIVE,
      <>
        <p>
          This test shows that there was <span className="fw-bold">microscopic blood detected</span> in your stool. Please inform your physician of these results
          immediately. <span className="fw-bold">We STRONGLY recommend that you have a colonoscopy performed as soon as possible for further evaluation of
          any positive stool test. Colonoscopy is usually performed by a Gastroenterologist.</span>
        </p>
        <p>
          There are many causes of blood in the stool, including colon cancer, hemorrhoids, benign or precancerous polyps,
          inflammatory bowel disease, and diverticulosis.{" "}
        </p>
        <p className="tiny-text-1">Laboratory Director: Ashraf Nashed, MD</p>
      </>,
    ],
    [ProductCodeResultEnum.INVALID, <>
    <p>Unfortunately, the test results came back invalid. Someone from Convena Health will contact you via email on next steps.</p>
    <p>- The Convena Health Team</p>
    </>],
  ]);

  const getImage = (activationCode: IActivationCode) => {
      if (activationCode.resultId === ProductCodeResultEnum.POSITIVE) {
        return <div className="h-2">
          <img src="/images/red-positive.png" alt="Positive" /> Positive (Abnormal)
          </div>
      }

      if (activationCode.resultId === ProductCodeResultEnum.NEGATIVE) {
        return <div className="h-25"><img src="/images/green-negative.png" alt="Negative" /> Negative (Normal)</div>
      }

      return <>INVALID</>;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col fw-bold">Patient Information</div>
      </div>

      <div className="row">
        <div className="col">
          <span className="fw-bold">Name:</span> {userContext?.account?.firstName} {userContext?.account?.lastName}
          <br />
          <span className="fw-bold">Date of Birth:</span> {Helper.getFormattedDateMDY( new Date(userContext?.account?.dateOfBirth + ' 00:00:00'))}
          <br />
        </div>

        <div className="col">
          <span className="fw-bold">Report Status:</span> FINAL
          <br />
          <span className="fw-bold">Test ID:</span> {activationCode.code}
          <br />
        </div>
      </div>

      <table className="table mt-2">
        <thead>
          <tr>
            <th>Test</th>
            <th>Result</th>
            <th>Collection Date</th>
            <th>Specimen Type</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Colon FIT</td>
            <td>{activationCode.resultId ? (<>{getImage(activationCode)}</>) : ""}</td>
            <td>{Helper.getFormattedDateMDY(new Date(activationCode.collectionDate + ' 00:00:00'))}</td>
            <td>Stool Sample</td>
          </tr>
        </tbody>
      </table>

      {activationCode.resultId && ResultText.get(activationCode.resultId)}
    </div>
  );
};

const ModalResult: React.FunctionComponent<{ show: any; handleClose: any; activationCode: IActivationCode }> = ({
  show,
  handleClose,
  activationCode,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="text-primary">
          Test Results for Kit {activationCode.code}
        </Modal.Title>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        <Result activationCode={activationCode} />
      </Modal.Body>
    </Modal>
  );
};

export { ModalResult };
