import React from 'react';
import { ActivationForm } from '_components';


const ActivationPage: React.FunctionComponent = () => {
  return(
    <div className="container activation-section mt-4">
      <div className="row d-flex justify-content-center">
        <div className="col-6">
        <h4 className="text-center fw-bold light-blue">Kit Activation</h4>
      
        <div className="mt-2">

        <ActivationForm />

        </div>

        </div>
      </div>

    </div>
  );
}

export { ActivationPage }