import React, { useEffect, useState } from "react";
import { customerAccountService } from "_services";

const ConfirmEmail: React.FunctionComponent = (props: any) => {
  const [ status, setStatus ] = useState<string>('');
  const [ message, setMessage ] = useState<string>('');

  useEffect(()=>{
    const _init = async () => {
      const payload = {
        authId: props.match.params.authID,
      };
      try {
        await customerAccountService.confirmEmail(payload);
        setMessage('Thank you for confirming your email address.');
      } catch (error: any) {
        setMessage('We could not confirm your email address.');
        setStatus(error.message);
      }

    }

    _init();
  }, [status, props.match.params.authID])


  return (
    <div className="container-md">
      <div className="row justify-content-center">
        <div className="col col-md-4">
          <h2 className="text-center text-primary">E-Mail Confirmation</h2>
          <div className="text-center small-text-1">{message}</div>
          {status && <div className="alert alert-danger">{status}</div>}
        </div>
      </div>
    </div>
  );
};

export { ConfirmEmail };
