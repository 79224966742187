import React from "react";
import { AdminNavigation } from "_components/_admin";

const AdminLayout: React.FunctionComponent = ({ children }) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <AdminNavigation />
          {children}
        </div>
      </div>
    </div>
  );
};

export { AdminLayout };
