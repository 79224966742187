import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { PageNotFound, CustomerLayout, AdminLayout, HomePage, Login, ActivationRedirector, EmptyLayout } from "_components";
import { PrivateRoute, UserContext } from "_common";
import { adminRoutes, customerRoutes } from "_config/routes";
import { IRoute } from "_interfaces";
import { IAccount } from "_interfaces";
import { Role, Url } from "_constants";
import { adminAuthenticationService, customerAuthenticationService } from "_services";

import "app.scss";
import { ResultImage } from "_components/_admin";

const App = () => {
  const initialCustomerAccount = customerAuthenticationService.getLocalAccount() || null;
  const initialAdminAccount = adminAuthenticationService.getLocalAccount() || null;

  const [customerAccount, setCustomerAccount] = useState<IAccount | null>(initialCustomerAccount ? initialCustomerAccount : null);
  const [adminAccount, setAdminAccount] = useState<IAccount | null>(initialAdminAccount ? initialAdminAccount : null);

  return (
    <Router>
      <Switch>
        <Route path={customerRoutes.map((r: IRoute) => r.path)}>
          <UserContext.Provider value={{ account: customerAccount, setAccount: setCustomerAccount }}>
            <CustomerLayout>
              <Switch>
                {customerRoutes.map((r: IRoute, i: number) => (
                  <PrivateRoute
                    key={`cust-${i}`}
                    exact
                    path={r.path}
                    component={r.component}
                    account={customerAccount}
                    roles={r.roles || []}
                    redirectUrl={Url.CUSTOMER_REGISTRATION}
                  />
                ))}
              </Switch>
            </CustomerLayout>
          </UserContext.Provider>
        </Route>

        <Route path={`${Url.ADMIN_RESULT_IMAGE}`}>
          <UserContext.Provider value={{ account: adminAccount, setAccount: setAdminAccount }}>
            <EmptyLayout>
              <Switch>
                <PrivateRoute
                  exact
                  path={`${Url.ADMIN_RESULT_IMAGE}`}
                  component={ResultImage}
                  account={adminAccount}
                  roles={[Role.ADMIN]}
                  redirectUrl={Url.ADMIN_LOGIN}
                />
              </Switch>
            </EmptyLayout>
          </UserContext.Provider>
        </Route>

        <Route path={adminRoutes.map((r: IRoute) => r.path)}>
          <UserContext.Provider value={{ account: adminAccount, setAccount: setAdminAccount }}>
            <AdminLayout>
              <Switch>
                {adminRoutes.map((r: IRoute, i: number) => (
                  <PrivateRoute
                    key={`admin-${i}`}
                    exact
                    path={r.path}
                    component={r.component}
                    account={adminAccount}
                    roles={r.roles || []}
                    redirectUrl={Url.ADMIN_LOGIN}
                  />
                ))}
                <PageNotFound />
              </Switch>
            </AdminLayout>
          </UserContext.Provider>
        </Route>

        <Route path="/" exact>
          <UserContext.Provider value={{ account: customerAccount, setAccount: setCustomerAccount }}>
            <CustomerLayout>
              <Route exact path="/" component={HomePage} />
            </CustomerLayout>
          </UserContext.Provider>
        </Route>

        <Route path="/code/activate/:hashedId" exact>
          <UserContext.Provider value={{ account: adminAccount, setAccount: setAdminAccount }}>
            <Route exact path="/code/activate/:hashedId" component={ActivationRedirector} />
          </UserContext.Provider>
        </Route>


        <UserContext.Provider value={{ account: customerAccount, setAccount: setCustomerAccount }}>
          <CustomerLayout>
            <Switch>
              <Route exact path="/login" component={Login} />
              <PageNotFound />
            </Switch>
          </CustomerLayout>
        </UserContext.Provider>
      </Switch>
    </Router>
  );
};

export default App;
