/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { ConfirmCheck } from "_common";
import { Hipaa, ProductConsent, ModalAgreement } from '_components';

const Agreements: React.FunctionComponent<{ errors: any; touched: any, readOnly?: boolean }> = ({ errors, touched, readOnly = false }) => {
  const [showHipaa, setShowHipaa] = useState<boolean>(false);
  const [showConsent, setShowConsent] = useState<boolean>(false);

  const onClickHipaa = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault(); 
    setShowHipaa(true);
  }

  const onClickConsent = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault(); 
    setShowConsent(true);
  }

  return (
    <>
      <ModalAgreement show={showHipaa} handleClose={()=>setShowHipaa(false)} title="HIPAA Authorizations"><Hipaa /></ModalAgreement>
      <ModalAgreement show={showConsent} handleClose={()=>setShowConsent(false)} title="Product Consent"><ProductConsent /></ModalAgreement>
      <div className="row mt-3">
        <div className="col">
          <ConfirmCheck
            name="agreeHipaa"
            errors={errors}
            touched={touched}
            readOnly={readOnly}
          >
            <div>I have read and accept the <a onClick={onClickHipaa} className="agreement">HIPAA Authorizations</a></div>
          </ConfirmCheck>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          <ConfirmCheck
            name="agreeProductConsent"
            errors={errors}
            touched={touched}
            readOnly={readOnly}
          >
            <div>I have read and accept the <a onClick={onClickConsent} className="agreement">Product Consent</a></div>
          </ConfirmCheck>
        </div>
      </div>
    </>
  );
};

export { Agreements }