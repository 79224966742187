import { IAccount } from "_interfaces";
import axios from "axios";
import { config } from "_config";
import { customerAuthenticationService } from "_services";
import { Role, StorageKey } from "_constants";

class CustomerAccountService {
  constructor (
    protected apiBaseUrl: string
  ) {}

  async register(registrationData: IAccount): Promise<any> {
    const url = `${this.apiBaseUrl}/registration`;
    try {
      const response = await axios.post(url, registrationData);
      if (response.data.error) {
        console.error('throwing', response.data.error);
        throw Error(response.data.error.message);
      }
      localStorage.setItem(StorageKey.CUSTOMER_TOKEN, response.data.token);
      await customerAuthenticationService.setLocalAccount(Role.CUSTOMER);
      return customerAuthenticationService.getLocalAccount();
    } catch (err: any) {
      console.error('error in reg', {err});
      throw Error(err.message);
    }
  }

  isLoggedIn(): boolean {
    return customerAuthenticationService.getLocalAccount() !== null;
  }

  getAccount(): IAccount | null {
    return customerAuthenticationService.getLocalAccount();
  }

  async updateAccount(accountData: IAccount): Promise<any> {
    const token = customerAuthenticationService.getToken();
    if (token === '' || token === null) {
      throw Error('Cannot update without a token');
    }
    const url: string = `${this.apiBaseUrl}/account`;
    try {
      const account = await axios.put(url, accountData, await customerAuthenticationService.getAuthHeader());
      if (!account.data || account.data.error) {
        throw Error(`error updating: ${account.data.error.message}`);
      }
      await customerAuthenticationService.setLocalAccount(Role.CUSTOMER);
      return customerAuthenticationService.getLocalAccount();
    } catch (err: any) {
      throw Error(err);
    }
  }


  async requestResetPassword(emailAddress: string): Promise<any> {
    const url = `${this.apiBaseUrl}/request-reset-password`;
    const data = {
      email: emailAddress,
      ip: 0,
    }

    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      console.error({err});
      throw Error(err.message);
    }
  }

  async resetPassword(data: {authId: string, password: string, repeatPassword: string}) {
    const url = `${this.apiBaseUrl}/reset-password`;
    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async confirmEmail(data: {authId: string}) {
    const url = `${this.apiBaseUrl}/confirm-email`;
    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      throw Error(err.message);
    }
  }


}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const customerAccountService = new CustomerAccountService(apiBaseUrl);
export { customerAccountService }