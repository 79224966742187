import React from 'react';
import { StripeActivationCodeCheckout, StripeCheckout } from '_components';
import { cartService } from '_services';

const Checkout:React.FunctionComponent = () => {
  
  const cart = cartService.getCart();

  return(
    <>
    { cart && cart.activationCodes && cart.activationCodes.length > 0 ? <StripeActivationCodeCheckout /> : <StripeCheckout />}

    </>
  );
}

export { Checkout }