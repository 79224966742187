class TestKitService {

  isCodePaid (code: string) {
    return new Promise( (resolve, reject) => {
      setTimeout( () => {
        const data = {
          'status': code === 'TESTPAID' ? 'PAID' : 'NOTPAID'
        };
        resolve(data);
      }, 10);
    });
  }
}

const testKitService = new TestKitService();
export { testKitService }