enum ProductCodeStatus {
  CREATED                         = 1,
  MAILED                          = 2,
  PURCHASED                       = 3,
  ACTIVATED                       = 4,
  PREPARING_KIT                   = 5,
  SHIPPED                         = 6,
  RETURN_TRANSIT                  = 7,
  RECEIVED                        = 8,
  AWAITING_Q_VERIFICATION         = 9,
  COMPLETED                       = 10,
  ACTIVATING                      = 11,
}

const ProductCodeStatusName: Map<number, string> = new Map([
  [ProductCodeStatus.CREATED, 'Created'],
  [ProductCodeStatus.MAILED, 'Mailed'],
  [ProductCodeStatus.PURCHASED, 'Purchased - Ready for Activation'],
  [ProductCodeStatus.ACTIVATED, 'Activated - Waiting for kit to be returned'],
  [ProductCodeStatus.PREPARING_KIT, 'Preparing Kit'],
  [ProductCodeStatus.SHIPPED, 'Shipped'],
  [ProductCodeStatus.RETURN_TRANSIT, 'Return Transit'],
  [ProductCodeStatus.RECEIVED, 'Received'],
  [ProductCodeStatus.AWAITING_Q_VERIFICATION, 'Pending QA Verification'],
  [ProductCodeStatus.COMPLETED, 'Completed'],
  [ProductCodeStatus.ACTIVATING, 'Activating'],
]);

export {ProductCodeStatus, ProductCodeStatusName}