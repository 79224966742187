import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { DateOfBirth } from "_components/_account/_subforms/DateOfBirth";
import { UserContext } from "_common";
import { IAccount, IUserContext } from "_interfaces";
import { AddressInfo, Agreements, ContactInfo, PersonalInfo } from "_components/_account/_subforms";
import { validationMap, Validator } from "_components/_schemas";
import { customerAccountService } from "_services";

const ConfirmProfile: React.FunctionComponent<{ onConfirm: any }> = ({ onConfirm }) => {
  const userContext: IUserContext | null = useContext(UserContext);

  const initialValues = {
    firstName: userContext?.account?.firstName,
    lastName: userContext?.account?.lastName,
    dateOfBirth: userContext?.account?.dateOfBirth,
    address1: userContext?.account?.address1,
    address2: userContext?.account?.address2,
    city: userContext?.account?.city,
    state: userContext?.account?.state,
    zipcode: userContext?.account?.zipcode,
    phoneNumber: userContext?.account?.phoneNumber,
    agreeHipaa: false,
    agreeProductConsent: false,
  };

  const onSubmit = async (data: any, actions: any) => {
    // save the info
    const { setSubmitting, setStatus } = actions;

    const account: IAccount = {
      firstName: data.firstName,
      lastName: data.lastName,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      zipcode: data.zip,
      phoneNumber: data.phoneNumber,
      dateOfBirth: data.dateOfBirth,
    };

    try {
      setSubmitting(true);
      await customerAccountService.updateAccount(account);
      onConfirm();
    } catch (err: any) {
      setStatus(`${err}`);
    }

  };

  const validationSchema = validationMap.get(Validator.CONFIRM_PROFILE_FORM);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ values, isSubmitting, errors, touched, setFieldValue, status }) => {
        return (
          <Form>
            <div className="my-2 p-3 small-text-2 text-center alert alert-warning">
              <span className="fw-bold">PRIVACY NOTICE:</span> These questions are HIPAA-compliant. Your privacy is paramount. We
              will NEVER sell your information.
            </div>

            <div className="container-md my-3">
              <div className="row justify-content-center mt-3">
                <div className="col-md-6">
                  {/* Personal Information*/}
                  <PersonalInfo errors={errors} touched={touched} />
                </div>

                <div className="row justify-content-center mt-3">
                  <div className="col-md-6">
                    <div className="container">
                      <DateOfBirth
                        errors={errors}
                        touched={touched}
                        values={values}
                        fieldName="dateOfBirth"
                        setFieldValue={setFieldValue}
                        title={<div>Date of Birth</div>}
                      />
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center mt-3">
                  <div className="col-md-6">
                    {/* Address */}
                    <AddressInfo errors={errors} touched={touched} />
                  </div>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-md-6">
                    {/* Contact Information*/}
                    <ContactInfo errors={errors} touched={touched} />
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="container">
                      <Agreements errors={errors} touched={touched} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col text-center">
                    {status && <div className={"alert alert-danger"}>{status}</div>}
                    {isSubmitting && <div className="spinner-border"></div>}
                  </div>
                </div>

                <div className="row justify-content-center my-5">
                  <div className="col-auto">
                    <button disabled={isSubmitting} className="btn btn-primary btn-lg btn-block" type="submit">
                      {isSubmitting ? "Saving..." : "Confirm"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export { ConfirmProfile };
