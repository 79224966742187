import React, { useState } from "react";
import { ICustomerPayment } from "_interfaces";
import { adminLabelService, adminPaymentApiService } from "_services";
import { ListContainer } from "_common";
import { FormButtons, Url } from "_constants";

const OrderList: React.FunctionComponent = () => {
  
  const emptyValues: ICustomerPayment = {
    envelopeOutgoingTrackingId: '',
    envelopeReturnTrackingId: '',
    envelopeExpectedArrivalDate: '',
    trackingNumber: ''
  };

  const [ status, setStatus ] = useState<string>('');

  const onSelectedRows = async (ids: string[]) => {
    try {
      await adminLabelService.generateWebOrders(ids.map(i => Number(i)), 1)
      setStatus('Labels Generated');
    } catch (error: any) {
      setStatus(error);
    }
  }

  const downloadLink = (row: any): any => {
    if (row['labelFilePath']) {
      return <a href={`${Url.ADMIN_DOWNLOAD}?path=${row['labelFilePath']}`}>Download</a>;
    } else {
      return <></>
    }
  }

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['ID', undefined]],
    ['typeId', ['Type ID',undefined]],
    ['quantity', ['Quantity',undefined]],
    ['creationTimestamp', ['Creation Date/Time',undefined]],
    ['productId', ['Product ID',undefined]],
    ['productName', ['Product Name',undefined]],
    ['labelFilePath', ['Label Download', downloadLink]],
  ]);

  return (
    <>
    <ListContainer 
      apiService={adminPaymentApiService} 
      emptyValues={emptyValues} 
      onSelectedRows={onSelectedRows} 
      hasMultiSelect={true}
      multiSelectLabel="Generate Labels"
      buttons={[FormButtons.EDIT]}
      title="Payments and Orders"
      fieldList={fieldList}
    >
      {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
        return (
          <>Placeholder Form</>
        );
      }}
    </ListContainer>
    { status && <div className="alert">{status}</div>}
    </>
  );
};

export { OrderList };
