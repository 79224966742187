import React, { useContext, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { BillingForm, CouponForm, CreditCardForm, WebOrderConfirmation, WebOrderSummary } from "_components";
import { useStripe, useElements, CardNumberElement } from "@stripe/react-stripe-js";
import { ShippingForm } from "./ShippingForm";
import { StorageKey, Url } from "_constants";
import { customerAccountService, paymentService } from "_services";
import { IAccount, ICart, IOrderSummary, IProduct, IUserContext } from "_interfaces";
import { validationMap, Validator } from "_components/_schemas";
import { UserContext } from "_common";
import { config } from "_config";
import { AccountSetup } from "_components/_account/_subforms";

const SimpleCheckout = () => {
  const stripe = useStripe();
  const elements = useElements();

  const userContext: IUserContext | null = useContext(UserContext);
  const [isSameBillShip, setIsSameBillShip] = useState<boolean>(true);
  const [isSameProfileAddr, setIsSameProfileAddr] = useState<boolean>(true);
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponApplied, setCouponApplied] = useState<boolean>(false);
  const [orderSummary, setOrderSummary] = useState<IOrderSummary | undefined>(undefined);
  const [isAllowedState, setIsAllowedState] = useState<boolean>(true);
  

  const defaultSchema = userContext?.account
    ? validationMap.get(Validator.CHECKOUT_FORM_LOGGED_IN)
    : validationMap.get(Validator.CHECKOUT_FORM);
  const [validationSchema, setValidationSchema] = useState<any>(defaultSchema);

  const initialFormState: any = {
    billingFirstName: isSameBillShip ? userContext?.account?.firstName : "",
    billingLastName: isSameBillShip ? userContext?.account?.lastName : "",
    billingAddress1: isSameBillShip ? userContext?.account?.address1 : "",
    billingAddress2: isSameBillShip ? userContext?.account?.address2 : "",
    billingCity: isSameBillShip ? userContext?.account?.city : "",
    billingState: isSameBillShip ? userContext?.account?.state : "",
    billingZip: isSameBillShip ? userContext?.account?.zipcode : "",
    billingPhoneNUmber: isSameBillShip ? userContext?.account?.phoneNumber : "",
    creditCardCode: "",

    shippingFirstName: isSameProfileAddr ? userContext?.account?.firstName : "",
    shippingLastName: isSameProfileAddr ? userContext?.account?.lastName : "",
    shippingAddress1: isSameProfileAddr ? userContext?.account?.address1 : "",
    shippingAddress2: isSameProfileAddr ? userContext?.account?.address2 : "",
    shippingCity: isSameProfileAddr ? userContext?.account?.city : "",
    shippingState: isSameProfileAddr ? userContext?.account?.state : "",
    shippingZip: isSameProfileAddr ? userContext?.account?.zipcode : "",
    shippingPhoneNumber: isSameProfileAddr ? userContext?.account?.phoneNumber : "",

    quantity: 1,
    couponCode: "",

    email: "",
    password: "",
    confirmPassword: "",
    agreeTerms: false,
  };

  //const validationSchema = userContext?.account ? validationMap.get(Validator.CHECKOUT_FORM_LOGGED_IN) : validationMap.get(Validator.CHECKOUT_FORM);

  useEffect(() => {
    if (userContext?.account) {
      if (isSameBillShip) {
        setValidationSchema(validationMap.get(Validator.CHECKOUT_FORM_LOGGED_IN));
      } else {
        setValidationSchema(validationMap.get(Validator.CHECKOUT_FORM_WITH_BILL_LOGGED_IN));
      }
    } else {
      if (isSameBillShip) {
        setValidationSchema(validationMap.get(Validator.CHECKOUT_FORM));
      } else {
        setValidationSchema(validationMap.get(Validator.CHECKOUT_FORM_WITH_BILL));
      }
    }
  }, [isSameBillShip, userContext, isAllowedState]);

  const submitOrder = (data: any, actions: any) => {
    const { setStatus, setSubmitting } = actions;
    const directAccessStates = ["DE", "IN", "IA", "KS", "MN", "MS", "MO", "NM", "NC", "OH", "OK", "UT", "WI", "TX", "VA"]; // states that allow Direct Access (i.e. no prescription required)

    const isAllowedState = directAccessStates.indexOf(data.shippingState) >= 0;

    if (!isAllowedState) {
      setIsAllowedState(false);
      setSubmitting(false);
      return;
    }

    //@TODO: make this strict
    const billingDetails: any = {
      name: `${data.billingFirstName} ${data.billingLastName}`,
      address: {
        city: data.billingCity,
        line1: data.billingAddress1,
        state: data.billingState,
        postal_code: data.billingZip,
      },
    };

    setStatus("");

    paymentService.getPaymentIntent().then((paymentIntent) => {
      const cardNumberElement = elements ? elements.getElement(CardNumberElement) : null;
      const stripeClientSecret = paymentIntent.data.stripeClientSecret;

      if (cardNumberElement) {
        stripe
          ?.confirmCardSetup(stripeClientSecret, {
            payment_method: {
              card: cardNumberElement,
              billing_details: billingDetails,
            },
          })
          .then((result) => {
            if (result.error) {
              setStatus(`${result.error.message}`);
              setSubmitting(false);
            } else {
              const cart: ICart = {
                productId: config.productId,
                quantity: Number(data.quantity),
                shipTo: {
                  firstName: data.shippingFirstName,
                  lastName: data.shippingLastName,
                  address1: data.shippingAddress1,
                  address2: data.shippingAddress2,
                  city: data.shippingCity,
                  state: data.shippingState,
                  zipcode: data.shippingZip,
                  country: "USA",
                },
                couponCode: data.couponCode || '',
              };

              stripe.retrieveSetupIntent(stripeClientSecret).then((setupIntentResult) => {
                paymentService
                  .pay({ setupIntentResult, cart })
                  .then((result) => {
                    // success. go to Order Complete page
                    setSubmitting(false);
                    localStorage.removeItem(StorageKey.KIT_QUANTITY);
                    setOrderSummary(result.data);
                    //history.push(Url.CUSTOMER_THANK_YOU);
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    console.error("error calling pay endpoint", err);
                    setStatus(`${err}`);
                  });
              });
            }
          })
          .catch((error) => {
            console.error("error", error);
            setStatus("unexpected error in stripe");
          });
      }
    });
  };

  const onSubmit = (data: any, actions: any) => {
    const { setStatus, setSubmitting } = actions;

    // if already logged in, for example, when prior attempt logged in the registered user, but CC failed
    if (userContext?.account) {
      // just submit the order without registering
      submitOrder(data, actions);
    } else {
      customerAccountService
        .register({
          email: data.email,
          password: data.password,
          firstName: data.shippingFirstName,
          lastName: data.shippingLastName,
          address1: data.shippingAddress1,
          address2: data.shippingAddress2,
          city: data.shippingCity,
          state: data.shippingState,
          zipcode: data.shippingZip,
          phoneNumber: data.shippingPhoneNumber,
        })
        .then((account: IAccount) => {
          userContext?.setAccount(account);
          submitOrder(data, actions);
        })
        .catch((err: any) => {
          setSubmitting(false);
          setStatus(err.message);
        });
    }
  };

  const onClickSameProfileAddr = (values: any, e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSameProfileAddr(!isSameProfileAddr);
    if (isSameProfileAddr === false) {
      values["shippingFirstName"] = userContext?.account?.firstName;
      values["shippingLastName"] = userContext?.account?.lastName;
      values["shippingAddress1"] = userContext?.account?.address1;
      values["shippingAddress2"] = userContext?.account?.address2;
      values["shippingCity"] = userContext?.account?.city;
      values["shippingState"] = userContext?.account?.state;
      values["shippingZip"] = userContext?.account?.zipcode;
    } else {
      values["shippingFirstName"] = "";
      values["shippingLastName"] = "";
      values["shippingAddress1"] = "";
      values["shippingAddress2"] = "";
      values["shippingCity"] = "";
      values["shippingState"] = "";
      values["shippingZip"] = "";
    }
  };

  const onClickSameBillShip = (values: any, e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSameBillShip(!isSameBillShip);
    if (isSameBillShip === false) {
      values["billingFirstName"] = values["shippingFirstName"];
      values["billingLastName"] = values["shippingLastName"];
      values["billingAddress1"] = values["shippingAddress1"];
      values["billingAddress2"] = values["shippingAddress2"];
      values["billingCity"] = values["shippingCity"];
      values["billingState"] = values["shippingState"];
      values["billingZip"] = values["shippingZip"];
      values["billingPhoneNumber"] = values["shippingPhoneNumber"];
    } else {
      values["billingFirstName"] = "";
      values["billingLastName"] = "";
      values["billingAddress1"] = "";
      values["billingAddress2"] = "";
      values["billingCity"] = "";
      values["billingState"] = "";
      values["billingZip"] = "";
      values["billingPhoneNumber"] = "";
    }

    e.stopPropagation(); // without this, checkbox will not toggle!
  };

  return (
    <>
    {orderSummary ? (
      <div className="container"><WebOrderConfirmation orderSummary={orderSummary}/></div>
    ) : (
      <Formik initialValues={initialFormState} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, isSubmitting, errors, touched, setStatus, status, setFieldValue }) => {
        return (
          <Form>
            <div className="container my-5">
              <h3 className="mb-3 text-primary">Order Form</h3>
              <div className="row">
                <div className="col-auto col-md-4 mx-auto mb-3">
                  <WebOrderSummary
                    product={{ description: "FIT Test" } as IProduct}
                    quantity={values["quantity"]}
                    couponCode={couponCode}
                    setCouponApplied={setCouponApplied}
                    setStatus={setStatus}
                  />
                  {!couponApplied && (
                    <CouponForm
                      errors={errors}
                      touched={touched}
                      values={values}
                      setCouponCode={setCouponCode}
                      setFieldValue={setFieldValue}
                      name="coupon"
                    />
                  )}
                </div>
                <div className="col-auto col-md-8">
                  {!userContext?.account && (
                    <div className="container mb-3">
                      <AccountSetup
                        errors={errors}
                        touched={touched}
                        loginUrl={`${Url.CUSTOMER_LOGIN}?redir=order`}
                        formatAsCard={true}
                      />
                    </div>
                  )}

                  <ShippingForm errors={errors} touched={touched} />

                  <div className="d-flex mx-3">
                    {userContext?.account && (
                      <div className="form-group mx-2">
                        <input
                          type="checkbox"
                          id="sameShipProfile"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onClickSameProfileAddr(values, e)}
                          value={1}
                          name="sameShipProfile"
                          className="mx-1"
                          defaultChecked={isSameProfileAddr}
                        />
                        <label htmlFor="sameShipProfile">Shipping Address is same as Account Address</label>
                      </div>
                    )}

                    <div className="form-group mx-2">
                      <input
                        type="checkbox"
                        id="sameBillShip"
                        defaultChecked={isSameBillShip}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onClickSameBillShip(values, e)}
                        value={1}
                        name="sameBillShip"
                        className="mx-1"
                      />
                      <label htmlFor="sameBillShip">Billing Address is same as Shipping Address</label>
                    </div>
                  </div>

                  {!isSameBillShip && (
                    <div className="mt-3">
                      <BillingForm errors={errors} touched={touched} />
                    </div>
                  )}

                  {isAllowedState && (
                    <div className="container my-3">
                      <CreditCardForm />
                    </div>
                  )}

                  {!isAllowedState && (
                    <div className="container my-3 alert alert-warning">
                      <p>
                      We apologize but the FIT Test is currently not available in your state. Please check back again in a few
                      weeks.
                      </p>
                      <p>
                      Account registration and order has been canceled. To protect your privacy, the data you entered in the form has been discarded.
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col text-center">
                  <div className="container">{status && <div className={"alert alert-danger text-center"}>{status}</div>}</div>
                  {isSubmitting && <div className="spinner-border"></div>}
                </div>
              </div>

              {isAllowedState && (
                <div className="row justify-content-center my-3">
                  <div className="col-auto">
                    <button disabled={isSubmitting} className="btn btn-primary btn-lg btn-block" type="submit">
                      {isSubmitting ? "Processing..." : "Place Order"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>      
    )}
    </>
  );
};

export { SimpleCheckout };
