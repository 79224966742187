import React, { useState, useEffect } from "react";
import { paymentService } from "_services";
import { IPaymentDetail } from "_interfaces";

const PreMailOrderSummary: React.FunctionComponent<{activationCodes: string[], couponCode: string, setCouponApplied: any, setStatus: any}> = ({activationCodes, couponCode, setCouponApplied, setStatus}) => {

  const [paymentDetail, setPaymentDetail] = useState<IPaymentDetail>({} as IPaymentDetail);
  const [couponValid, setCouponValid] = useState<boolean>(false);

  useEffect(()=> {
    const _init = async () => {
      try {
        const paymentDetail: IPaymentDetail =  await paymentService.getPayDetails(1, activationCodes.length, couponCode);
        setStatus('');
        if (couponCode) {
          setCouponApplied(true);
          setCouponValid(true);
        }
        setPaymentDetail(paymentDetail);
      } catch (error: any) {
        setCouponApplied(false);
        setStatus(`Error: ${error}`);
      }
    }

    _init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationCodes, couponCode])

  return(
    <>
      <table className="table">
        <thead>
          <th>Item Description</th>
          <th className="text-end">Activation Code</th>
        </thead>
        <tbody>
          {activationCodes.length > 0 ? (
            activationCodes.map((code: any, i: number) => (
              <tr key={`code-${i}`}>
                <td>FIT Kit Processing Fee</td>
                <td className="text-end">{code}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2}>Empty Cart</td>
            </tr>
          )}
        </tbody>
      </table>

      <table className="table">
        <thead>
          <th colSpan={2}>Order Summary</th>
        </thead>
        <tbody>
          <tr>
            <td>Subtotal</td>
            <td className="text-end">${paymentDetail.price}</td>
          </tr>
          {couponCode && couponValid && <tr><td>Coupon: {couponCode}</td><td className="text-end">${paymentDetail.discountCoupon.toFixed(2)}</td></tr>}
          <tr className="fw-bold">
            <td>Grand Total</td>
            <td className="text-end">${paymentDetail.totalAmount}</td>
          </tr>
        </tbody>
      </table>
    </>    
  );
}

export { PreMailOrderSummary }