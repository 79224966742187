import React, { useContext } from "react";
import { customerAuthenticationService } from "_services";
import { ICredentials } from "_interfaces";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { LoginForm, UserContext } from "_common";
import { IUserContext } from "_interfaces";
import { Role, Url } from "_constants";

const Login: React.FunctionComponent = (props: any) => {
  const history = useHistory();
  const userContext: IUserContext | null = useContext(UserContext);
  const initialFormState: ICredentials = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Please enter your email address"),
    password: Yup.string().required("Password required"),
  });

  const onSubmit = async (data: any, actions: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    const redir = queryParams.get("redir");

    const { email, password } = data;
    const { setStatus } = actions;
    try {
      await customerAuthenticationService.login({ email, password });
      const account: any = await customerAuthenticationService.getLocalAccount();
      if (!account) {
        throw Error("unexpected error");
      }
      userContext?.setAccount(account);

      // see if there's a redirect first and redirect there
      if (redir) {
        history.push(redir);
      } else {
        // if no redir, go to the Role's default page
        switch (account.roleId) {
          case Role.ADMIN:
            history.push(Url.ADMIN_HOME_PAGE);
            break;
          case Role.CUSTOMER:
            history.push(Url.CUSTOMER_DASHBOARD);
            break;
          case Role.TECH:
            history.push("/tech");
            break;
          default:
            history.push(Url.DEFAULT_HOME);
        }
      }
    } catch (error) {
      setStatus("Invalid Login");
    }
  };

  const onClickRegister = () => {
    history.push(Url.CUSTOMER_REGISTRATION);
  };

  return (
    <>
    <LoginForm onClickRegister={onClickRegister} initialFormState={initialFormState} validationSchema={validationSchema} onSubmit={onSubmit}/>
    </>
  );
};

export { Login };
