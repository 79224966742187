import React, { useEffect, useState } from 'react';
import { adminStorageService } from '_services';

const ResultImage: React.FunctionComponent = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(()=> {
    const _init = async () => {
      const path = queryParams.get('path');
      if (path) {
        const url =  await adminStorageService.downloadFile(path);
        setImageUrl(url);
      }
    }
    _init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <img width="100%" height="100%" src={imageUrl} alt='Result' />
  );
}

export { ResultImage }