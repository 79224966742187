/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { ConfirmCheck, FieldWithError } from "_common";
import { ModalAgreement, TermsAndConditions } from "_components";

const AccountSetup: React.FunctionComponent<{
  errors: any;
  touched: any;
  readOnly?: boolean;
  showPasswordToggle?: boolean;
  emailDisabled?: boolean;
  loginUrl?: string;
  formatAsCard?: boolean;
}> = ({
  errors,
  touched,
  readOnly = false,
  showPasswordToggle = false,
  emailDisabled = false,
  loginUrl,
  formatAsCard = false,
}) => {
  const [showPasswordFields, setShowPasswordFields] = useState<boolean>(false);
  const [showToC, setShowToC] = useState<boolean>(false);

  const onClickToC = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowToC(true);
  };

  return (
    <>
      {formatAsCard ? (
        <div className="card">
          <h4 className="card-header">Account Credentials</h4>
          <div className="card-body">
            <div className="container">
              <div className="row mb-2">
                <div className="col">
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="email"
                    placeholder="E-Mail Address"
                    label="Email Address"
                    readOnly={readOnly || emailDisabled}
                  />
                </div>
              </div>

              {showPasswordToggle && !showPasswordFields && (
                <div className="row mb-2">
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => {
                        setShowPasswordFields(true);
                      }}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              )}

              {(!showPasswordToggle || (showPasswordToggle && showPasswordFields)) && (
                <>
                  <div className="row mb-2">
                    <div className="col">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="password"
                        placeholder="Enter Password"
                        type="password"
                        label="Password"
                        readOnly={readOnly}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="confirmPassword"
                        placeholder="Confirm Password"
                        type="password"
                        label="Confirm Password"
                        readOnly={readOnly}
                      />
                    </div>
                  </div>

                  {!emailDisabled && (
                    <div className="row my-3">
                      <div className="col">
                        <ModalAgreement show={showToC} handleClose={() => setShowToC(false)} title="Terms and Conditions">
                          <TermsAndConditions />
                        </ModalAgreement>
                        <ConfirmCheck name="agreeTerms" errors={errors} touched={touched} readOnly={readOnly}>
                          <div>
                            I have read and accept the{" "}
                            <a onClick={onClickToC} className="Terms and Conditions">
                              Terms and Conditions
                            </a>
                          </div>
                        </ConfirmCheck>
                      </div>
                    </div>
                  )}

                  {loginUrl && (
                    <div className="row">
                      <div className="col text-center my-3">
                        Already have an account? <a href={loginUrl}>Log in</a>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="container">
          <div className="medium-text-1 text-primary">Account Credentials</div>
            <div className="row mb-2">
              <div className="col">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName="email"
                  placeholder="E-Mail Address"
                  label="Email Address"
                  readOnly={readOnly || emailDisabled}
                />
              </div>
            </div>

            {showPasswordToggle && !showPasswordFields && (
              <div className="row mb-2">
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      setShowPasswordFields(true);
                    }}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            )}

            {(!showPasswordToggle || (showPasswordToggle && showPasswordFields)) && (
              <>
                <div className="row mb-2">
                  <div className="col">
                    <FieldWithError
                      errors={errors}
                      touched={touched}
                      fieldName="password"
                      placeholder="Enter Password"
                      type="password"
                      label="Password"
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <FieldWithError
                      errors={errors}
                      touched={touched}
                      fieldName="confirmPassword"
                      placeholder="Confirm Password"
                      type="password"
                      label="Confirm Password"
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                {!emailDisabled && (
                  <div className="row my-3">
                    <div className="col">
                      <ModalAgreement show={showToC} handleClose={() => setShowToC(false)} title="Terms and Conditions">
                        <TermsAndConditions />
                      </ModalAgreement>
                      <ConfirmCheck name="agreeTerms" errors={errors} touched={touched} readOnly={readOnly}>
                        <div>
                          I have read and accept the{" "}
                          <a onClick={onClickToC} className="Terms and Conditions">
                            Terms and Conditions
                          </a>
                        </div>
                      </ConfirmCheck>
                    </div>
                  </div>
                )}

                {loginUrl && (
                  <div className="row">
                    <div className="col text-center my-3">
                      Already have an account? <a href={loginUrl}>Log in</a>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export { AccountSetup };
