import React from 'react';

import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';


const CreditCardForm = () => {
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '1.1em',
      }
    }
  }
  return (
    <div className="card my-2">
      <h4 className="card-header">Payment Info</h4>
      <div className="card-body">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-1">
              <div className="form-group">
                <label>Credit Card Number</label>
                <CardNumberElement options={cardElementOptions} />
              </div></div>
            <div className="col-md-3 mt-1">
              <div className="form-group">
                <label>Exp Date</label>
                <CardExpiryElement options={cardElementOptions} />
              </div></div>
            <div className="col-md-3 mt-1">
              <div className="form-group">
                <label>CVC Code</label>
                <CardCvcElement options={cardElementOptions} />
              </div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { CreditCardForm }