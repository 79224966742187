export enum StorageKey {
  ADMIN_ACCT = 'aacct',
  ADMIN_TOKEN = 'atoken',
  CUSTOMER_ACCT = 'cacct',
  CUSTOMER_TOKEN = 'ctoken',
  KIT_QUANTITY = 'cquan',
  ACTIVATION_CODE = 'acd',
  COMPANION_ACTIVATION_CODE ='cacd',
  CART = 'crt',
  PREVIOUS_ANSWERS = 'pans'
}