import { Form, Formik } from "formik";
import React from "react";
import { QuestionnaireForm } from "_components";
import { QuestionEnum } from "_constants";
import * as Yup from 'yup';


const ReviewAnswers: React.FunctionComponent<{ initialValues: any, validationSchemaMap: Map<string, object>, onSubmit: any }> = ({ initialValues, validationSchemaMap, onSubmit }) => {

  const initialFormState = {
    gender: initialValues ? initialValues[`q${QuestionEnum.GENDER}`] || "" : "",
    historyCheck: initialValues
      ? initialValues[`q${QuestionEnum.DISEASE_HISTORY}`]?.map((i: number) => i.toString()) || []
      : [],
    familyWithColonCancer: initialValues
      ? initialValues[`q${QuestionEnum.FAMILY_WITH_COLON_RECTAL_CANCER}`]?.map((i: number) => i.toString()) || []
      : [],
    hasBloodInPriorBM: initialValues
      ? initialValues[`q${QuestionEnum.RECENT_BRIGHT_RED_BLOOD}`]?.toString() || undefined
      : undefined,
    hasMenstrualPeriod: initialValues
      ? initialValues[`q${QuestionEnum.FEMALE_MENSTRUAL_PERIOD_3D_STOOL_SAMPLE}`]?.toString() || undefined
      : undefined,
    lastColonoscopyYear: initialValues ? initialValues[`q${QuestionEnum.RECENT_COLONOSCOPY_YEAR}`] || false : false,
    colonoscopyResult: initialValues
      ? initialValues[`q${QuestionEnum.RECENT_COLONOSCOPY_RESULT}`]?.toString() || undefined
      : undefined,
    medicalConditionOptions: initialValues
      ? initialValues[`q${QuestionEnum.MEDICAL_CONDITIONS}`]?.map((i: number) => i.toString()) || []
      : [],
    hasPCP: initialValues ? initialValues[`q${QuestionEnum.PRIMARY_CARE_PHYSICIAN}`] || undefined : undefined,
    ethnicity: initialValues ? initialValues[`q${QuestionEnum.ETHNICITY}`]?.toString() || undefined : undefined,
    race: initialValues ? initialValues[`q${QuestionEnum.RACE}`]?.map((i: number) => i.toString()) || [] : [],
  };
  initialFormState.lastColonoscopyYear = initialFormState.lastColonoscopyYear === false ? "0" : initialFormState.lastColonoscopyYear;

  /* @TODO: figure out how to get the shapes from validationSchemaMap to avoid copy/paste
  const shapes = Array.from(validationSchemaMap.entries()).map( (item: [string, object]) => {
    const [key, objValue] = item;
    return objValue
  });
  const validationSchema = shapes;
  console.log(shapes);
  */

  const validationSchema = Yup.object().shape({
    gender: Yup.string().required("Please select your gender"),
    historyCheck: Yup.array().min(1, "Please select an answer"),
    familyWithColonCancer: Yup.array().min(1, "Please select an answer"),
    hasBloodInPriorBM: Yup.string().required("Please select an answer"),
    hasMenstrualPeriod: Yup.string().required("Please select an answer"),
    lastColonoscopyYear: Yup.string().required("Please select an answer"),
    colonoscopyResult: Yup.string().required("Please select an answer"),
    medicalConditionOptions: Yup.array().min(1, "Please select an answer"),
    hasPCP: Yup.string().required("Please specify if you have a PCP"),
    race: Yup.array().min(1, "Please select at least one option"),
    ethnicity: Yup.string().required("Please select at least one option"),
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialFormState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, errors, touched, setFieldValue, status }) => {
          return (
            <div className="container-md">
              <div className="row">
                <div className="col">
                  <h4 className="text-center mt-3">Please review your answers below and confirm at the bottom of the page:</h4>

                  <div className="border alert alert-warning my-5 p-3 small-text-2 text-center">
                    <span className="fw-bold">PRIVACY NOTICE:</span> These questions are HIPAA-compliant. Your privacy is paramount. We will never sell your information.
                  </div>
                  <Form>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col col-md-8">
                          <QuestionnaireForm
                            values={values}
                            errors={errors}
                            touched={touched}
                            stepIndex={0}
                            showAll={true}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>

                      <div className="row mt-5 d-flex justify-content-center">
                        <div className="col col-md-6">
                          <div className="d-flex justify-content-evenly my-3">
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {status && <div className="alert alert-warning">{status}</div>}
                  </Form>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export { ReviewAnswers };
