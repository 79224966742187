
import { IAccount, ICredentials } from "_interfaces";
import axios from "axios";
import { config } from '_config';
import { Role, StorageKey } from "_constants";

class AuthenticationService {

  constructor (
    protected apiBaseUrl: string,
    protected storageTokenKey: string,
    protected storageAccountKey: string,
  ) {}

  async login (credentials: ICredentials): Promise<IAccount | undefined> {

    const url: string = `${this.apiBaseUrl}/login`;
    try {
      const response: any = await axios.post(url, credentials);
      if (!response.data || response.data.error) {
        throw Error(`${response.data.error.message}`)
      }
      if (!response.data.roleId) {
        // logged in with no role means it's not an Admin/Tech (i.e. it's a customer)
        response.data.roleId = Role.CUSTOMER;
      }
      localStorage.setItem(this.storageTokenKey, response.data.token);
      await this.setLocalAccount(response.data.roleId);
      return response.data;
      
    } catch (error: any) {
      throw Error(error.message);
    }

  }

  getLocalAccount(): IAccount | null {
    const data = localStorage.getItem(this.storageAccountKey);
    if (data) {
      const obj = JSON.parse(data);
      return obj.account;
    } else {
      return null;
    }
  }

  async setLocalAccount(roleId: number) {
    // get the account and save it
    try {
      const url: string = `${this.apiBaseUrl}/account`;
      const response = await axios.get(url, await this.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      if (response.data.account) {
        response.data.account.roleId = roleId;
      } else {
        throw Error('Unexpected API response. no account found');
      }
      localStorage.setItem(this.storageAccountKey, JSON.stringify(response.data));
    } catch (err) {
      console.error(err);
      throw Error(`error setting account: ${err}`);
    }
}

  logout = async () => {
    localStorage.removeItem(this.storageTokenKey);
    localStorage.removeItem(this.storageAccountKey);
  }

  getToken(): string {
    const token = localStorage.getItem(this.storageTokenKey) || '';
    return token;
  }

  async getAuthHeader(redir?: string): Promise<Object> {

    // check if token is expired
    if (await this.isTokenExpired()) {
      let redirPath = '/login';
      if (redir) {
        redirPath = `${redirPath}?redir={redir}`
      }
      window.location.href = redirPath;
    }
    const header = {headers: {
      Authorization: 'Bearer ' + this.getToken(),
      token: this.getToken()
    }};

    return header;
  }

  async isTokenExpired(): Promise<boolean> {
    const url: string = `${this.apiBaseUrl}/account`;
    let result: boolean = false;
    const header = {headers: {
      Authorization: 'Bearer ' + this.getToken(),
      token: this.getToken()
    }};
     try {
      const response = await axios.get(url, header);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      if (response.data.account) {
        result = false;
      }
    } catch (error: any) {
      if (error.message === 'Failed to authenticate token.') {
        result = true;
      } else {
        console.error(error);
        throw Error(error);
      }
    } finally {
      return result;
    }
    

  }

}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/user`;
const adminApiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/admin`;
const adminAuthenticationService = new AuthenticationService(adminApiBaseUrl, StorageKey.ADMIN_TOKEN, StorageKey.ADMIN_ACCT);
const customerAuthenticationService = new AuthenticationService(apiBaseUrl, StorageKey.CUSTOMER_TOKEN, StorageKey.CUSTOMER_ACCT);
export { AuthenticationService, adminAuthenticationService, customerAuthenticationService }
