import React, { useState, useEffect } from "react";
import { Field } from "formik";
import { paymentService } from "_services";
import { IProduct, IPaymentDetail } from "_interfaces";

const WebOrderSummary: React.FunctionComponent<{
  product: IProduct;
  quantity: any;
  couponCode: string;
  setCouponApplied: any;
  setStatus: any;
}> = ({ product, quantity, couponCode, setCouponApplied, setStatus }) => {
  const [paymentDetail, setPaymentDetail] = useState<IPaymentDetail>({} as IPaymentDetail);
  const [couponValid, setCouponValid] = useState<boolean>(false);

  useEffect(() => {
    const _init = async () => {
      try {
        const paymentDetail: IPaymentDetail = await paymentService.getPayDetails(1, quantity, couponCode);
        setStatus("");
        if (couponCode) {
          setCouponApplied(true);
          setCouponValid(true);
        }
        setPaymentDetail(paymentDetail);
      } catch (error: any) {
        setCouponApplied(false);
        setStatus(`Error: ${error}`);
      }
    };

    _init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, couponCode]);

  return (
    <><div className="small-text-3 alert alert-primary text-center"><span className="fw-bold">Save 25% on each additional kit.</span> <br/> Select the quantity below.</div>
      <table className="table">
        <thead>
          <tr>
            <th>Item Description</th>
            <th className="text-end">Quantity</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{product.description}</td>
            <td className="text-end">
              <Field as="select" name="quantity">
                {[...new Array(5)].map((_, i: number) => {
                  return (
                    <option key={`key-${i + 1}`} value={i + 1}>
                      {i + 1}
                    </option>
                  );
                })}
              </Field>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="table">
        <thead>
          <tr>
            <th colSpan={2}>Order Summary</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Subtotal</td>
            <td className="text-end">${paymentDetail.price}</td>
          </tr>
          <tr>
            <td>Shipping</td>
            <td className="text-end">FREE</td>
          </tr>
          {couponCode && couponValid ? (
            <tr>
              <td>Coupon:{couponCode}</td>
              <td className="text-end">${paymentDetail.discountCoupon.toFixed(2)}</td>
            </tr>
          ) : (<></>)}
          <tr className="fw-bold">
            <td>Grand Total</td>
            <td className="text-end">${paymentDetail.totalAmount}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export { WebOrderSummary };
