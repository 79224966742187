import React, { useContext } from "react";
import { UserContext } from "_common";
import { IUserContext } from "_interfaces";

const Dashboard: React.FunctionComponent = () => {
  const userContext: IUserContext | null = useContext(UserContext);
  return (
    <>
    
    <div>Admin Dashboard</div>

    You are logged in as {userContext?.account?.email}
    </>
  );
}

export { Dashboard as AdminDashboard}