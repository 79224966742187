enum ProductCodeResultEnum {
  POSITIVE                          = 1,
  NEGATIVE                          = 2,
  INVALID                           = 3,
}

const ProductCodeResultNames = new Map<number, string>([
  [ProductCodeResultEnum.POSITIVE, 'Positive'],
  [ProductCodeResultEnum.NEGATIVE, 'Negative'],
  [ProductCodeResultEnum.INVALID, 'Invalid'],
])

export { ProductCodeResultEnum, ProductCodeResultNames}
