import React from 'react';

const EmptyLayout:React.FunctionComponent = ({ children }) => {
  return(
    <>
    {children}
    </>
  );
}

export { EmptyLayout }