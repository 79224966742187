import axios from "axios";
import { config } from '_config';
import { ProductCodeStatus } from "_constants";
import { IAdminCodeHash } from "_interfaces";
import { adminActivationCodeApiService, adminAuthenticationService as authService } from "_services";


class AdminCodeService {

  constructor (protected apiBaseUrl: string) {}

  getCodeFromHash(codeHash: string): Promise<IAdminCodeHash> {
    const url: string = `${this.apiBaseUrl}/product-code-hash/${codeHash}`;
    return this.get<IAdminCodeHash>(url);
  }

  getEnvelopeDetail(codeHash: string): Promise<any> {
    const payload = {
      codeHash
    }
    const url: string = `${this.apiBaseUrl}/envelope/details`;
    return this.post<any>(url, payload);
  }

  uploadCollectionImage (file: any):Promise<any> { //@TODO: find the correct data type for file upload
    const url = `${this.apiBaseUrl}/storage/upload-file-tmp`;
    return this.postFile<any>(url, {file: file});
  }

  async updateResult(data: any): Promise<any> {
    // upload image first
    try {
      const response: any = await this.uploadCollectionImage(data.file);
      const putData = {
        collectionDate: data.collectionDate,
        resultId: Number(data.result),
        resultFilePath: response.filePath,
        statusId: ProductCodeStatus.AWAITING_Q_VERIFICATION,
      };
      return await adminActivationCodeApiService.update(putData, data.id);
    } catch (error: any) {
      console.error({error});
      alert('error! check console');
    }
  }

  // utilities: @TODO: move to helper
  async get<T>(url:string): Promise<T> {
    try {
      const response: any = await axios.get<any>(url, await authService.getAuthHeader());
      if (response?.error) {
        throw Error(response.error.message);
      }
      return response.data;
    } catch (error: any) {
      throw Error(error);
    }    
  }

  async postFile<T>(url:string, item: any): Promise<T> {
    const formData = new FormData();
    formData.append('file', item.file);

    try {
      const authHeader:any = await authService.getAuthHeader();
      authHeader.headers['Content-Type'] = 'multipart/form-data';
      const response: any = await axios.post<any>(url, formData, authHeader);
      if (response.error) {
        throw Error(response.error.message);
      }
      return response.data;
    } catch (error: any) {
      console.error('error in post', {error});
      throw Error(error);
    }    
  }

  async post<T>(url:string, item: object): Promise<T> {
    try {
      const response: any = await axios.post<any>(url, item, await authService.getAuthHeader());
      if (response.error) {
        throw Error(response.error.message);
      }
      return response.data as T;
    } catch (error: any) {
      console.error('error in post', {error});
      throw Error(error);
    }    
  }
}


const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/admin`;
const adminCodeService = new AdminCodeService(apiBaseUrl);

export { adminCodeService }