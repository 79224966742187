import React from 'react';
import { Field, ErrorMessage } from "formik";
import { YesNoField } from "_common";
import { IKeyValue } from "_interfaces";


const gender: IKeyValue<string>[] = [
  { key: 'M', value: 'Male'},
  { key: 'F', value: 'Female'},
]

const healthHistory: IKeyValue<number>[] = [
  { key: 1, value: "Colon Cancer" },
  { key: 2, value: "Diverticulitis" },
  { key: 3, value: "Ulcerative Colitis" },
  { key: 4, value: "Chron's Disease" },
  { key: 5, value: "None of The Above" },
];

const familyWithColonCancer: IKeyValue<number>[] = [
  { key: 1, value: "Parent" },
  { key: 2, value: "Brother or Sister" },
  { key: 3, value: "Child" },
  { key: 4, value: "None of The Above" },
];

const colonoscopyResultOptions: IKeyValue<number>[] = [
  { key: 1, value: "Normal" },
  { key: 2, value: "Noncancerous polyp" },
  { key: 3, value: "Pre-cancerous polyp" },
  { key: 4, value: "Cancer" },
  { key: 5, value: "Don't Know" },
];

const medicalConditionOptions: IKeyValue<number>[] = [
  { key: 1, value: "High Blood Pressure" },
  { key: 2, value: "Diabetes" },
  { key: 3, value: "Cancer" },
  { key: 4, value: "High Cholesterol" },
  { key: 5, value: "Obesity" },
  { key: 6, value: "None of The Above" },
];

const raceOptions: IKeyValue<number>[] = [
  { key: 1, value: "American Indian or Alaska Native" },
  { key: 2, value: "Asian" },
  { key: 3, value: "Black or African American" },
  { key: 4, value: "Native Hawaiian or other Pacific Islander" },
  { key: 5, value: "White" },
  { key: 6, value: "Other" },
  { key: 7, value: "Prefer Not To Answer" },
];

const ethnicityOptions: IKeyValue<number>[] = [
  { key: 1, value: "Hispanic or Latino" },
  { key: 2, value: "Not Hispanic or Latino" },
  { key: 3, value: "Prefer Not To Answer" },
];

const menstrualPeriodOptions: IKeyValue<number>[] = [
  { key: 0, value: "No" },
  { key: 1, value: "Yes" },
  { key: 2, value: "N/A" },
];

const bloodInPriorOptions: IKeyValue<number>[] = [
  { key: 0, value: "No" },
  { key: 1, value: "Yes" },
  { key: 2, value: "I Don't Know" },
];


const QuestionnaireForm: React.FunctionComponent<{ values: any; errors: any; touched: any; stepIndex: number, setFieldValue: any, showAll: boolean }> = ({
  values,
  errors,
  touched,
  stepIndex,
  setFieldValue,
  showAll
}) => {

  const LastColonoscopyYear: React.FunctionComponent<{errors: any, touched: any, name: string}> = ({errors, touched, name}) => {
    const q = [...new Array(30)].map((_, i) => new Date().getFullYear() - i);
    return (
      <>
        <div>Year of your most recent colonoscopy:</div>
        <Field
          name="lastColonoscopyYear"
          as="select"
          className={
            "form-control " +
            (errors && errors["lastColonoscopyYear"] && touched && touched["lastColonoscopyYear"] ? " is-invalid" : "")
          }
        >
          <option key='lastyear-0' value={0}>Never Had One</option>
          <option key='lastyear-1' value={1}>I Don't Remember</option>
          {q.map((i: number) => (
            <option key={`lastyear-${i}`} value={i}>{i}</option>
          ))}
        </Field>
        {errors && <ErrorMessage className="mt-2 invalid-feedback" name="lastColonoscopyYear" component="div" />}
      </>
    );
  };

  const steps: React.ReactNode[] = [

    // gender
    <ListOptions
      title="Gender Assigned At Birth"
      name="gender"
      list={gender}
      errors={errors}
      touched={touched}
      type="radio"
      />
    ,

    // ethnicity
    <ListOptions
      title="What race do you identify as (check all that apply):"
      name="race"
      list={raceOptions}
      errors={errors}
      touched={touched}
      />
    ,

    // race
    <ListOptions
    title="What Ethnicity do you identify as?(check one):"
    name="ethnicity"
    list={ethnicityOptions}
    errors={errors}
    touched={touched}
    type="radio"
    />
  ,

    // cancer history
    <ListOptions
      title="Do you have a history of any of the following (check all that apply):"
      name="historyCheck"
      list={healthHistory}
      errors={errors}
      touched={touched}
      />
    ,

    // family history
    <ListOptions
      title="Do you have any family members with Colon or rectal cancer? (check all that apply):"
      name="familyWithColonCancer"
      list={familyWithColonCancer}
      errors={errors}
      touched={touched}
      />
    ,

    // blood in prior BM
    <div className="questionnaire-title">
      <ListOptions
            title="Any bright red blood in the last few days when wiping after bowel movements?"
            name="hasBloodInPriorBM"
            type="radio"
            list={bloodInPriorOptions}
            errors={errors}
            touched={touched}
      />     
    </div>
    ,

    // menstruation
    <div className="questionnaire-title">

      <ListOptions
            title="If female, have you had your menstrual period within 3 days of taking your stool sample?"
            name="hasMenstrualPeriod"
            type="radio"
            list={menstrualPeriodOptions}
            errors={errors}
            touched={touched}
      />      

    </div>
    ,

    // colonoscopy year
    <div className="small-text-3 questionnaire-title">
      <LastColonoscopyYear errors={errors} touched={touched} name="lastColonoscopyYear"/>
    </div>,

    // result of colonoscopy
    <ListOptions
      title="What was the result (select one):"
      name="colonoscopyResult"
      type="radio"
      list={colonoscopyResultOptions}
      errors={errors}
      touched={touched}
      />
    ,

    // medical conditions
    <ListOptions
      title="Have you been told that you have any of the following medical conditions (check all that apply):"
      name="medicalConditionOptions"
      list={medicalConditionOptions}
      errors={errors}
      touched={touched}
      />
    ,

    // pcp
    <div className="questionnaire-title">
    <YesNoField
      groupName="grpHasPCP"
      label="Do you have a primary care physician?"
      name="hasPCP"
      errors={errors}
      touched={touched}
    />
    </div>
    ,

  ];

  const renderQuestion = (step: number) => {
    return steps[step];
  };

  const renderAll = () => {
    return(
      <>
      {steps.map( (q: React.ReactNode, i: number) => <div key={`answer-${i}`} className="my-3">
        <div className="questionnaire-title">Question {i+1} of {steps.length}</div>
        {q}
        </div>) }
      </>
    );
  }

  return <>
  {(showAll) ? renderAll() : renderQuestion(stepIndex)}
  </>;
};

const ListOptions:React.FunctionComponent<{title: string, name: string, list: IKeyValue<number | string>[], type?: 'radio' | 'checkbox', errors: any, touched: any}> = ({title, name, list, errors, type, touched}) => {
  return (
    <div >
      <div id={`checkbox-${name}`} className="small-text-3 questionnaire-title">
        {title}
      </div>
      <div role="group" aria-labelledby={`checkbox-${name}`} className={"form-control" + (errors && errors[name] && touched && touched[name] ? " is-invalid" : "")}>
        {list.map((i: IKeyValue<number | string>) => (
          <div className="form-check mt-2" key={`${name}-${i.key}`}>
            <Field
              id={`${name}-${i.key}`}
              className="form-check-input"
              type={type ? type : 'checkbox'}
              name={name}
              value={i.key.toString()}
            />
            <label className="form-class-label" htmlFor={`${name}-${i.key}`}>
              {i.value}
            </label>
          </div>
        ))}
      </div>
      {errors && <ErrorMessage className="mt-2 invalid-feedback" name={name} component="div"  />}
    </div>
  );
};


export { QuestionnaireForm }