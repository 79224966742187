import React from "react";

const Hipaa = () => {
  return (
    <div className="hipaa">
      <h1 className="text-center">HIPAA PRIVACY AUTHORIZATION</h1>
      <p className="text-center">Updated on December 11, 2021</p>
      <p>
        <span className="fw-bold">Purpose</span>: This authorization allows us to share information you provide to us with our
        partners and allows our partner healthcare providers and laboratories to share your protected health information,
        including the results of test(s) you order with us.
      </p>
      <div className="fw-bold">
        <p>
          BY CLICKING ON THE “I HAVE READ AND ACCEPT THE HIPAA AUTHORIZATION” BUTTON ON THE ACCOUNT CREATION PAGE ON THE
          CONVENAHEALTH.COM WEBSITE, I INDICATE THAT I HAVE READ THE CONTENTS OF THIS HIPAA PRIVACY AUTHORIZATION AND I HEREBY
          AUTHORIZE ALL HEALTHCARE PROVIDERS, INCLUDING THEIR PHYSICIANS, STAFF, AGENTS AND DESIGNEES (“HEALTH CONSULTANTS”), AND
          THE TESTING LABORATORIES, INCLUDING THEIR PHYSICIANS, STAFF, AGENTS AND DESIGNEES (“LABS”) THAT PERFORM SERVICES
          REQUESTED BY OR CONSENTED TO BY ME, WHICH HAVE A RELATIONSHIP WITH CONVENA HEALTH, INC. (“COMPANY”), TO USE AND DISCLOSE
          HEALTH INFORMATION ABOUT ME IN THE MANNER AND FOR THE PURPOSES STATED BELOW.
        </p>
      </div>
      <p>
        This authorization applies to the use and disclosure of the following information about me: all information in request(s)
        submitted by me or for me with my consent and the laboratory test values/results/information which are the result of such
        request(s).
      </p>
      <p>
        For avoidance of doubt, I specifically authorize the transfer and release of this information to, between and among myself
        and the following individuals/organizations and their representatives, affiliates, staff, agents, and designees: (a)
        Company; (b) applicable Health Consultants and Labs; and (c) other Company partners for the purposes herein and as
        required or permitted by law.
      </p>
      <p>
        The information subject to this authorization may be used or disclosed for the following purposes: (a) to facilitate and
        execute the services requested by me or performed with my consent (including receiving, reviewing, and approving test
        requests and reviewing, processing, and delivering the test values/results); (b) for treatment, health care operations and
        payment services; (c) to provide me with information and materials on treatment alternatives, health related offerings and
        services and products which may assist me with health, wellness and overall care or be of interest to me; (d) to conduct
        statistical research studies; and (e) as required or permitted under applicable state and federal laws. I authorize the
        use of my personal information for marketing purposes, including providing information about products and services that
        may be of interest to me.
      </p>
      <p>
        I may opt to not have my personal information used or disclosed for some of the purposes referenced herein. In order to
        opt-out, I must provide written notice to the Company as set forth below. I understand that such opt-out may affect the
        services I have voluntarily elected to receive.
      </p>
      <p>
        This authorization is evidence of my informed decision to allow the release of my information to the parties referenced
        herein. This authorization is effective immediately and will expire ten years after the date of this authorization. Upon
        my written request, I may inspect or copy the information that I have permitted to be used or disclosed, as permitted by
        law.
      </p>
      <p>
        I understand that I have a right to receive a copy of this authorization. I have the right to refuse to agree to this
        authorization and understand that my refusal may affect the services provided to me. I understand that the information
        used or disclosed pursuant to this authorization may be subject to re-disclosure by the recipient and would then no longer
        be protected by federal privacy regulations.
      </p>
      <p>
        I may revoke this authorization in writing at any time. I understand that my revocation will not affect any use or
        disclosure already taken in reliance upon this authorization. My written revocation must be submitted to Company at:
        Convena Health Inc. 92 E. Main St. Suite 405 Somerville, NJ. 08876; Email: contact@convenahealth.com.
      </p>
      <p>I understand that this authorization may be accepted by someone legally authorized to represent me.</p>
      <h1 className="text-center">HEALTH CONSULTANTS REMOTE CARE SERVICES: TELEHEALTH CONSENT, NOTICES &amp; ACKNOWLEDGEMENTS</h1>
      <p>
        <span className="fw-bold">Purpose: </span>This authorization allows our Health Consultant partners to contact you in order
        to provide prescriptions and counseling where applicable.
      </p>
      <p className="fw-bold">
        BY CLICKING “I ACCEPT,” YOU ACKNOWLEDGE THAT YOU HAVE READ, ACCEPTED, AND AGREED TO BE BOUND BY THIS INFORMED CONSENT. IF
        YOU DO NOT CLICK “I ACCEPT”, YOU WILL NOT BE ABLE TO USE OR RECEIVE THE SERVICES.
      </p>
      <p>
        I agree to receive the services provided Convena Health’s affiliated professional entities (collectively, “Affiliated
        Health Professionals”, “we” or “us”) relating to ordering laboratory tests (“Tests”), including, without limitation,
        ordering of Tests, test review services, testing, receipt of Test results (“Results”), physician consultations via
        telemedicine (“Consults”), any customer support or counseling and any other related services provided by Convena Health or
        its service providers and partners (the “Services”). All clinical Services, including Services provided by physicians,
        will be provided through Convena Health or our affiliated professional entities.
      </p>
      <p>I acknowledge and agree to the following:</p>
      <ul>
        <li>I am the individual who will provide the sample for the Test(s) that I am ordering.</li>
        <li>I am at least eighteen (18) years of age.</li>
        <li>
          I have read and understand the information provided about the Test(s) that I am requesting at the website where I am
          requesting the Test(s).
        </li>
        <li>
          In order to utilize the Services, I must provide an appropriate sample for the Test(s), which may include a blood, body
          fluids or other sample.
        </li>
        <li>
          The information I have provided in connection with the Services is correct to the best of my knowledge. I will not hold
          Convena Health or its health care providers responsible for any errors or omissions that I may have made in providing
          such information.
        </li>
        <li>
          My health information and results may be shared with other Convena Health and Affiliated providers, including
          physicians, and counselors for purposes of providing care to me.
        </li>
        <li>
          The Services do not constitute treatment or diagnosis of any condition, disease or illness, except for Consults for
          Treatment Conditions as described below.
        </li>
        <li>
          While Convena Health and the laboratories implement safeguards to avoid errors, as with all laboratory tests, there is a
          chance of a false positive or false negative result.
        </li>
        <li>
          I agree to contact the Convean Health’s team if I do not receive results within ten (10) days after I provided a sample
          at the lab testing center.
        </li>
        <li>
          I am responsible for checking my email for results notification and logging on to my account to view my results when
          available.
        </li>
        <li>
          If I receive an abnormal result on a Test, I understand that Convena Health’s team will attempt to call me to review the
          results, offer education and explain the next steps I should take. Convena Health’s team may leave me a voicemail but
          will not include my test results in any voicemail message. I also understand that if I am not able to be reached,
          Convena Health’s team will mail a follow-up letter to the residential address I provided when I purchased my test (the
          letter will not include my test results). If I receive an abnormal result and have not connected with Convena Health’s
          team, I understand that I should not delay following up with my personal physician.
        </li>
        <li>
          I understand that after receiving my Results, I will have the opportunity for a telemedicine Consult with a Convena
          Health physician.
        </li>
        <li>
          I certify that throughout the duration of the Services I receive, including my Consult, I will be physically present in
          the state of the patient service center I selected, or if I did not select a patient service center, the state of
          residence I provided or other state of which I have notified Convena Health.
        </li>
        <li>
          I am responsible for forwarding any results to my primary care or other personal physician and for initiating follow up
          with such physician for care, diagnosis, medical treatment or to obtain an interpretation of the Results.
        </li>
        <li>
          I will not make medical decisions without consulting a healthcare provider or disregard medical advice from my
          healthcare provider or delay seeking such advice based on information as a result of the use of the Services.
        </li>
      </ul>
      I understand that Services, including Consults, are delivered by health care providers who are not in the same physical
      location as I am using electronic communications, information technology or other means, including the electronic
      transmission of personal health information. I also understand that:
      <ul>
        <li>
          A Convena Health or Affiliated physician will determine whether or not Test(s) and Services, including any treatment,
          are appropriate for me.
        </li>
        <li>
          For Consults for Treatment Conditions, the scope of services will be at the sole discretion of the physician treating
          me, with no guarantee of diagnosis, treatment, or prescription. The physician will determine whether or not the
          condition being diagnosed and/or treated or the Services being rendered are appropriate for a telehealth encounter.
        </li>
        <li>
          I have the right to withdraw my consent to the use of telehealth in the course of my care at any time by contacting the
          Convena Health’s team by emailing contact@convenahealth.com.
        </li>
        <li>Any video feed from the Consult will not be retained or recorded by Convena Health. </li>
        <li>
          My health and wellness information pertaining to telehealth services are governed by the Convena Health Terms of Use and
          Convena Health Privacy Policy.
        </li>
        <li>I may need to see a health care provider in-person for diagnosis, treatment and care.</li>
        <li>
          There are potential risks associated with the use of technology, including disruptions, loss of data and technical
          difficulties.
        </li>
        <li>
          There are alternative services, such as visiting a primary care provider, an emergency room, or an urgent care facility;
          however, I chose to proceed with the Services at this time.
        </li>
      </ul>
      <p>
        I understand that if I have any questions before or after my Test, I can contact Convena Health’s by emailing
        contact@convenahealth.com.
      </p>
      <p>
        I authorize Convena Health and Affiliated physicians to use the email address and phone number I provided in connection
        with my account at the time I purchased my Test(s) (or that I updated by contacting Convena Health’s team as described
        below) to contact me in connection with the Services, including followup after a Consult. I am responsible for contacting
        Convena Health’s team by emailing contact@convenahealth.com to notify them of any changes to my mailing address, email
        address, phone number or other information that I provided in connection with the Services.
      </p>
      <p>
        I understand that testing is voluntary and that I may withdraw my consent to testing at any time prior to the completion
        of the Test(s) by contacting Convena Health’s team by emailing contact@convenahealth.com
      </p>
      <h1 className="text-center">Data Authorization</h1>
      <p>
        I specifically authorize the transfer and release of my information as described herein and in the Notice of Privacy
        Practices available to me when seeking and purchasing the Services, including my lab test Results and other identifiable
        health information, submitted by me or about me in connection with the Services, to, between and among myself and the
        following individuals, organizations and their representatives: (a) the company through which I purchased the applicable
        laboratory test and its affiliates, their staff and agents; (b) Convena Health and its affiliates, and their staff,
        agents, and health care providers, including physicians, and (c) the laboratory conducting the laboratory testing services
        to facilitate and execute the Services requested by me or performed with my consent (including receiving, reviewing and
        approving a laboratory request; reviewing, processing and delivering the laboratory test value(s)/result(s)), and as
        required or permitted by law.
      </p>
      <p>
        I understand that I have a right to receive a copy of the above data disclosure authorization. I have the right to refuse
        to agree to this authorization in which case my refusal may affect the Services provided to me. When my information is
        used or disclosed pursuant to this authorization, it may be subject to re-disclosure by the recipient and may no longer be
        protected by privacy laws. I have the right to revoke this authorization in writing at any time, except that the
        revocation will not apply to any information already disclosed by the parties referenced in this authorization. This
        authorization will expire ten (10) years from the date of signature. My written revocation must be submitted to Convena
        Health, Inc. at:
      </p>
      <p>
        Convena Health, Inc.
        <br />
        92 E. Main St. Suite 405
        <br />
        Somerville, NJ. 08876
        <br />
      </p>
      <p>
        I have read this Informed Consent carefully, and all my questions were answered to my satisfaction. I hereby consent to
        participate in the Services, including the performance of the Test(s) that I have ordered and the Consult, pursuant to the
        terms, conditions, standards, and requirements set forth herein, in the Convena Health Terms of Use and Convena Health
        Privacy Policy or as otherwise provided to me.
      </p>
    </div>
  );
};

export { Hipaa };
